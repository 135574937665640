const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'side-menu-dashboard',
        title: 'Dashboard',
        subMenu: [
          {
            icon: '',
            pageName: 'side-menu-dashboard-overview-2',
            title: 'Overview 1'
          }
        ]
      },
      {
        icon: 'BoxIcon',
        pageName: 'side-menu-menu-layout',
        title: 'Menu Layout',
        subMenu: [
          {
            icon: '',
            pageName: 'side-menu-dashboard-overview-1',
            title: 'Side Menu',
            ignore: true
          },
          {
            icon: '',
            pageName: 'simple-menu-dashboard-overview-1',
            title: 'Simple Menu',
            ignore: true
          },
          {
            icon: '',
            pageName: 'top-menu-dashboard-overview-1',
            title: 'Top Menu',
            ignore: true
          }
        ]
      },
      'devider',
      {
        icon: 'LayoutIcon',
        pageName: 'side-menu-layout',
        title: 'Pages',
        subMenu: [
          {
            icon: '',
            pageName: 'side-menu-wizards',
            title: 'Wizards',
            subMenu: [
              {
                icon: '',
                pageName: 'side-menu-wizard-layout-1',
                title: 'Layout 1'
              },
              {
                icon: '',
                pageName: 'side-menu-wizard-layout-2',
                title: 'Layout 2'
              },
              {
                icon: '',
                pageName: 'side-menu-wizard-layout-3',
                title: 'Layout 3'
              }
            ]
          }
        ]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
