const state = () => {
  return {
    menu: [
      {
        icon: 'GlobeIcon',
        pageName: 'side-menu-dashboard',
        title: 'COATE',
        subMenu: [
          {
            icon: '',
            pageName: 'side-oblasts',
            permission: 'COATE',
            title: 'Области'
          },
          {
            icon: '',
            pageName: 'rayons',
            permission: 'COATE',
            title: 'Районы'
          },
          {
            icon: '',
            pageName: 'aymaks',
            permission: 'COATE',
            title: 'Айылные аймаки'
          },
          {
            icon: '',
            pageName: 'naspunkts',
            permission: 'COATE',
            title: 'Населенные пукнты'
          }
        ]
      },
      {
        icon: 'DatabaseIcon',
        pageName: 'side-menu-dashboard',
        title: 'Группы и участники',
        subMenu: [
          {
            icon: '',
            pageName: 'uchastniki',
            permission: 'UCHASTNIKI_READ',
            title: 'Участники'
          },
          {
            icon: '',
            pageName: 'gruppaList',
            permission: 'GRUPPA_READ',
            title: 'Группы'
          }
        ]
      },
      {
        icon: 'CheckSquareIcon',
        pageName: 'side-menu-dashboard',
        title: 'Анкеты',
        subMenu: [
          {
            icon: '',
            pageName: 'anketa1List',
            permission: 'ANKETA',
            title: 'Внедрение технологий'
          },
          {
            icon: '',
            pageName: 'anketa2List',
            permission: 'ANKETA',
            title: 'Пре - пост Тест «ПО Животноводству и ветеринарии»'
          },
          {
            icon: '',
            pageName: 'anketa3List',
            permission: 'ANKETA',
            title: 'Пре пост тест по Агрономии'
          },
          {
            icon: '',
            pageName: 'feedBackList',
            permission: 'ANKETA',
            title: 'Отзывы участников'
          },
          {
            icon: '',
            pageName: 'anketaUdoyList',
            permission: 'ANKETA',
            title: 'Анкеты по удою'
          },
          {
            icon: '',
            pageName: 'fermaAnketasReport',
            permission: 'ANKETA',
            title: 'Отчеты'
          }
        ]
      },
      {
        icon: 'CheckSquareIcon',
        pageName: 'side-menu-dashboard',
        title: 'Анкетирование выпускников',
        subMenu: [
          {
            icon: 'CheckSquareIcon',
            pageName: 'side-menu-dashboard',
            title: 'Справочники',
            subMenu: [
              {
                icon: '',
                pageName: 'instituteList',
                permission: 'INSTITUTE_READ',
                title: 'Учебные заведения'
              },
              {
                icon: '',
                pageName: 'specializationList',
                permission: 'SPECIALIZATION_READ',
                title: 'Специальности'
              }
            ]
          },
          {
            icon: '',
            pageName: 'students',
            permission: 'STUDENT_READ',
            title: 'Выпускники'
          },
          {
            icon: '',
            pageName: 'anketaForStudentList',
            permission: 'ANKETA_FOR_STUDENT_READ',
            title: 'Анкеты'
          },
          {
            icon: '',
            pageName: 'anketaForStudentReport',
            permission: 'ANKETA_FOR_STUDENT_REPORT',
            title: 'Отчет по выпускникам'
          }
        ]
      },
      {
        icon: 'ListIcon',
        pageName: 'side-menu-dashboard',
        title: 'Новые анкеты',
        subMenu: [
          {
            icon: 'SunIcon',
            pageName: 'side-menu-dashboard',
            title: 'Агрономия',
            subMenu: [
              {
                icon: '',
                pageName: 'agronomiyaQuiz1List',
                permission: 'ANKETA',
                title: 'УМ1: Плодородие почвы...'
              },
              {
                icon: '',
                pageName: 'agronomiyaQuiz2List',
                permission: 'ANKETA',
                title: 'УМ 2: «Агротехника выращивания...'
              },
              {
                icon: '',
                pageName: 'agronomiyaQuiz3List',
                permission: 'ANKETA',
                title: 'УМ 3: «Приготовление и хранение...'
              }
            ]
          },
          {
            icon: 'TwitterIcon',
            pageName: 'side-menu-dashboard',
            title: 'Животноводство',
            subMenu: [
              {
                icon: '',
                pageName: 'jivotnovodstvoQuiz2List',
                permission: 'ANKETA',
                title: 'УМ 1: Болезни коров...'
              },
              {
                icon: '',
                pageName: 'jivotnovodstvoQuiz3List',
                permission: 'ANKETA',
                title: 'УМ 2: Основы и экономика...'
              },
              {
                icon: '',
                pageName: 'jivotnovodstvoQuiz4List',
                permission: 'ANKETA',
                title: 'УМ 3: Составление и использование...'
              },
              {
                icon: '',
                pageName: 'jivotnovodstvoQuiz5List',
                permission: 'ANKETA',
                title: 'УМ 4: Основы обеспечения качества...'
              },
              {
                icon: '',
                pageName: 'jivotnovodstvoQuiz6List',
                permission: 'ANKETA',
                title: 'УМ 5: Технологии улучшения условий...'
              },
              {
                icon: '',
                pageName: 'jivotnovodstvoQuiz1List',
                permission: 'ANKETA',
                title: 'УМ6: Воспроизводство крупного...'
              }
            ]
          },
          {
            icon: 'GitCommitIcon',
            pageName: 'side-menu-dashboard',
            title: 'Внед. тех. (Агрономия)',
            subMenu: [
              {
                icon: '',
                pageName: 'vnedAgrQuiz1List',
                permission: 'ANKETA',
                title: 'УМ 1: Плодородие почвы...'
              },
              {
                icon: '',
                pageName: 'vnedAgrQuiz2List',
                permission: 'ANKETA',
                title: 'УМ 2: Агротехника выращивания...'
              },
              {
                icon: '',
                pageName: 'vnedAgrQuiz3List',
                permission: 'ANKETA',
                title: 'УМ 3: Приготовление и хранение...'
              }
            ]
          },
          {
            icon: 'GitCommitIcon',
            pageName: 'side-menu-dashboard',
            title: 'Внед. тех. (Животноводство)',
            subMenu: [
              {
                icon: '',
                pageName: 'vnedJivQuiz1List',
                permission: 'ANKETA',
                title: 'УМ 1: Болезни дойных...'
              },
              {
                icon: '',
                pageName: 'vnedJivQuiz2List',
                permission: 'ANKETA',
                title: 'УМ 2: Основы и экономика...'
              },
              {
                icon: '',
                pageName: 'vnedJivQuiz3List',
                permission: 'ANKETA',
                title: 'УМ 3: Составление и использование...'
              },
              {
                icon: '',
                pageName: 'vnedJivQuiz4List',
                permission: 'ANKETA',
                title: 'УМ 4: Основы обеспечения качества...'
              },
              {
                icon: '',
                pageName: 'vnedJivQuiz5List',
                permission: 'ANKETA',
                title: 'УМ 5: Технологии улучшения условий...'
              },
              {
                icon: '',
                pageName: 'vnedJivQuiz6List',
                permission: 'ANKETA',
                title: 'УМ 6: Воспроизводство крупного рогатого...'
              }
            ]
          }
        ]
      },
      {
        icon: 'LockIcon',
        pageName: 'side-menu-dashboard',
        title: 'Пользователи',
        subMenu: [
          {
            icon: '',
            pageName: 'userroles',
            permission: 'SUPER_ADMIN',
            title: 'Роли'
          },
          {
            icon: '',
            pageName: 'users',
            permission: 'USER_READ',
            title: 'Пользователи'
          }
        ]
      },
      'devider',
      {
        icon: 'LockIcon',
        pageName: 'accessControl',
        permission: 'SUPER_ADMIN',
        title: 'Контроль доступов'
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
