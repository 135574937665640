import axios from 'axios'

const API_URL = 'https://anketa.kg/back/api/authenticate'

class AuthService {
  login(user) {
    return axios
      .post(API_URL, {
        headers: {
          'Content-Type': 'application/json'
        },
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.jwtToken) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
  }

  logout() {
    localStorage.removeItem('user')
  }
}

export default new AuthService()
