/* eslint-disable */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import $ from 'jquery'

// SASS Theme
import './assets/sass/app.scss'
import './assets/css/main.css'

import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import Jwt_decode from "jwt-decode";

const app = createApp(App)
  .use(store)
  .use(router)

app.use(VueSweetalert2)
app.mixin({
  methods: {
    checkJwtExp: function (){
      const loggedIn = store.state.auth.user
      const decodedJWTExpDate = loggedIn != null ? new Date(Jwt_decode(loggedIn.jwtToken).exp * 1000) : 0
      if(decodedJWTExpDate <= new Date() ) {
        router.push('/logout')
        store.user = null
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          focusConfirm: true,
          showConfirmButton: false,
          icon: 'error',
          title: 'У вас закончился сессия!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
      }
    },
    checkResponseError: function (error) {
     this.checkJwtExp()
      if (error.toString() === 'Error: Network Error') {
        this.$swal.fire({
          toast: true,
          focusConfirm: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Внутренняя ошибка сервера',
          text: 'Попробуйте позже',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
      const statusCode = error.response ? error.response.status : null
      if (statusCode != null && statusCode === 400) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Вы ввели не корректные данные!',
          text: 'Пожалуйста проверьте правильность данных!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
      if (statusCode != null && statusCode === 401) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          focusConfirm: true,
          icon: 'error',
          title: 'Вы не авторизованы!',
          text: 'Пожалуйста авторизуйтесь!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        $('#close').click()
        router.push('/logout')
      }
      if (statusCode != null && statusCode === 403) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          focusConfirm: true,
          showConfirmButton: false,
          icon: 'error',
          title: 'У вас нет прав!',
          text: 'Обратитесь администратору системы!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
      if (statusCode != null && statusCode === 500) {
        this.$swal.fire({
          toast: true,
          focusConfirm: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Внутренняя ошибка сервера',
          text: 'Попробуйте позже',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
      if (statusCode != null && statusCode === 406) {
        this.$swal.fire({
          toast: true,
          focusConfirm: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: error.response.data,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
    },
    succesResponse: function (response) {
      this.checkJwtExp()
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        let text = 'Запись успешно сохранена!!!'
        if (response.data.length > 0 && (typeof response.data) == 'string') {
          text = response.data
        }
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          focusConfirm: true,
          icon: 'success',
          title: text,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
    },
    deleteResponse: function (response) {
      this.checkJwtExp()
      if (response.status === 200) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'info',
          title: 'Запись успешно удалена!!!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
      if (response.status === 404) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'warning',
          title: 'Запись не найдена!!!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return false
      }
    },
    invalidCredentials: function (error) {
      const statusCode = error.response ? error.response.status : null
      if (statusCode != null && (statusCode === 404 || statusCode === 500)) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Неправильный логин или пароль!',
          text: 'Пожалуйста проверьте правильность данных!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
    },
    checkResponseErrorForSpisanie: function (error) {
      if (error.toString() === 'Error: Network Error') {
        this.$swal.fire({
          toast: true,
          focusConfirm: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Внутренняя ошибка сервера',
          text: 'Попробуйте позже',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
      const statusCode = error.response ? error.response.status : null
      if (statusCode != null && statusCode === 400) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Запрашиваемое количество превышает остаток на складе!',
          text: 'Пожалуйста проверьте правильность данных!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
      if (statusCode != null && statusCode === 401) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          focusConfirm: true,
          icon: 'error',
          title: 'Вы не авторизованы!',
          text: 'Пожалуйста авторизуйтесь!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        $('#close').click()
        router.push('/logout')
      }
      if (statusCode != null && statusCode === 403) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          focusConfirm: true,
          showConfirmButton: false,
          icon: 'error',
          title: 'У вас нет прав!',
          text: 'Обратитесь администратору системы!',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
      if (statusCode != null && statusCode === 500) {
        this.$swal.fire({
          toast: true,
          focusConfirm: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Внутренняя ошибка сервера',
          text: 'Попробуйте позже',
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
    },
    privCardExists: function (response) {
      if (response.status === 200) {
        let text = 'Запись успешно сохранена!!!'
        if ((typeof response.data) == 'string') {
          text = response.data
        }
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          focusConfirm: true,
          icon: 'error',
          title: text,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        return true
      }
    }
  }
})

globalComponents(app)
utils(app)

app.mount('#app')
