import AuthService from '../services/auth.service'
// eslint-disable-next-line camelcase
import Jwt_decode from 'jwt-decode'
const userFromLS = JSON.parse(localStorage.getItem('user'))
const decodedJWTExpDate = userFromLS != null ? new Date(Jwt_decode(userFromLS.jwtToken).exp * 1000) : 0
const user = decodedJWTExpDate > new Date() ? userFromLS : null
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export default {
  namespaced: true,
  state: initialState,
  getters: {

  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    }
  }
}
