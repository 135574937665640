<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="intro-y text-lg font-medium mt-10">Айылные аймаки</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <a      href="javascript:;"
                  data-toggle="modal"
                  data-target="#create-modal"
                  class="btn btn-primary shadow-md mr-2"
                  @click="showModalCreate">Добавить</a>
        </div>
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2" id='table_id'>
            <thead>
            <tr>
              <th class="whitespace-nowrap">Название</th>
              <th class="whitespace-nowrap">ATE Код</th>
              <th class="whitespace-nowrap">Район</th>
              <th class="text-center whitespace-nowrap">Действия</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in aymaks"
                :key="item.id">
              <td>
                <div class="text-s">
                  {{ item.shortName }}
                </div>
              </td>
              <td>
                <div class="text-s">
                  {{ item.ateCode }}
                </div>
              </td>
              <td>
                <div class="text-s" v-for="(rayon, name) in item.rayon" v-bind:key="rayon">
                  <div v-if="name === 'name'">
                    {{ rayon }}
                  </div>
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;"
                     data-toggle="modal"
                     data-target="#create-modal"
                     @click="showModalEdit(item)">
                    <CheckSquareIcon class="w-4 h-4 mr-1"/>
                    Редактировать
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- BEGIN: Create Modal -->
      <div
        id="create-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop='static'
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <div class="text-3xl mt-5">{{ nameForModal }}</div>
              </div>
              <form v-on:submit.prevent="isEditingModal ? edit() : create()">
                <div class="p-5">
                  <label class="form-label left-0">Название</label>
                  <input v-model="aymak.name"
                         type="text"
                         required
                         class="intro-x login__input form-control py-3 px-4 border-gray-300 block mb-4"
                         placeholder="Название"
                  />
                  <label class="form-label left-0">ATE код</label>
                  <input v-model="aymak.ateCode"
                         type="number"
                         class="intro-x login__input form-control py-3 px-4 border-gray-300 block mb-4"
                         placeholder="Код"
                  />

                  <label class="form-label left-0">Район</label>
                  <Select2 v-model="aymak.rayon" :options="rayons" />

                  <button type="submit" class="btn btn-primary py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top">
                    {{ nameOfButtonForModal }}
                  </button>
                  <button type="button" id="close" data-dismiss='modal' @click="resetData"
                          class='btn btn-light py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top'>
                    Отмена
                  </button>
                </div>
              </form>
              <div
                class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue'
import axios from 'axios'
import store from '@/store'

import $ from 'jquery'
import Select2 from "vue3-select2-component";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},
  setup() {
    const select = ref('#')
    return { select }
  },
  data() {
    return {
      nameForModal: String,
      nameOfButtonForModal: String,
      isEditingModal: false,
      totalItems: Number,
      aymaks: {
        type: Array
      },
      aymak: {
        id: '',
        ateCode: null,
        ateTypeName: null,
        ateTypeShortName: null,
        shortName: null,
        code: null,
        name: null,
        rayon: null
      },
      rayons: []
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.aymak, this.$options.data().aymak)
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'coate/aymak/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.aymaks = response.data
              $("#table_id").dataTable().fnDestroy();
              $(document).ready( function () {
                $('#table_id').DataTable({
                    "language": {
                      "processing": "Подождите...",
                      "search": "Поиск:",
                      "lengthMenu": "Показать _MENU_ записей",
                      "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
                      "infoEmpty": "Записи с 0 до 0 из 0 записей",
                      "infoFiltered": "(отфильтровано из _MAX_ записей)",
                      "infoPostFix": "",
                      "loadingRecords": "Загрузка записей...",
                      "zeroRecords": "Записи отсутствуют.",
                      "emptyTable": "В таблице отсутствуют данные",
                      "paginate":
                        {
                          "first": "Первая",
                          "previous": "Предыдущая",
                          "next": "Следующая",
                          "last": "Последняя"
                        },
                      "aria": {
                        "sortAscending": ": активировать для сортировки столбца по возрастанию",
                        "sortDescending": ": активировать для сортировки столбца по убыванию"
                      },
                      "select": {
                        "rows": {
                          "_": "Выбрано записей: %d",
                          "0": "Кликните по записи для выбора",
                          "1": "Выбрана одна запись"
                        }
                      },
                      "buttons":
                        {
                          "print": "Печать"
                        }
                    },
                    columnDefs : [
                      { 'visible': false, 'targets': [ ] }
                    ]
                    // "buttons": ["csv", "excel", "print"]
                  }
                );
              })
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

          await axios.get(API_URL + 'coate/rayon/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.rayons = []
              response.data.forEach(item => {
                this.rayons.push({id: item.id, text: item.name})
              })
            }
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    showModalCreate() {
      this.nameForModal = 'Добавление'
      this.nameOfButtonForModal = 'Добавить'
      this.isEditingModal = false
    },
    create() {
      axios({
        method: 'post',
        url: API_URL + 'coate/aymak/add',
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          ateCode: this.aymak.ateCode,
          ateTypeName: this.aymak.ateTypeName,
          ateTypeShortName: this.aymak.ateTypeShortName,
          shortName: this.aymak.name,
          code: this.aymak.code,
          name: this.aymak.name,
          rayon: this.aymak.rayon != null ? {id: this.aymak.rayon} : null
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    showModalEdit(aymak) {
      this.aymak.id = aymak.id
      this.aymak.ateCode = aymak.ateCode
      this.aymak.ateTypeName = aymak.ateTypeName
      this.aymak.ateTypeShortName = aymak.ateTypeShortName
      this.aymak.shortName = aymak.shortName
      this.aymak.code = aymak.code
      this.aymak.name = aymak.name
      this.aymak.rayon = aymak.rayon.id
      this.isEditingModal = true
      this.nameForModal = 'Редактирование'
      this.nameOfButtonForModal = 'Изменить'
    },
    edit() {
      axios({
        method: 'put',
        url: API_URL + 'coate/aymak/edit/' + this.aymak.id,
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          id: this.aymak.id,
          ateCode: this.aymak.ateCode,
          ateTypeName: this.aymak.ateTypeName,
          ateTypeShortName: this.aymak.ateTypeShortName,
          shortName: this.aymak.shortName,
          code: this.aymak.code,
          name: this.aymak.name,
          rayon: this.aymak.rayon != null ? {id: this.aymak.rayon} : null
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    }
  }
})
</script>
