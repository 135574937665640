/* eslint-disable */
import {createRouter, createWebHistory} from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import Login from '../views/login/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'
import ErrorPage403 from '../views/error-page/Main403.vue'
import Oblasts from '../views/coate/oblast/Main.vue'
import Rayons from '../views/coate/rayon/Main.vue'
import NasPunkts from '../views/coate/naspunkt/Main.vue'
import UserRoles from '../views/users/userRole/Main.vue'
import Users from '../views/users/user/Main.vue'
import Aymaks from '../views/coate/aymak/Main.vue'
import Profile from '../views/users/profile/Main.vue'
import AccessControl from '../views/users/accessControl/Main.vue'
import Uchastniki from '../views/uchastniki/Main.vue'
import Anketa1List from '../views/anketas/anketa1/MainList.vue'
import Anketa1Add from '../views/anketas/anketa1/MainAdd.vue'
import Anketa1Info from '../views/anketas/anketa1/MainInfo.vue'
import Anketa2List from '../views/anketas/anketa2/MainList.vue'
import Anketa2Add from '../views/anketas/anketa2/MainAdd.vue'
import Anketa2Editfrom from '../views/anketas/anketa2/MainInfo.vue'
import CopyLinks from '../views/anketas/copyLinks/Main.vue'
import FeedbackAnketa from '../views/anketas/feedbackAnketa/MainList.vue'
import FeedbackAnketaAdd from '../views/anketas/feedbackAnketa/MainAdd.vue'
import FeedbackAnketaInfo from '../views/anketas/feedbackAnketa/MainInfo.vue'
import Anketa3List from '../views/anketas/anketa3/MainList.vue'
import Anketa3Add from '../views/anketas/anketa3/MainAdd.vue'
import Anketa3Info from '../views/anketas/anketa3/MainInfo.vue'

import store from '@/store'


const routes = [
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: 'dashboard1',
        name: 'dashboard1',
        permission: 'DASHBOARD_READ',
        component: () => import('../views/dashboard-overview-2/Main.vue')
      },
      {
        path: 'oblasts',
        name: 'side-oblasts',
        permission: 'COATE',
        component: Oblasts
      },
      {
        path: 'rayons',
        name: 'rayons',
        permission: 'COATE',
        component: Rayons
      },
      {
        path: 'naspunkts',
        name: 'naspunkts',
        permission: 'COATE',
        component: NasPunkts
      },
      {
        path: 'aymaks',
        name: 'aymaks',
        permission: 'COATE',
        component: Aymaks
      },

      {
        path: 'userroles',
        name: 'userroles',
        permission: 'SUPER_ADMIN',
        component: UserRoles
      },
      {
        path: 'users',
        name: 'users',
        permission: 'USER_READ',
        component: Users
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile
      },
      {
        path: 'accessControl',
        name: 'accessControl',
        permission: 'SUPER_ADMIN',
        component: AccessControl
      },
      {
        path: 'gruppaList',
        name: 'gruppaList',
        permission: 'GRUPPA_READ',
        component: () => import('../views/gruppa/list/Main.vue')
      },
      {
        path: 'addUchastniki/:id',
        name: 'addUchastniki',
        permission: 'GRUPPA_READ',
        component: () => import('../views/gruppa/uchastniki/Main.vue')
      },
      {
        path: 'poseshaemost/:id',
        name: 'poseshaemost',
        permission: 'GRUPPA_READ',
        component: () => import('../views/gruppa/poseshaemost/Main.vue')
      },
      {
        path: 'poseshaemostInfo/:id',
        name: 'poseshaemostInfo',
        permission: 'GRUPPA_READ',
        component: () => import('../views/gruppa/poseshaemost/MainInfo.vue')
      },
      {
        path: 'uchastniki',
        name: 'uchastniki',
        permission: 'UCHASTNIKI_READ',
        component: Uchastniki
      },
      {
        path: 'anketa1List',
        name: 'anketa1List',
        permission: 'ANKETA',
        component: Anketa1List
      },
      {
        path: 'anketa1Info/:id',
        name: 'anketa1Info',
        permission: 'ANKETA',
        component: Anketa1Info
      },
      {
        path: 'anketa2List',
        name: 'anketa2List',
        permission: 'ANKETA',
        component: Anketa2List
      },
      {
        path: 'anketa2Info/:id',
        name: 'anketa2Info',
        permission: 'ANKETA',
        component: Anketa2Editfrom
      },
      {
        path: 'copyLinks/:id',
        name: 'copyLinks',
        permission: 'GRUPPA_READ',
        component: CopyLinks
      },
      {
        path: 'feedBackList',
        name: 'feedBackList',
        permission: 'ANKETA',
        component: FeedbackAnketa
      },
      {
        path: 'feedBackInfo/:id',
        name: 'feedBackInfo',
        permission: 'ANKETA',
        component: FeedbackAnketaInfo
      },
      {
        path: 'anketa3List',
        name: 'anketa3List',
        permission: 'ANKETA',
        component: Anketa3List
      },
      {
        path: 'anketa3Info/:id',
        name: 'anketa3Info',
        permission: 'ANKETA',
        component: Anketa3Info
      },
      {
        path: 'anketaUdoyList',
        name: 'anketaUdoyList',
        permission: 'ANKETA',
        component: () => import('../views/anketas/anketaUdoy/MainList')
      },
      {
        path: 'anketaUdoyInfo/:id',
        name: 'anketaUdoyInfo',
        permission: 'ANKETA',
        component: () => import('../views/anketas/anketaUdoy/MainInfo')
      },
      {
        path: 'instituteList',
        name: 'instituteList',
        permission: 'INSTITUTE_READ',
        component: () => import('../views/anketaForStudent/dictionaries/institute/Main')
      },
      {
        path: 'students',
        name: 'students',
        permission: 'STUDENT_READ',
        component: () => import('../views/anketaForStudent/student/Main')
      },
      {
        path: 'specializationList',
        name: 'specializationList',
        permission: 'SPECIALIZATION_READ',
        component: () => import('../views/anketaForStudent/dictionaries/specialization/Main')
      },
      {
        path: 'anketaForStudentList',
        name: 'anketaForStudentList',
        permission: 'ANKETA_FOR_STUDENT_READ',
        component: () => import('../views/anketaForStudent/anketaList/Main')
      },
      {
        path: 'anketaForStudentInfo/:id',
        name: 'anketaForStudentInfo',
        permission: 'ANKETA_FOR_STUDENT_READ',
        component: () => import('../views/anketaForStudent/anketaInfo/Main')
      },
      {
        path: 'anketaForStudentReport',
        name: 'anketaForStudentReport',
        permission: 'ANKETA_FOR_STUDENT_REPORT',
        component: () => import('../views/anketaForStudent/report/Main')
      },
      {
        path: 'fermaAnketasReport',
        name: 'fermaAnketasReport',
        permission: 'ANKETA',
        component: () => import('../views/anketas/report/Main')
      },
      {
        path: 'agronomiyaQuiz1List',
        name: 'agronomiyaQuiz1List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/agronomiya/quiz1/MainList.vue')
      },
      {
        path: 'agronomiyaQuiz1Info/:id',
        name: 'agronomiyaQuiz1Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/agronomiya/quiz1/MainInfo.vue')
      },
      {
        path: 'agronomiyaQuiz2List',
        name: 'agronomiyaQuiz2List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/agronomiya/quiz2/MainList.vue')
      },
      {
        path: 'agronomiyaQuiz2Info/:id',
        name: 'agronomiyaQuiz2Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/agronomiya/quiz2/MainInfo.vue')
      },
      {
        path: 'agronomiyaQuiz3List',
        name: 'agronomiyaQuiz3List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/agronomiya/quiz3/MainList.vue')
      },
      {
        path: 'agronomiyaQuiz3Info/:id',
        name: 'agronomiyaQuiz3Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/agronomiya/quiz3/MainInfo.vue')
      },
      {
        path: 'jivotnovodstvoQuiz2List',
        name: 'jivotnovodstvoQuiz2List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz2/MainList.vue')
      },
      {
        path: 'jivotnovodstvoQuiz2Info/:id',
        name: 'jivotnovodstvoQuiz2Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz2/MainInfo.vue')
      },
      {
        path: 'jivotnovodstvoQuiz1List',
        name: 'jivotnovodstvoQuiz1List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz1/MainList.vue')
      },
      {
        path: 'jivotnovodstvoQuiz1Info/:id',
        name: 'jivotnovodstvoQuiz1Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz1/MainInfo.vue')
      },
      {
        path: 'jivotnovodstvoQuiz3List',
        name: 'jivotnovodstvoQuiz3List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz3/MainList.vue')
      },
      {
        path: 'jivotnovodstvoQuiz3Info/:id',
        name: 'jivotnovodstvoQuiz3Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz3/MainInfo.vue')
      },
      {
        path: 'jivotnovodstvoQuiz4List',
        name: 'jivotnovodstvoQuiz4List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz4/MainList.vue')
      },
      {
        path: 'jivotnovodstvoQuiz4Info/:id',
        name: 'jivotnovodstvoQuiz4Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz4/MainInfo.vue')
      },
      {
        path: 'jivotnovodstvoQuiz5List',
        name: 'jivotnovodstvoQuiz5List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz5/MainList.vue')
      },
      {
        path: 'jivotnovodstvoQuiz5Info/:id',
        name: 'jivotnovodstvoQuiz5Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz5/MainInfo.vue')
      },
      {
        path: 'jivotnovodstvoQuiz6List',
        name: 'jivotnovodstvoQuiz6List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz6/MainList.vue')
      },
      {
        path: 'jivotnovodstvoQuiz6Info/:id',
        name: 'jivotnovodstvoQuiz6Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz6/MainInfo.vue')
      },
      {
        path: 'vnedAgrQuiz1List',
        name: 'vnedAgrQuiz1List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz1/MainList.vue')
      },
      {
        path: 'vnedAgrQuiz1Info/:id',
        name: 'vnedAgrQuiz1Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz1/MainInfo.vue')
      },
      {
        path: 'vnedAgrQuiz2List',
        name: 'vnedAgrQuiz2List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz2/MainList.vue')
      },
      {
        path: 'vnedAgrQuiz2Info/:id',
        name: 'vnedAgrQuiz2Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz2/MainInfo.vue')
      },
      {
        path: 'vnedAgrQuiz3List',
        name: 'vnedAgrQuiz3List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz3/MainList.vue')
      },
      {
        path: 'vnedAgrQuiz3Info/:id',
        name: 'vnedAgrQuiz3Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz3/MainInfo.vue')
      },
      {
        path: 'vnedJivQuiz1List',
        name: 'vnedJivQuiz1List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz1/MainList.vue')
      },
      {
        path: 'vnedJivQuiz1Info/:id',
        name: 'vnedJivQuiz1Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz1/MainInfo.vue')
      },
      {
        path: 'vnedJivQuiz2List',
        name: 'vnedJivQuiz2List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz2/MainList.vue')
      },
      {
        path: 'vnedJivQuiz2Info/:id',
        name: 'vnedJivQuiz2Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz2/MainInfo.vue')
      },
      {
        path: 'vnedJivQuiz3List',
        name: 'vnedJivQuiz3List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz3/MainList.vue')
      },
      {
        path: 'vnedJivQuiz3Info/:id',
        name: 'vnedJivQuiz3Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz3/MainInfo.vue')
      },
      {
        path: 'vnedJivQuiz4List',
        name: 'vnedJivQuiz4List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz4/MainList.vue')
      },
      {
        path: 'vnedJivQuiz4Info/:id',
        name: 'vnedJivQuiz4Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz4/MainInfo.vue')
      },
      {
        path: 'vnedJivQuiz5List',
        name: 'vnedJivQuiz5List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz5/MainList.vue')
      },
      {
        path: 'vnedJivQuiz5Info/:id',
        name: 'vnedJivQuiz5Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz5/MainInfo.vue')
      },
      {
        path: 'vnedJivQuiz6List',
        name: 'vnedJivQuiz6List',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz6/MainList.vue')
      },
      {
        path: 'vnedJivQuiz6Info/:id',
        name: 'vnedJivQuiz6Info',
        permission: 'ANKETA',
        component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz6/MainInfo.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/successFilled',
    name: 'successFilled',
    component: () => import('../views/anketas/newAnketas/SuccessFilled.vue')
  },
  {
    path: '/anketa1Add/:id',
    name: 'anketa1Add',
    permission: 'ANKETA',
    component: Anketa1Add
  },
  {
    path: '/anketa2Add/:id',
    name: 'anketa2Add',
    permission: 'ANKETA',
    component: Anketa2Add
  },
  {
    path: '/anketa3Add/:id',
    name: 'anketa3Add',
    permission: 'ANKETA',
    component: Anketa3Add
  },
  {
    path: '/feedBackAdd/:id',
    name: 'feedBackAdd',
    permission: 'ANKETA',
    component: FeedbackAnketaAdd
  },
  {
    path: '/anketaUdoyAdd/:id',
    name: 'anketaUdoyAdd',
    permission: 'ANKETA',
    component: () => import('../views/anketas/anketaUdoy/MainAdd')
  },
  {
    path: '/anketaForStudentAdd/:id',
    name: 'anketaForStudentAdd',
    component: () => import('../views/anketaForStudent/anketaAdd/Main')
  },
  {
    path: '/agronomiyaQuiz1Add/:id',
    name: 'agronomiyaQuiz1Add',
    component: () => import('../views/anketas/newAnketas/agronomiya/quiz1/MainAdd.vue')
  },
  {
    path: '/agronomiyaQuiz2Add/:id',
    name: 'agronomiyaQuiz2Add',
    component: () => import('../views/anketas/newAnketas/agronomiya/quiz2/MainAdd.vue')
  },
  {
    path: '/agronomiyaQuiz3Add/:id',
    name: 'agronomiyaQuiz3Add',
    component: () => import('../views/anketas/newAnketas/agronomiya/quiz3/MainAdd.vue')
  },
  {
    path: '/jivotnovodstvoQuiz2Add/:id',
    name: 'jivotnovodstvoQuiz2Add',
    component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz2/MainAdd.vue')
  },
  {
    path: '/jivotnovodstvoQuiz1Add/:id',
    name: 'jivotnovodstvoQuiz1Add',
    component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz1/MainAdd.vue')
  },
  {
    path: '/jivotnovodstvoQuiz3Add/:id',
    name: 'jivotnovodstvoQuiz3Add',
    component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz3/MainAdd.vue')
  },
  {
    path: '/jivotnovodstvoQuiz4Add/:id',
    name: 'jivotnovodstvoQuiz4Add',
    component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz4/MainAdd.vue')
  },
  {
    path: '/jivotnovodstvoQuiz5Add/:id',
    name: 'jivotnovodstvoQuiz5Add',
    component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz5/MainAdd.vue')
  },
  {
    path: '/jivotnovodstvoQuiz6Add/:id',
    name: 'jivotnovodstvoQuiz6Add',
    component: () => import('../views/anketas/newAnketas/jivotnovodstvo/quiz6/MainAdd.vue')
  },
  {
    path: '/vnedAgrQuiz1Add/:id',
    name: 'vnedAgrQuiz1Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz1/MainAdd.vue')
  },
  {
    path: '/vnedAgrQuiz2Add/:id',
    name: 'vnedAgrQuiz2Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz2/MainAdd.vue')
  },
  {
    path: '/vnedAgrQuiz3Add/:id',
    name: 'vnedAgrQuiz3Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/agronomiya/quiz3/MainAdd.vue')
  },
  {
    path: '/vnedJivQuiz1Add/:id',
    name: 'vnedJivQuiz1Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz1/MainAdd.vue')
  },
  {
    path: '/vnedJivQuiz2Add/:id',
    name: 'vnedJivQuiz2Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz2/MainAdd.vue')
  },
  {
    path: '/vnedJivQuiz3Add/:id',
    name: 'vnedJivQuiz3Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz3/MainAdd.vue')
  },
  {
    path: '/vnedJivQuiz4Add/:id',
    name: 'vnedJivQuiz4Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz4/MainAdd.vue')
  },
  {
    path: '/vnedJivQuiz5Add/:id',
    name: 'vnedJivQuiz5Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz5/MainAdd.vue')
  },
  {
    path: '/vnedJivQuiz6Add/:id',
    name: 'vnedJivQuiz6Add',
    component: () => import('../views/anketas/newAnketas/vnedTechnologiy/jivotnovodstvo/quiz6/MainAdd.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: {
      beforeRouteEnter(to, from, next) {
        const destination = {
          path: '/login',
          query: from.query,
          params: from.params
        }
        store.dispatch('auth/logout')
        next(destination)
      }
    }
  },
  {
    path: '/404',
    name: 'ErrorPage',
    component: ErrorPage
  },
  {
    path: '/403',
    name: 'ErrorPage403',
    component: ErrorPage403
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {left: 0, top: 0}
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/successFilled', '/404', '/profile', '/logout', '/403', '/anketa1Add/:id',
    '/feedBackAdd/:id', '/anketa3Add/:id', '/anketa2Add/:id', '/anketaForStudentAdd/:id', '/anketaUdoyAdd/:id',
    '/agronomiyaQuiz1Add/:id', '/agronomiyaQuiz2Add/:id', '/agronomiyaQuiz3Add/:id', '/jivotnovodstvoQuiz1Add/:id',
    '/jivotnovodstvoQuiz3Add/:id', '/jivotnovodstvoQuiz2Add/:id', '/jivotnovodstvoQuiz4Add/:id', '/jivotnovodstvoQuiz5Add/:id', '/jivotnovodstvoQuiz6Add/:id',
    '/vnedAgrQuiz1Add/:id', '/vnedAgrQuiz2Add/:id', '/vnedAgrQuiz3Add/:id', '/vnedJivQuiz1Add/:id', '/vnedJivQuiz2Add/:id', '/vnedJivQuiz3Add/:id', '/vnedJivQuiz4Add/:id', '/vnedJivQuiz5Add/:id', '/vnedJivQuiz6Add/:id']
  const path = to.path
  let pathNew = to.path
  const authRequired = !publicPages.includes(path)
  const anketa1Add = path.includes('anketa1Add')
  const feedBackAnketaAdd = path.includes('feedBackAdd')
  const anketa3Add = path.includes('anketa3Add')
  const anketa2Add = path.includes('anketa2Add')
  const anketaForStudentAdd = path.includes('anketaForStudentAdd')
  const anketaUdoyAdd = path.includes('anketaUdoyAdd')
  const agronomiyaQuiz1Add = path.includes('agronomiyaQuiz1Add')
  const agronomiyaQuiz2Add = path.includes('agronomiyaQuiz2Add')
  const agronomiyaQuiz3Add = path.includes('agronomiyaQuiz3Add')
  const jivotnovodstvoQuiz2Add = path.includes('jivotnovodstvoQuiz2Add')
  const jivotnovodstvoQuiz1Add = path.includes('jivotnovodstvoQuiz1Add')
  const jivotnovodstvoQuiz3Add = path.includes('jivotnovodstvoQuiz3Add')
  const jivotnovodstvoQuiz4Add = path.includes('jivotnovodstvoQuiz4Add')
  const jivotnovodstvoQuiz5Add = path.includes('jivotnovodstvoQuiz5Add')
  const jivotnovodstvoQuiz6Add = path.includes('jivotnovodstvoQuiz6Add')
  const vnedAgrQuiz1Add = path.includes('vnedAgrQuiz1Add')
  const vnedAgrQuiz2Add = path.includes('vnedAgrQuiz2Add')
  const vnedAgrQuiz3Add = path.includes('vnedAgrQuiz3Add')
  const vnedJivQuiz1Add = path.includes('vnedJivQuiz1Add')
  const vnedJivQuiz2Add = path.includes('vnedJivQuiz2Add')
  const vnedJivQuiz3Add = path.includes('vnedJivQuiz3Add')
  const vnedJivQuiz4Add = path.includes('vnedJivQuiz4Add')
  const vnedJivQuiz5Add = path.includes('vnedJivQuiz5Add')
  const vnedJivQuiz6Add = path.includes('vnedJivQuiz6Add')

  const successFilled = path.includes('successFilled')
  const loggedIn = store.state.auth.user
  pathNew = pathNew.substr(1)
  if (pathNew.includes('/')) {
    pathNew = pathNew.split('/')[0] + '/:id'
  }
  let perm = null
  routes[0].children.forEach(item => {
    if (item.path === pathNew) {
      perm = item.permission
    }
  })
  if (authRequired && !loggedIn && !anketa1Add && !feedBackAnketaAdd
    && !anketa3Add && !anketa2Add && !anketaForStudentAdd && !anketaUdoyAdd
    && !agronomiyaQuiz1Add && !agronomiyaQuiz2Add && !agronomiyaQuiz3Add && !jivotnovodstvoQuiz2Add
    && !jivotnovodstvoQuiz1Add && !jivotnovodstvoQuiz3Add && !jivotnovodstvoQuiz4Add && !jivotnovodstvoQuiz5Add && !jivotnovodstvoQuiz6Add
    && !vnedAgrQuiz1Add && !vnedAgrQuiz2Add && !vnedAgrQuiz3Add && !vnedJivQuiz1Add && !vnedJivQuiz2Add && !vnedJivQuiz3Add && !vnedJivQuiz4Add && !vnedJivQuiz5Add && !vnedJivQuiz6Add && !successFilled) {
    next('/login')
  } else if (publicPages.includes(path) || path.includes('anketa1Add') || path.includes('feedBackAdd') ||
    path.includes('anketa3Add') || path.includes('anketa2Add') || path.includes('anketaForStudentAdd') ||
    path.includes('anketaUdoyAdd') || path.includes('agronomiyaQuiz1Add') || path.includes('agronomiyaQuiz2Add') ||
    path.includes('agronomiyaQuiz3Add') ||
    path.includes('successFilled') || path.includes('jivotnovodstvoQuiz1Add') || path.includes('jivotnovodstvoQuiz2Add')
    || path.includes('jivotnovodstvoQuiz3Add') || path.includes('jivotnovodstvoQuiz4Add') || path.includes('jivotnovodstvoQuiz5Add') || path.includes('jivotnovodstvoQuiz6Add')
    || path.includes('vnedAgrQuiz1Add') || path.includes('vnedJivQuiz1Add') || path.includes('vnedAgrQuiz2Add')
    || path.includes('vnedAgrQuiz3Add') || path.includes('vnedJivQuiz2Add') || path.includes('vnedJivQuiz3Add') || path.includes('vnedJivQuiz4Add') || path.includes('vnedJivQuiz5Add') || path.includes('vnedJivQuiz6Add')) {
    next()
  } else {
    if (loggedIn && store.state.auth.user.userRole.permissions != null) {
      if (path === '/') {
        next()
      }
      const userPermissions = JSON.parse(
        JSON.stringify(store.state.auth.user.userRole.permissions)
      );
      if (checkArray(userPermissions, perm)) {
        next()
      } else {
        next({
          name: 'ErrorPage403'
        })
      }
    } else {
      next('/login')
    }
  }
})
const checkArray = function (userPermissions, permission) {
  let flagShow = false
  userPermissions.forEach(item => {
    if (item.name.includes('SUPER_ADMIN')) {
      flagShow = true
    } else if (item.name.includes(permission)) {
      flagShow = true
    }
  })

  return flagShow
}

export default router
