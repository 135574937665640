<template>
  <div v-if="isSubmit" style="
  position: fixed;
    left: 30%;
    right: 50%;
    z-index: 999999;
    width: 701px;
    height: 135px;
    top: 30%;">
    <LoadingIcon icon="spinning-circles"/>
  </div>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="text-lg font-medium mt-10" style="color: white">Пре - пост Тест «ПО Животноводству и ветеринарии»</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="intro-y col-span-12 lg:col-span-12" :style="isSubmit ? 'pointer-events: none; filter: blur(5px);' : ''">
            <form class="validate-form" v-on:submit.prevent="create()">
              <!-- СВЕДЕНИЯ ОБ УЧАСТНИКЕ -->
              <div class="intro-y box">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Сведения об участнике</h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- Выбор участника -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Выберите ваше ФИО из списка<span class="text-primary-3"
                        >&nbsp;*</span>
                        </label>
                        <Select2 :options="uchastniki" v-model="anketa2.uchastnik" @select="loadUchastnikInfo($event)"/>
                      </div>
                      <!-- ФИО -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          ФИО
                        </label>
                        <input type="text"
                               :value="uchastnik.lastName + ' ' + uchastnik.firstName + ' ' + uchastnik.patronymic"
                               class="form-control italic" disabled/>
                      </div>
                      <!-- Дата рождения -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Год рождения
                        </label>
                        <input type="text" :value="uchastnik.birthDate" class="form-control italic" disabled/>
                      </div>

                      <!-- Адрес домохозяйства -->
                      <div class="input-form col-span-10">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Адрес домохозяйства
                        </label>
                        <input type="text" :value="uchastnik.address" class="form-control italic" disabled/>
                      </div>
                      <!-- Род занятий -->
                      <div class="input-form col-span-2">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Род занятий
                        </label>
                        <input type="text" :value="uchastnik.rodZanyatiy" class="form-control italic" disabled/>
                      </div>

                      <!-- Контактные данные -->
                      <div class="input-form col-span-3">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Контактные данные
                        </label>
                        <input type="text" :value="uchastnik.contacts" class="form-control italic" disabled/>
                      </div>
                      <!-- Размер сельхоз. угодий -->
                      <div class="input-form col-span-3">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Размер сельхоз. угодий
                        </label>
                        <input type="text" :value="uchastnik.razmerUgodiy" class="form-control italic" disabled/>
                      </div>
                      <!-- Возраст -->
                      <div class="input-form col-span-3">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Возраст
                        </label>
                        <select :value="uchastnik.vozrast" disabled class="form-select sm:mr-2 italic"
                                aria-label=".form-select-sm example">
                          <option value="С 18 до 29 лет">С 18 до 29 лет</option>
                          <option value="С 29 лет">С 29 лет</option>
                        </select>
                      </div>
                      <!-- Пол -->
                      <div class="input-form col-span-3">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Пол
                        </label>
                        <input type="text" :value="uchastnik.gender" class="form-control italic" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- КОНТРОЛЬНЫЕ ВОПРОСЫ -->
              <div class="intro-y box mt-5">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Контрольные вопросы</h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- Каков основной метод профилактики заразных болезней коров? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Уйлардын жугуштуу ооруларын алдын алуу үчүн негизги ыкма кайсы?</span>
                           / Каков основной метод профилактики заразных болезней коров?
                        </label>
                        <select v-model="anketa2.question1" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="Дегельминтизация">Дегельминтизация</option>
                          <option value="Диспансеризация">Диспансеризация</option>
                          <option value="Вакцинация">Вакцинация</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                      <!-- Отметьте из данного списка инвазионную болезнь? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Көрсөтүлгөн тизмеден инвазия (мите оорулары) ылаңдарына тиешелүүсүн белгилеп атаңыз?</span>
                           / Отметьте из данного списка инвазионную болезнь?
                        </label>
                        <select v-model="anketa2.question2" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="Бешенство">Кутурма / Бешенство</option>
                          <option value="Чесотка">Котур / Чесотка</option>
                          <option value="Мастит">Мастит</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                      <!-- Из каких основных веществ состоит корм? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Тоют кайсы негизги заттардан турат?</span>
                           / Из каких основных веществ состоит корм?
                        </label>
                        <select v-model="anketa2.question3" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="Протеин, клетчатка">Протеин, клетчатка</option>
                          <option value="Зола, сухое вещество">Күл, кургак зат / Зола, сухое вещество</option>
                          <option value="Сухое вещество, вода">Кургак зат, суу / Сухое вещество, вода</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                      <!-- В какую фазу вегетации надо косить сено? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Чөптү кайсы убакта чабуу керек?</span>
                           / В какую фазу вегетации надо косить сено?
                        </label>
                        <select v-model="anketa2.question4" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="После окончание цветения">Гүлдөп  бүтөрүндө / После окончание цветения</option>
                          <option value="В фазе бутанизации, с появлением 2-3 цветков">2-3 гүлү пайда болуп, бутон байлаганда / В фазе бутанизации, с появлением 2-3 цветков</option>
                          <option value="С появлением семян">Уруктанганда / С появлением семян</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                      <!-- Сколько кг протеина требуется на поддержание жизни коровы с живым весом 400 кг? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Тирүүлөй салмагы 400 кг уйдун жашоосуна керектүү протеин канча кг болушу керек?</span>
                          / Сколько кг протеина требуется на поддержание жизни коровы с живым весом 400 кг?
                        </label>
                        <select v-model="anketa2.question5" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="0,38">0,38</option>
                          <option value="0,25">0,25</option>
                          <option value="0,475">0,475</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                      <!-- Что нужно давать корове перед кормлением ее различными видами комбикорма? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Жемдин бардык түрүн берээрдин астында уйга эмне бериш керек?</span>
                           / Что нужно давать корове перед кормлением ее различными видами комбикорма?
                        </label>
                        <select v-model="anketa2.question6" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="Ничего">Эч нерсе / Ничего</option>
                          <option value="Вода">Суу / Вода</option>
                          <option value="2-3 кг сена">2-3 кг чөп / 2-3 кг сена</option>
                        </select>
                      </div>
                      <!-- Каким методом можно получить племенной приплод молочного направления? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Кайсы жол менен уйдан/кунаажындан жөндөмдүү, асыл тукумдуу жана тууту белгилүү болгон төл алууга болот?</span>
                           / Каким методом можно получить племенной приплод молочного направления?
                        </label>
                        <select v-model="anketa2.question7" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="Осеменение быком (волная случка)">Табигый (букадан качыруу) жолу менен / Осеменение быком (волная случка)</option>
                          <option value="Искусственное осеменение">Жасалма уруктандыруу жолу менен / Искусственное осеменение</option>
                          <option value="Покупка на скотском рынке">Мал базардан сатып алуу жолу менен / Покупка на скотском рынке</option>
                        </select>
                      </div>
                      <!-- Через сколько дней после отела можно осеменить корову? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Тууттан кийин уйларды канча күндөн кийин уруктандырса болот?</span>
                           / Через сколько дней после отела можно осеменить корову?
                        </label>
                        <select v-model="anketa2.question8" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="После 40 дней">40 күндөн кийин / После 40 дней</option>
                          <option value="Через 10 дней">10 күндөн кийин / Через 10 дней</option>
                          <option value="Нет разницы">Айырмасы жок / Нет разницы</option>
                        </select>
                      </div>
                      <!-- Через сколько дней после отела можно использовать молоко для переработки? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Туугандан кийин уйдун сүтүн канча күндөн кийин кайра иштетип чыгуу максатында колдонсо болот?</span>
                           / Через сколько дней после отёла можно использовать молоко для переработки?
                        </label>
                        <select v-model="anketa2.question9" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="2 дня">2 күн / 2 дня</option>
                          <option value="7 дней">7 күн / 7 дней</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                      <!-- Виды пастбищ? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Жайыттын негизги түрлөрү?</span>
                           / Виды пастбищ?
                        </label>
                        <select v-model="anketa2.question10" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="Естественные и культурные (искусственные)">Табигый жана эгилме (жасалма) жайыттар / Естественные и культурные (искусственные)</option>
                          <option value="Пашни">Айдоо жерлери / Пашни</option>
                          <option value="Естественные">Табигый жайыттар / Естественные</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                      <!-- С какого времени после рождения теленка лучше приучать к полно-смешанному рациону? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Музоо туулгандан кийин кайсы убакта толук кандуу аралашма рационго өткөрсө болот?</span>
                           / С какого времени после рождения теленка лучше приучать к полно-смешанному рациону?
                        </label>
                        <select v-model="anketa2.question11" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="С первой недели жизни теленка">Музоо туулгандан кийин  биринчи жумасында / С первой недели жизни теленка</option>
                          <option value="Со второй недели жизни теленка">Музоо туулгандан кийин  экинчи жумасында / Со второй недели жизни теленка</option>
                          <option value="С третьей недели жизни теленка">Музоо туулгандан  кийин  үчүнчү жумасында / С третьей недели жизни теленка</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                      <!-- Какова оптимальная температура в коровнике? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        ><span style="color: #1c3faa">Уй сарайда оптималдуу температура канча болуш керек?</span>
                           / Какова оптимальная температура в коровнике?
                        </label>
                        <select v-model="anketa2.question12" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="от 0 до 5 градусов по Цельсию">0дөн 5 °С чейин / от 0 до 5 градусов по Цельсию</option>
                          <option value="от 5 до 16 градусов по Цельсию">5тен 16 °С чейин / от 5 до 16 градусов по Цельсию</option>
                          <option value="от 20 до 25 градусов по Цельсию">20дан 25 °С чейин / от 20 до 25 градусов по Цельсию</option>
                          <option value="Не знаю">Билбейм / Не знаю</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary mt-5">
                Сохранить
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {defineComponent} from 'vue'
import axios from 'axios'
import store from '@/store'
import Select2 from "vue3-select2-component";
import router from "@/router";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},

  data() {
    return {
      gruppaId: this.$route.params.id,
      anketa2: {
        id: '',
        uchastnik: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: null,
        question11: null,
        question12: null
      },
      uchastnik: {
        lastName: '',
        firstName: '',
        patronymic: '',
        birthDate: '',
        gender: '',
        vozrast: null,
        address: '',
        rodZanyatiy: '',
        contacts: '',
        razmerUgodiy: ''
      },
      uchastniki: [],
      isSubmit: false
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.anketa2, this.$options.data().anketa1)
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'uchastnik/listForCreateAnketa/' + 2 + '/' + this.gruppaId).then(response => {
            if (response.data != null) {
              this.uchastniki = []
              response.data.forEach(item => {
                this.uchastniki.push({id: item.id, text: item.lastName + ' ' + item.firstName})
              })
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    create() {
      this.isSubmit = true
      axios({
        method: 'post',
        url: API_URL + 'anketa2/add',
        data: {
          uchastnik: this.anketa2.uchastnik != null ? {id: this.anketa2.uchastnik} : null,
          gruppa: {id: this.gruppaId},
          question1: this.anketa2.question1,
          question2: this.anketa2.question2,
          question3: this.anketa2.question3,
          question4: this.anketa2.question4,
          question5: this.anketa2.question5,
          question6: this.anketa2.question6,
          question7: this.anketa2.question7,
          question8: this.anketa2.question8,
          question9: this.anketa2.question9,
          question10: this.anketa2.question10,
          question11: this.anketa2.question11,
          question12: this.anketa2.question12
        }
      }).then(response => {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          focusConfirm: true,
          icon: 'success',
          title: 'Ваша запись успешно сохранена!',
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        router.push('/login')
      }).catch(error => {
        this.isSubmit = false
        if (error.response.status===409){
          this.$swal.fire({
            toast: true,
            focusConfirm: true,
            position: 'top-end',
            showConfirmButton: false,
            icon: 'error',
            title: 'Вы уже заполнили эту анкету',
            text: 'Вторую анкету можно заполнить через 3 месяца',
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer)
              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
          })
        } else {
          this.$swal.fire({
            toast: true,
            focusConfirm: true,
            position: 'top-end',
            showConfirmButton: false,
            icon: 'error',
            title: 'Внутренняя ошибка сервера',
            text: 'Попробуйте позже',
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer)
              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
          })
        }
      })
    },
    loadUchastnikInfo(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'uchastnik/' + event.id).then(response => {
            if (response.data != null) {
              var uchastnikFromDb = response.data
              this.uchastnik.lastName = uchastnikFromDb.lastName != null ? uchastnikFromDb.lastName : ''
              this.uchastnik.firstName = uchastnikFromDb.firstName != null ? uchastnikFromDb.firstName : ''
              this.uchastnik.patronymic = uchastnikFromDb.patronymic != null ? uchastnikFromDb.patronymic : ''
              this.uchastnik.birthDate = uchastnikFromDb.birthDate != null ? uchastnikFromDb.birthDate : null
              this.uchastnik.gender = uchastnikFromDb.gender.name
              if (uchastnikFromDb.birthDate != null) {
                var age = new Date().getFullYear() - new Date(uchastnikFromDb.birthDate).getFullYear()
                if (age >= 18 && age < 29) {
                  this.uchastnik.vozrast = 'С 18 до 29 лет'
                } else {
                  this.uchastnik.vozrast = 'С 29 лет'
                }
              }
              this.uchastnik.razmerUgodiy = uchastnikFromDb.razmerUgodiy != null ? uchastnikFromDb.razmerUgodiy : ''
              this.uchastnik.rodZanyatiy = uchastnikFromDb.rodZanyatiy != null ? uchastnikFromDb.rodZanyatiy : ''
              this.uchastnik.contacts = uchastnikFromDb.contacts != null ? uchastnikFromDb.contacts : ''
              let addressOfUchastnik = ''
              if (uchastnikFromDb.oblast != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.oblast.name + ', '
              }
              if (uchastnikFromDb.rayon != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.rayon.name + ', '
              }
              if (uchastnikFromDb.aymak != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.aymak.name + ', '
              }
              if (uchastnikFromDb.nasPunkt != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.nasPunkt.name + ', '
              }
              if (uchastnikFromDb.street != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.street + ' ' + uchastnikFromDb.houseNumber
              }
              this.uchastnik.address = addressOfUchastnik
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    }
  }
})
</script>
