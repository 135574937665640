<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="text-lg font-medium mt-10">Пользователи</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div v-if="isAdd"
          class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
          <a      href="javascript:;"
                  data-toggle="modal"
                  data-target="#create-modal"
                  class="btn btn-primary shadow-md mr-2"
                  @click="showModalCreate">Добавить пользователя</a>
        </div>
        <!-- BEGIN: Data List -->
        <div class="col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2" id="table_id">
            <thead>
            <tr>
              <th class="whitespace-nowrap">Логин</th>
              <th class="whitespace-nowrap">Роль</th>
              <th class="whitespace-nowrap">ФИО</th>
              <th class="whitespace-nowrap">Статус</th>
              <th v-if="isEdit" class="text-center whitespace-nowrap">Действия</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in users"
              :key="item.id">
              <td>
                <div class="text-s">
                  {{ item.username }}
                </div>
              </td>
              <td>
                <div class="text-s" v-for="(userRole, name) in item.userRole" v-bind:key="userRole">
                  <div v-if="name === 'nameRu'">
                    {{ userRole }}
                  </div>
                </div>
              </td>
              <td>
                <div class="text-s">
                  {{ item.lastName + ' ' + item.firstName }}
                </div>
              </td>
              <td>
                <div class="text-s">
                  {{ item.isActive ? 'Активен' : 'Деактивен' }}
                </div>
              </td>
              <td v-if="isEdit" class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;"
                     data-toggle="modal"
                     data-target="#create-modal"
                     @click="showModalEdit(item)">
                    <CheckSquareIcon class="w-4 h-4 mr-1"/>
                    Редактировать
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                    @click="showModalDelete(item)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1"/>
                    Удалить
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- END: Pagination -->
      </div>
      <!-- BEGIN: Create Oblast Modal -->
      <div
        id="create-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop='static'
      >
        <div class="modal-dialog" style='width: 60% !important;'>
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <div class="text-3xl mt-5">{{ nameForModal }}</div>
              </div>
              <form v-on:submit.prevent="isEditingModal ? edit() : create()">
                <div class="p-5">
                  <div class='grid grid-cols-12 gap-2'>
                    <div class='col-span-6'>
                      <label class="form-label left-0">Логин (ПИН)</label>
                      <input v-model="user.username" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                             type="text"
                             maxlength="14"
                             :readonly="isEditingModal"
                             required
                             class="intro-x form-control"
                      />
                    </div>
                    <div class='col-span-6'>
                      <label class="form-label left-0">Пароль</label>
                      <div class="input-group">
                        <input v-model="user.password"
                               class="intro-x form-control"
                               :type="isShowPsw ? 'text' : 'password'"
                               placeholder="Пароль"
                               aria-describedby="input-group-price"
                        />
                        <div id="input-group-price" @click="showPsw" class="input-group-text">
                          <svg v-if="!isShowPsw" xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off block mx-auto block mx-auto"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                          <svg v-if="isShowPsw" xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye block mx-auto block mx-auto"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                        </div>
                      </div>
                    </div>
                    <div class='col-span-12'>
                      <label class="form-label left-0">Фамилия</label>
                      <input v-model="user.lastName"
                             type="text"
                             required
                             class="intro-x form-control"
                      />
                    </div>
                    <div class='col-span-12'>
                      <label class="form-label left-0">Имя</label>
                      <input v-model="user.firstName"
                             type="text"
                             required
                             class="intro-x form-control"
                      />
                    </div>
                    <div class='col-span-12'>
                      <label class="form-label left-0">Отчество</label>
                      <input v-model="user.patronymic"
                             type="text"
                             class="intro-x form-control"
                      />
                    </div>
                    <div class='col-span-6'>
                      <label class="form-label left-0">Телефон</label>
                      <input v-model="user.phone"
                             type="tel"
                             class="intro-x form-control"
                      />
                    </div>
                    <div class='col-span-6'>
                      <label class="form-label left-0">Email</label>
                      <input v-model="user.email"
                             type="email"
                             class="intro-x form-control"
                      />
                    </div>
                    <div class='col-span-12'>
                      <label class="form-label left-0">Роль</label>
                      <Select2 required v-model="user.userRole" :options="userroles" />
                    </div>
                    <div class='col-span-12'>
                      <label class="form-label left-0">Активность</label>
                      <div class="mt-2">
                        <input v-model="user.isActive" type="checkbox" class="form-check-switch" />
                      </div>
                    </div>
                  </div>
                <button type="submit" class="btn btn-primary py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top">
                  {{ nameOfButtonForModal }}
                </button>
                <button type="button" id="close" data-dismiss='modal' @click="resetData"
                        class='btn btn-light py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top'>
                  Отмена
                </button>
              </div>
              </form>
              <div
                class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Create Oblast Modal -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div
        id="delete-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3"/>
                <div class="text-3xl mt-5">Вы уверены?</div>
                <div class="text-gray-600 mt-2">
                  Вы уверены что хотите удалить эту страну? <br/>
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-outline-secondary w-24 mr-1"
                >
                  Отмена
                </button>
                <button @click="confirmDelete" data-dismiss="modal" type="button" class="btn btn-danger w-24">Удалить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import axios from 'axios'
import store from '@/store'
import $ from 'jquery'
import Select2 from 'vue3-select2-component'

const API_URL = store.state.API_URI

export default defineComponent({
  components : {
    Select2
  },
  data() {
    return {
      nameForModal: '',
      nameOfButtonForModal: '',
      isEditingModal: false,
      users: [],
      user: {
        id: '',
        username: '',
        password: '',
        isActive: true,
        hospital: null,
        rayon: null,
        oblast: null,
        phone: '',
        firstName: '',
        lastName: '',
        patronymic: '',
        userRole: null,
        email: '',
        levelOfUser: null
      },
      itemForDelete: null,
      userroles: [],
      isShowPsw: false,
      userFromState: store.state.auth.user,
      isAdd: false,
      isEdit: false
    }
  },
  mounted() {
    this.userFromState.userRole.permissions.forEach(item => {
      if (item.name.includes('SUPER_ADMIN') || item.name.includes('USER_EDIT')) {
        this.isEdit = true
      }
      if (item.name.includes('SUPER_ADMIN') || item.name.includes('USER_CREATE')) {
        this.isAdd = true
      }
    })
    this.refreshData()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.user, this.$options.data().user)
    },

    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'users/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.users = response.data
              $("#table_id").dataTable().fnDestroy();
              $(document).ready( function () {
                $('#table_id').DataTable({
                    "language": {
                      "processing": "Подождите...",
                      "search": "Поиск:",
                      "lengthMenu": "Показать _MENU_ записей",
                      "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
                      "infoEmpty": "Записи с 0 до 0 из 0 записей",
                      "infoFiltered": "(отфильтровано из _MAX_ записей)",
                      "infoPostFix": "",
                      "loadingRecords": "Загрузка записей...",
                      "zeroRecords": "Записи отсутствуют.",
                      "emptyTable": "В таблице отсутствуют данные",
                      "paginate":
                        {
                          "first": "Первая",
                          "previous": "Предыдущая",
                          "next": "Следующая",
                          "last": "Последняя"
                        },
                      "aria": {
                        "sortAscending": ": активировать для сортировки столбца по возрастанию",
                        "sortDescending": ": активировать для сортировки столбца по убыванию"
                      },
                      "select": {
                        "rows": {
                          "_": "Выбрано записей: %d",
                          "0": "Кликните по записи для выбора",
                          "1": "Выбрана одна запись"
                        }
                      },
                      "buttons":
                        {
                          "print": "Печать"
                        }
                    },
                    columnDefs : [
                      { 'visible': false, 'targets': [ ] }
                    ]
                    // "buttons": ["csv", "excel", "print"]
                  }
                );
              })
            }
          }).catch(error => {
            this.checkResponseError(error)
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    showModalDelete(item) {
      this.itemForDelete = item
    },
    confirmDelete() {
      axios
        .delete(API_URL + 'users/user/' + this.itemForDelete.id, {
          headers: {
            Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          }
        }).then(response => {
          if (response.data != null) {
            this.deleteResponse(response)
            this.refreshData()
          }
        })
    },
    showModalCreate() {
      this.nameForModal = 'Добавление пользователя'
      this.nameOfButtonForModal = 'Добавить'
      this.isEditingModal = false
      this.getDatasForCreateAndUpdate()
    },
    create() {
      axios({
        method: 'post',
        url: API_URL + 'users/add/',
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          patronymic: this.user.patronymic,
          isActive: this.user.isActive,
          password: this.user.password,
          phone: this.user.phone,
          userRole: this.user.userRole != null ? {id: this.user.userRole} : null,
          username: this.user.username,
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    showModalEdit(item) {
      this.isEditingModal = true
      this.nameForModal = 'Редактирование пользователя'
      this.nameOfButtonForModal = 'Изменить'
      this.user.id = item.id
      this.user.email = item.email
      this.user.firstName = item.firstName
      this.user.lastName = item.lastName
      this.user.patronymic = item.patronymic
      this.user.isActive = item.isActive
      this.user.phone = item.phone
      this.user.userRole = item.userRole != null ? item.userRole.id : null
      this.user.username = item.username
      this.getDatasForCreateAndUpdate()
    },
    edit() {
      axios({
        method: 'put',
        url: API_URL + 'users/user/' + this.user.id,
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          id: this.user.id,
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          patronymic: this.user.patronymic,
          isActive: this.user.isActive,
          password: this.user.password,
          phone: this.user.phone,
          userRole: this.user.userRole != null ? {id: this.user.userRole} : null,
          username: this.user.username,
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    getDatasForCreateAndUpdate(){
      try {
        (async () => {

          await axios.get(API_URL + 'userroles/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.userroles = []
              response.data.forEach(item => {
                this.userroles.push({id: item.id, text: item.nameRu + ' (' + item.name + ')'})
              })
            }
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },

    showPsw() {
      this.isShowPsw ? this.isShowPsw = false : this.isShowPsw = true
    }
  }
})
</script>
