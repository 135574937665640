import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import simpleMenu from './simple-menu'
import topMenu from './top-menu'
import auth from './auth-module'

const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    auth
  },
  state: {
    API_URI: 'https://anketa.kg/back/api/'
  }
})

export function useStore() {
  return store
}

export default store
