<template>
  <div v-if="isSubmit" style="
  position: fixed;
    left: 30%;
    right: 50%;
    z-index: 999999;
    width: 701px;
    height: 135px;
    top: 30%;">
    <LoadingIcon icon="spinning-circles"/>
  </div>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="text-lg font-medium mt-10" style="color: white">Пре пост тест по Агрономии</h2>
      <h2 class="text-lg font-medium" style="color: white"> «Топурактын асылдуулугу, тоют өндүрүү жана көп жылдык чөптөрдү өстүрүү агротехникасы»</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="intro-y col-span-12 lg:col-span-12" :style="isSubmit ? 'pointer-events: none; filter: blur(5px);' : ''">
            <form class="validate-form" v-on:submit.prevent="create()">
              <!-- СВЕДЕНИЯ ОБ УЧАСТНИКЕ -->
              <div class="intro-y box">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Сведения об участнике</h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- Выбор участника -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Выберите ваше ФИО из списка<span class="text-primary-3"
                        >&nbsp;*</span>
                        </label>
                        <Select2 required :options="uchastniki" v-model="anketa3.uchastnik" @select="loadUchastnikInfo($event)"/>
                      </div>
                      <!-- ФИО -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          ФИО
                        </label>
                        <input type="text"
                               :value="uchastnik.lastName + ' ' + uchastnik.firstName + ' ' + uchastnik.patronymic"
                               class="form-control italic" disabled/>
                      </div>
                      <!-- Дата заполнения -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Дата заполнения
                        </label>
                        <input type="text"
                               :value="this.format_date(currentDate)"
                               class="form-control italic" disabled/>
                      </div>
                      <!-- Место проведения -->
                      <div class="input-form col-span-12">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Место проведения
                        </label>
                        <input type="text"
                               :value="placeProvedeniya"
                               class="form-control italic" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- КОНТРОЛЬНЫЕ ВОПРОСЫ -->
              <div class="intro-y box mt-5">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Контрольные вопросы</h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- Что влияет на плодородие почвы? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Топурактын асылдуулугуна эмне таасир берет?</span>
                         / Что влияет на плодородие почвы?
                        </label>
                        <select v-model="anketa3.question1" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="наличие в почве перегноя">Жердин курамындагы чириндилер | наличие в почве перегноя</option>
                          <option value="наличие в почве воды">Жердин курамындагы суу | наличие в почве воды</option>
                          <option value="наличие в почве песка и глины">Жердин курамындагы кум жана чопо | наличие в почве песка и глины</option>
                        </select>
                      </div>
                      <!-- К сочным кормам относятся: -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Чыктуу тоютка кайсылар кирет:</span>
                          / К сочным кормам относятся:
                        </label>
                        <select v-model="anketa3.question2" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="зеленые, корне- и клубнеплоды, бахчевые, силос">жашыл тоют, тамыр жана клубен жемиштери, бахчалар, силос | зеленые, корне- и клубнеплоды, бахчевые, силос</option>
                          <option value="барда, жом, кормовая патока, пивная дробина">барда, жом, тоют патокасы, сыра ачыткысы | барда, жом, кормовая патока, пивная дробина</option>
                          <option value="зерно, отруби, шрот, жмых, травяная мука">дан, кебек, шрот, күнжара, чөп уну | зерно, отруби, шрот, жмых, травяная мука</option>
                        </select>
                      </div>
                      <!-- К грубым кормам относятся: -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Кесек тоютка кайсылар кирет:</span>
                          / К грубым кормам относятся:
                        </label>
                        <select v-model="anketa3.question3" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="зеленые, корне- и клубнеплоды, бахчевые, силос">жашыл тоют, тамыр жана клубен жемиштери, бахчалар, силос | зеленые, корне- и клубнеплоды, бахчевые, силос</option>
                          <option value="зерно, отруби, шрот, жмых, травяная мука">дан, кебек, шрот, күнжара, чөп уну | зерно, отруби, шрот, жмых, травяная мука</option>
                          <option value="сено, солома, мякина">чөп, саман, самандын тобу | сено, солома, мякина</option>
                        </select>
                      </div>

                      <!-- Основные вредители люцерны в фазу бутонизации: -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Люцернанын бутон байлоо фазасындагы зыян келтирген зыянкечтер:</span>
                          / Основные вредители люцерны в фазу бутонизации:
                        </label>
                        <select v-model="anketa3.question4" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="долгоносики">узун мурундуулар | долгоносики</option>
                          <option value="семяеды">урук жечгичтер | семяеды</option>
                          <option value="тля, клопы, почкоеды">шире соргуч, коңуз, бүчүр жегич | тля, клопы, почкоеды</option>
                        </select>
                      </div>
                      <!-- Уборка люцерны и эспарцета на сено:  -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Люцерна менен эспарцети кесек тоютка кайсы фазада оруп баштоо керек?</span>
                          / Фаза уборки люцерны и эспарцета на сено
                        </label>
                        <select v-model="anketa3.question5" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="в фазу стеблевания или начала бутонизации">бутон байлоо жана гүлдөп башталуу фазасында | в фазу стеблевания или начала бутонизации</option>
                          <option value="в фазу цветения">толук гүлдөө фазасында | в фазу цветения</option>
                          <option value="в фазу полной спелости семян">уруктардын толук жетилүү фазасында | в фазу полной спелости семян</option>
                        </select>
                      </div>

                      <div class="col-span-12 mt-3">
                        <p class="text-base font-bold" style="justify-content: center; align-items: center; display: flex;color: #1c3faa">
                          «Дан эгиндердин, тоют түп тамырдуулардын жана тоют кызылчанын агротехникасы»
                        </p>
                        <p class="text-base font-bold" style="justify-content: center; align-items: center; display: flex">
                          «Агротехника выращивания кормовых злаков и корнеплодов»
                        </p>
                      </div>
                      <!-- Норма высева ячменя кг/га   -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Арпаны себүүнүн ченеми кг/га:</span>
                          Норма высева ячменя кг/га
                        </label>
                        <select v-model="anketa3.question6" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="100-120 кг/га">100-120 кг/га</option>
                          <option value="230-250 кг/га">230-250 кг/га</option>
                          <option value="165-220 кг/га">165-220 кг/га </option>
                        </select>
                      </div>
                      <!-- Какая культура более засухоустойчивая    -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Кайсы өсүмдүк кургакчылыкка чыдамдуу?</span>
                          Какая культура более засухоустойчивая
                        </label>
                        <select v-model="anketa3.question7" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="ячмень">ячмень</option>
                          <option value="пшеница">пшеница</option>
                          <option value="овес">овес</option>
                        </select>
                      </div>
                      <!-- Укажите лучший срок посева кормовой свёклы    -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Тоют кызылчасын айдоонун ыңгайлуу убактысын көрсөтүңүз:</span>
                          Укажите лучший срок посева кормовой свёклы
                        </label>
                        <select v-model="anketa3.question8" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="температура почвы 6 - 7С°">кыртыштын температурасы 6 - 7С° | температура почвы 6 - 7С°</option>
                          <option value="температура почвы 8 - 10С°">кыртыштын температурасы 8 - 10С° | температура почвы 8 - 10С°</option>
                          <option value="температура почвы 10 - 12С°">кыртыштын температурасы 10 - 12С° | температура почвы 10 - 12С°</option>
                        </select>
                      </div>
                      <!-- Укажите оптимальную глубину заделки семян свёклы    -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Кызылча үрөнүнүн оптималдуу тигүү тереңдигин көрсөтүңүз</span>
                          Укажите оптимальную глубину заделки семян свёклы
                        </label>
                        <select v-model="anketa3.question9" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="7 - 8 см">7 - 8 см</option>
                          <option value="2,5 - 3 см">2,5 - 3 см</option>
                          <option value="5 - 6 см">5 - 6 см</option>
                        </select>
                      </div>
                      <!-- Какая болезнь поражает зерновые культуры    -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Дан өсүмдүктөрүн кайсы илдет жабыркатат</span>
                          Какая болезнь поражает зерновые культуры
                        </label>
                        <select v-model="anketa3.question10" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="фитофтороз">фитофтороз</option>
                          <option value="головня">кара көсө | головня</option>
                          <option value="черная ножка">тамыр чириги | черная ножка</option>
                        </select>
                      </div>
                      <!-- Гидропоника это?     -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Гидропоника -бул?</span>
                          Гидропоника это?
                        </label>
                        <select v-model="anketa3.question11" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="хранение в подвале">жертөлөдө сактоо | хранение в подвале</option>
                          <option value="выращивание растений без грунта, на питательных растворах">өсүмдүктөрдү топураксыз, азыктандыруучу эритмелерде өстүрүү | выращивание растений без грунта, на питательных растворах</option>
                          <option value="увлажнённый корм">нымдалган тоют | увлажнённый корм</option>
                        </select>
                      </div>
                      <div class="col-span-12 mt-3">
                        <p class="text-base font-bold" style="justify-content: center; align-items: center; display: flex;color: #1c3faa">«Силосту даярдоо жана сактоо. Тоютту жедирүүгө даярдоо жана сактоо»
                        </p>
                        <p class="text-base font-bold" style="justify-content: center; align-items: center; display: flex">«Приготовление и хранение силоса.
                          Хранение и подготовка кормов к скармливанию»
                        </p>
                      </div>
                      <!-- Какие растения хорошо силосуются?     -->
                      <div class="input-form col-span-6">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Кайсы өсүмдүктөр жакшы силостолот?</span>
                          Какие растения хорошо силосуются?
                        </label>
                        <select v-model="anketa3.question12" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="люцерна эспарцет">кара беде, эспарцет | люцерна эспарцет</option>
                          <option value="картофельная ботва">картөшкөнүн сабагы | картофельная ботва</option>
                          <option value="кукуруза, злаковые травы, подсолнечник">жугөрү, дан чөбү, кун карама | кукуруза, злаковые травы, подсолнечник</option>
                        </select>
                      </div>
                      <!-- Какие методы обработки зернохранилищ против амбарных вредителей вы знаете?     -->
                      <div class="input-form col-span-6">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Кампа зыянкечтерине каршы кандай тазалоо ыкмаларын билесиңер?</span>
                          Какие методы обработки зернохранилищ против амбарных вредителей вы знаете?
                        </label>
                        <select v-model="anketa3.question13" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="профилактический, механический, известью, фумигацией">профилактикалык, механикалык, акиташ менен, фумигациялоо | профилактический, механический, известью, фумигацией</option>
                          <option value="биологический метод">биологикалык ыкма | биологический метод</option>
                          <option value="установить ультрафиолетовые лампы">ультрафиолет лампаларын орнотуу | установить ультрафиолетовые лампы</option>
                        </select>
                      </div>
                      <!-- В какой фазе развития кукурузы заготавливается качественный силос?     -->
                      <div class="input-form col-span-6">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Сапаттуу жүгөрү силосту кайсы өсүү фазасында жасалат?</span>
                          В какой фазе развития кукурузы заготавливается качественный силос?
                        </label>
                        <select v-model="anketa3.question14" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="фаза образования початков">жугорунун сотосу түзүлүү фазасында | фаза образования початков</option>
                          <option value="в фазе молочно-восковой спелости початков">жугорунун сотосу сүт-думбул фазасында | в фазе молочно-восковой спелости початков</option>
                          <option value="в фазе полного созревания зерна">жугорунун даны толук бышкан фазасында | в фазе полного созревания зерна</option>
                        </select>
                      </div>
                      <!-- С какой целью проводится подготовка кормов к скармливанию?     -->
                      <div class="input-form col-span-6">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Тоютту жедирүүгө даярдоонун максаты?</span>
                          С какой целью проводится подготовка кормов к скармливанию?
                        </label>
                        <select v-model="anketa3.question15" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="повышение влажности">нымдуулугун жогорулатуу | повышение влажности</option>
                          <option value="увеличение объемов кормов">тоюттун көлөмүн кобойтуу | увеличение объемов кормов</option>
                          <option value="повышение перевариваемости">тоюттун көлөмүн кобойтуу | повышение перевариваемости</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary mt-5">
                Сохранить
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {defineComponent} from 'vue'
import axios from 'axios'
import store from '@/store'
import Select2 from "vue3-select2-component";
import router from "@/router";
import moment from "moment";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},

  data() {
    return {
      gruppaId: this.$route.params.id,
      anketa3: {
        id: '',
        uchastnik: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: null,
        question11: null,
        question12: null,
        question13: null,
        question14: null,
        question15: null
      },
      uchastnik: {
        lastName: '',
        firstName: '',
        patronymic: ''
      },
      uchastniki: [],
      currentDate: new Date(),
      placeProvedeniya: '',
      isSubmit: false
    }
  },
  mounted() {
    this.refreshData()
    this.loadGruppaInfo()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.anketa3, this.$options.data().anketa3)
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'uchastnik/listForCreateAnketa/' + 3 + '/' + this.gruppaId).then(response => {
            if (response.data != null) {
              this.uchastniki = []
              response.data.forEach(item => {
                this.uchastniki.push({id: item.id, text: item.lastName + ' ' + item.firstName})
              })
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    create() {
      this.isSubmit = true
      axios({
        method: 'post',
        url: API_URL + 'anketa3/add',
        data: {
          uchastnik: this.anketa3.uchastnik != null ? {id: this.anketa3.uchastnik} : null,
          gruppa: {id: this.gruppaId},
          question1: this.anketa3.question1,
          question2: this.anketa3.question2,
          question3: this.anketa3.question3,
          question4: this.anketa3.question4,
          question5: this.anketa3.question5,
          question6: this.anketa3.question6,
          question7: this.anketa3.question7,
          question8: this.anketa3.question8,
          question9: this.anketa3.question9,
          question10: this.anketa3.question10,
          question11: this.anketa3.question11,
          question12: this.anketa3.question12,
          question13: this.anketa3.question13,
          question14: this.anketa3.question14,
          question15: this.anketa3.question15
        }
      }).then(response => {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          focusConfirm: true,
          icon: 'success',
          title: 'Ваша запись успешно сохранена!',
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        router.push('/login')
      }).catch(error => {
        this.isSubmit = false
        this.$swal.fire({
          toast: true,
          focusConfirm: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Внутренняя ошибка сервера',
          text: 'Попробуйте позже',
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
      })
    },
    loadUchastnikInfo(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'uchastnik/' + event.id).then(response => {
            if (response.data != null) {
              var uchastnikFromDb = response.data
              this.uchastnik.lastName = uchastnikFromDb.lastName != null ? uchastnikFromDb.lastName : ''
              this.uchastnik.firstName = uchastnikFromDb.firstName != null ? uchastnikFromDb.firstName : ''
              this.uchastnik.patronymic = uchastnikFromDb.patronymic != null ? uchastnikFromDb.patronymic : ''
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    loadGruppaInfo() {
      try {
        (async () => {
          await axios.get(API_URL + 'gruppa/getById', {
            params: {
              id: this.gruppaId
            }
          }).then(response => {
            if (response.data != null) {
              let placeProvedeniya = ''
              if (response.data.oblast != null) {
                placeProvedeniya = placeProvedeniya + response.data.oblast.name + ', '
              }
              if (response.data.rayon != null) {
                placeProvedeniya = placeProvedeniya + response.data.rayon.name + ', '
              }
              if (response.data.aymak != null) {
                placeProvedeniya = placeProvedeniya + response.data.aymak.name + ', '
              }
              if (response.data.nasPunkt != null) {
                placeProvedeniya = placeProvedeniya + response.data.nasPunkt.shortName
              }
              this.placeProvedeniya = placeProvedeniya
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
  }
})
</script>
