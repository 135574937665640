<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto">
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              Anketa.KG <br />
            </div>
<!--            <div-->
<!--              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"-->
<!--            >-->
<!--              Государственный портал электронных услуг электронных заявлений на регистрацию рождения и регистрацию смерти-->
<!--            </div>-->
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Вход в систему
            </h2>
            <form v-on:submit.prevent="handleLogin()">
              <div class="intro-x mt-8">
                <input v-model="userForm.username"
                       type="text"
                       class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                       placeholder="Логин"
                />
                <div class="input-group mt-4">
                <input v-model="userForm.password"
                       :type="isShowPsw ? 'text' : 'password'"
                       class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                       placeholder="Пароль"
                       aria-describedby="input-group-price"
                />
                <div id="input-group-price" @click="showPsw" class="input-group-text">
                  <svg v-if="!isShowPsw" xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off block mx-auto block mx-auto"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                  <svg v-if="isShowPsw" xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye block mx-auto block mx-auto"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                </div>
                </div>
              </div>
              <div
                class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >
              </div>
              <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button type="submit" class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">
                  Войти
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  data () {
    return {
      userForm: {
        username: '',
        password: ''
      },
      isShowPsw: false
    }
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  methods: {
    showPsw() {
      this.isShowPsw ? this.isShowPsw = false : this.isShowPsw = true
    },
    handleLogin() {
      this.$store.dispatch('auth/login', this.userForm).then(
        () => {
          this.$router.push('/')
        },
        (error) => {
          this.invalidCredentials(error)
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        }
      )
    }
  }
})
</script>
