<template>
  <div v-if="isSubmit" style="
  position: fixed;
    left: 30%;
    right: 50%;
    z-index: 999999;
    width: 701px;
    height: 135px;
    top: 30%;">
    <LoadingIcon icon="spinning-circles"/>
  </div>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="text-lg font-medium mt-10" style="color: white">Оценка тренинга со стороны участников / <span
        class="kyrg">&nbsp;Тренингди катышуучулар тарабынан баалоо</span></h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="intro-y col-span-12 lg:col-span-12"
               :style="isSubmit ? 'pointer-events: none; filter: blur(5px);' : ''">
            <form class="validate-form" v-on:submit.prevent="create()">
              <!-- СВЕДЕНИЯ ОБ УЧАСТНИКЕ -->
              <div class="intro-y box">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Сведения об участнике / <span class="kyrg"> &nbsp;Катышуучу жөнүндө маалымат</span>
                  </h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- Общая информация-->
                      <div class="col-span-8">
                        <UserCheckIcon class="w-4 h-4 mr-2"/>
                        ФИО тренера(-ов): / <span class="kyrg"> &nbsp;Тренердин аты-жөнү:</span> &nbsp;<span
                        class="italic">{{ this.gruppa.prepods }}</span>
                      </div>
                      <div class="col-span-4">
                        <CalendarIcon class="w-4 h-4 mr-2"/>
                        Дата оценки: /<span class="kyrg">&nbsp;Баалоо күнү: </span><span
                        class="italic">{{ this.format_date(currentDate) }}</span>
                      </div>
                      <div class="col-span-12 mb-4 mt-2">
                        <NavigationIcon class="w-4 h-4 mr-2"/>
                        Место проведения: /<span class="kyrg">&nbsp;Өткөрүлүүчү жер:</span>&nbsp;<span
                        class="italic">{{ this.gruppa.placeProvedeniya }}</span>
                      </div>
                      <!-- Выбор участника -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Выберите ваше ФИО из списка /<span
                          class="kyrg">&nbsp;Тизмеден аты-жөнүңүздү тандаңыз</span><span class="text-primary-3"
                        >&nbsp;*</span>
                        </label>
                        <Select2 required :options="uchastniki" v-model="feedBackAnketa.uchastnik"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- КОНТРОЛЬНЫЕ ВОПРОСЫ -->
              <div class="intro-y box mt-5">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Контрольные вопросы /<span class="kyrg">&nbsp;Контролдук суроолор</span>
                  </h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <div class="col-span-12">
                        <p class="font-base font-bold">1. Участие на тренингах /<span class="kyrg">&nbsp;Тренингдерге катышуу</span>
                        </p>
                      </div>
                      <!-- Вы раньше участвовали на тренингах, консультациях по аналогичной теме?-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Вы раньше участвовали на тренингах, консультациях по аналогичной теме? /
                            <span class="kyrg">&nbsp;Буга чейин ушул сыяктуу темадагы тренингдерге, консультацияларга катышып көрдүңүз беле?</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question1" class="form-check-input"
                                     type="radio"
                                     name="question1" value="Да"/>
                              <label class="form-check-label">Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question1" class="form-check-input"
                                     type="radio"
                                     name="question1" value="Нет"/>
                              <label class="form-check-label">Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 mt-4">
                        <p class="font-base font-bold">2. Оценка предоставляемого материала / <span class="kyrg">&nbsp;Берилген окуу материалды баалоо</span>
                        </p>
                      </div>
                      <!-- Предложенный материал обладает практической ценностью-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Предложенный материал обладает практической ценностью /
                            <span class="kyrg">&nbsp;Сунушталган материалдын практикалык мааниси бар</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question2" class="form-check-input"
                                     type="radio"
                                     name="question2" value="Плохо"/>
                              <label class="form-check-label">Плохо /<span
                                class="kyrg">&nbsp;Канааттандырбайт</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question2" class="form-check-input"
                                     type="radio"
                                     name="question2" value="Среднее"/>
                              <label class="form-check-label">Среднее /<span class="kyrg">&nbsp;Орточо</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question2" class="form-check-input"
                                     type="radio"
                                     name="question2" value="Хорошо"/>
                              <label class="form-check-label">Хорошо /<span class="kyrg">&nbsp;Жакшы</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Предложенный материал обладает достаточной новизной-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Предложенный материал обладает достаточной новизной /
                            <span
                              class="kyrg">&nbsp;Сунушталган материал жетиштүү даражада инновацияга (жаңылыкка) ээ</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question3" class="form-check-input"
                                     type="radio"
                                     name="question3" value="Плохо"/>
                              <label class="form-check-label">Плохо /<span
                                class="kyrg">&nbsp;Канааттандырбайт</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question3" class="form-check-input"
                                     type="radio"
                                     name="question3" value="Среднее"/>
                              <label class="form-check-label">Среднее /<span class="kyrg">&nbsp;Орточо</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question3" class="form-check-input"
                                     type="radio"
                                     name="question3" value="Хорошо"/>
                              <label class="form-check-label">Хорошо /<span class="kyrg">&nbsp;Жакшы</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Содержание тренинга соответствует поставленным задачам-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Содержание тренинга соответствует поставленным задачам
                            /<span class="kyrg">&nbsp;Окуунун (тренингдин) мазмуну коюлган максаттарга ылайыктуу</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question4" class="form-check-input"
                                     type="radio"
                                     name="question4" value="Плохо"/>
                              <label class="form-check-label">Плохо /<span
                                class="kyrg">&nbsp;Канааттандырбайт</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question4" class="form-check-input"
                                     type="radio"
                                     name="question4" value="Среднее"/>
                              <label class="form-check-label">Среднее /<span class="kyrg">&nbsp;Орточо</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question4" class="form-check-input"
                                     type="radio"
                                     name="question4" value="Хорошо"/>
                              <label class="form-check-label">Хорошо /<span class="kyrg">&nbsp;Жакшы</span></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-12 mt-4">
                        <p class="font-base font-bold">3. Оценка тренера /<span
                          class="kyrg">&nbsp;Тренерди баалоо</span></p>
                      </div>
                      <!-- Доступность изложения материала-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Доступность изложения материала
                            /<span class="kyrg">&nbsp;Берилген окуу материалынын катышуучуларга  жеткиликтүүлүгү</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question5" class="form-check-input"
                                     type="radio"
                                     name="question5" value="Плохо"/>
                              <label class="form-check-label">Плохо /<span
                                class="kyrg">&nbsp;Канааттандырбайт</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question5" class="form-check-input"
                                     type="radio"
                                     name="question5" value="Среднее"/>
                              <label class="form-check-label">Среднее /<span class="kyrg">&nbsp;Орточо</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question5" class="form-check-input"
                                     type="radio"
                                     name="question5" value="Хорошо"/>
                              <label class="form-check-label">Хорошо /<span class="kyrg">&nbsp;Жакшы</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Работа тренера с группой-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Работа тренера с группой
                            /<span class="kyrg">&nbsp;Тренердин группа менен иш алып баруусу</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question6" class="form-check-input"
                                     type="radio"
                                     name="question6" value="Плохо"/>
                              <label class="form-check-label">Плохо /<span
                                class="kyrg">&nbsp;Канааттандырбайт</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question6" class="form-check-input"
                                     type="radio"
                                     name="question6" value="Среднее"/>
                              <label class="form-check-label">Среднее /<span class="kyrg">&nbsp;Орточо</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question6" class="form-check-input"
                                     type="radio"
                                     name="question6" value="Хорошо"/>
                              <label class="form-check-label">Хорошо /<span class="kyrg">&nbsp;Жакшы</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Уровень владения информацией-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Уровень владения информацией /<span class="kyrg">&nbsp;Тренердин билим деңгээли</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question7" class="form-check-input"
                                     type="radio"
                                     name="question7" value="Плохо"/>
                              <label class="form-check-label">Плохо /<span
                                class="kyrg">&nbsp;Канааттандырбайт</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question7" class="form-check-input"
                                     type="radio"
                                     name="question7" value="Среднее"/>
                              <label class="form-check-label">Среднее /<span class="kyrg">&nbsp;Орточо</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question7" class="form-check-input"
                                     type="radio"
                                     name="question7" value="Хорошо"/>
                              <label class="form-check-label">Хорошо /<span class="kyrg">&nbsp;Жакшы</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Тренер хорошо подготовлен к тренингу-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Тренер хорошо подготовлен к тренингу /<span class="kyrg">&nbsp;Тренердин окууга болгон даярдык деңгээли</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question8" class="form-check-input"
                                     type="radio"
                                     name="question8" value="Плохо"/>
                              <label class="form-check-label">Плохо /<span
                                class="kyrg">&nbsp;Канааттандырбайт</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question8" class="form-check-input"
                                     type="radio"
                                     name="question8" value="Среднее"/>
                              <label class="form-check-label">Среднее /<span class="kyrg">&nbsp;Орточо</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question8" class="form-check-input"
                                     type="radio"
                                     name="question8" value="Хорошо"/>
                              <label class="form-check-label">Хорошо /<span class="kyrg">&nbsp;Жакшы</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Тренинг проходит динамично, живо, с энтузиазмом-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>Тренинг проходит динамично, живо, с энтузиазмом /<span class="kyrg">&nbsp;Тренинг динамикалуу, жандуу, энтузиазмдуу өтүлүүдө</span>
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question9" class="form-check-input"
                                     type="radio"
                                     name="question9" value="Плохо"/>
                              <label class="form-check-label">Плохо /<span
                                class="kyrg">&nbsp;Канааттандырбайт</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question9" class="form-check-input"
                                     type="radio"
                                     name="question9" value="Среднее"/>
                              <label class="form-check-label">Среднее /<span class="kyrg">&nbsp;Орточо</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question9" class="form-check-input"
                                     type="radio"
                                     name="question9" value="Хорошо"/>
                              <label class="form-check-label">Хорошо /<span class="kyrg">&nbsp;Жакшы</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- В каких тренингах Вы хотели бы участвовать дополнительно?-->
                      <div class="col-span-12">
                        <div class="mt-3">
                          <label>В каких тренингах Вы хотели бы участвовать дополнительно? /<span class="kyrg">&nbsp;Кайсы кошумча тренингдерге катышкыңыз келет?</span></label>
                          <div class="flex flex-col sm:flex-row mt-2">
                            <div class="form-check mr-2">
                              <input v-model="feedBackAnketa.question10" id="checkbox-switch-4" class="form-check-input"
                                     type="checkbox" value="по агрономии"/>
                              <label class="form-check-label" for="checkbox-switch-4">по агрономии /<span class="kyrg">&nbsp;агрономия боюнча</span></label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input v-model="feedBackAnketa.question10" id="checkbox-switch-5" class="form-check-input"
                                     type="checkbox" value="по животноводству"/>
                              <label class="form-check-label" for="checkbox-switch-5">по животноводству /<span
                                class="kyrg">&nbsp;мал чарбачылыгы боюнча</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary mt-5">
                Сохранить /
                <span>&nbsp;Сактоо</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {defineComponent} from 'vue'
import axios from 'axios'
import store from '@/store'
import Select2 from "vue3-select2-component";
import router from "@/router";
import moment from "moment";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},

  data() {
    return {
      gruppaId: this.$route.params.id,
      feedBackAnketa: {
        id: '',
        uchastnik: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: []
      },
      currentDate: new Date(),
      gruppa: {
        prepods: '',
        placeProvedeniya: ''
      },
      uchastniki: [],
      isSubmit: false
    }
  },
  mounted() {
    this.refreshData()
    this.loadGruppaInfo()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.feedBackAnketa, this.$options.data().feedBackAnketa)
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'uchastnik/listForCreateAnketa/' + 4 + '/' + this.gruppaId).then(response => {
            if (response.data != null) {
              this.uchastniki = []
              response.data.forEach(item => {
                this.uchastniki.push({id: item.id, text: item.lastName + ' ' + item.firstName})
              })
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    create() {
      this.isSubmit = true
      axios({
        method: 'post',
        url: API_URL + 'feedbackAnketa/add',
        data: {
          uchastnik: this.feedBackAnketa.uchastnik != null ? {id: this.feedBackAnketa.uchastnik} : null,
          gruppa: {id: this.gruppaId},
          question1: this.feedBackAnketa.question1,
          question2: this.feedBackAnketa.question2,
          question3: this.feedBackAnketa.question3,
          question4: this.feedBackAnketa.question4,
          question5: this.feedBackAnketa.question5,
          question6: this.feedBackAnketa.question6,
          question7: this.feedBackAnketa.question7,
          question8: this.feedBackAnketa.question8,
          question9: this.feedBackAnketa.question9,
          question10: this.feedBackAnketa.question10
        }
      }).then(response => {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          focusConfirm: true,
          icon: 'success',
          title: 'Ваша запись успешно сохранена!',
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        router.push('/login')
      }).catch(error => {
        this.isSubmit = false
        this.$swal.fire({
          toast: true,
          focusConfirm: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Внутренняя ошибка сервера',
          text: 'Попробуйте позже',
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
      })
    },
    loadGruppaInfo() {
      try {
        (async () => {
          await axios.get(API_URL + 'gruppa/getById', {
            params: {
              id: this.gruppaId
            }
          }).then(response => {
            if (response.data != null) {
              let placeProvedeniya = ''
              let prepods = ''
              if (response.data.oblast != null) {
                placeProvedeniya = placeProvedeniya + response.data.oblast.name + ', '
              }
              if (response.data.rayon != null) {
                placeProvedeniya = placeProvedeniya + response.data.rayon.name + ', '
              }
              if (response.data.aymak != null) {
                placeProvedeniya = placeProvedeniya + response.data.aymak.name + ', '
              }
              if (response.data.nasPunkt != null) {
                placeProvedeniya = placeProvedeniya + response.data.nasPunkt.shortName
              }
              this.gruppa.placeProvedeniya = placeProvedeniya
              if (response.data.prepods != null) {
                response.data.prepods.forEach(item => {
                  prepods = prepods + item.lastName + ' ' + item.firstName + ', '
                })
              }
              this.gruppa.prepods = prepods
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
  }
})
</script>

<style>
.kyrg {
  color: #2b51b4;
}
</style>
