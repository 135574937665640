const state = () => {
  return {
    menu: [
      {
        icon: 'UserMinusIcon',
        pageName: 'side-menu-dashboard',
        title: 'Перинатальная смерть',
        subMenu: [
          {
            icon: '',
            pageName: 'perinatalDeathList',
            title: 'Список перинатальных смертей'
          },
          {
            icon: '',
            pageName: 'perinatalDeathForm',
            title: 'Регистрация перинатальной смерти'
          }
        ]
      },
      {
        icon: 'UserPlusIcon',
        pageName: 'side-menu-dashboard',
        title: 'Свидетельство о рождении',
        subMenu: [
          {
            icon: '',
            pageName: 'birthSvidelstvoList',
            title: 'Медицинское свидетельство о рождении'
          },
          {
            icon: '',
            pageName: 'birthSvidelstvoForm',
            title: 'Регистрация рождений'
          }]
      },
      {
        icon: 'UserXIcon',
        pageName: 'side-menu-dashboard',
        title: 'Свидетельство о смерти',
        subMenu: [
          {
            icon: '',
            pageName: 'simpleDeathList',
            title: 'Список свидетельств о смерти'
          },
          {
            icon: '',
            pageName: 'simpleDeathForm',
            title: 'Регистрация смерти'
          }
        ]
      },
      'devider',
      {
        icon: 'GlobeIcon',
        pageName: 'side-menu-dashboard',
        title: 'COATE',
        subMenu: [
          {
            icon: '',
            pageName: 'countries',
            title: 'Страны'
          },
          {
            icon: '',
            pageName: 'oblasts',
            title: 'Области'
          },
          {
            icon: '',
            pageName: 'rayons',
            title: 'Районы'
          },
          {
            icon: '',
            pageName: 'aymaks',
            title: 'Айылные аймаки'
          },
          {
            icon: '',
            pageName: 'naspunkts',
            title: 'Населенные пукнты'
          },
          {
            icon: '',
            pageName: 'hospitals',
            title: 'ОЗ'
          }
        ]
      },
      {
        icon: 'DatabaseIcon',
        pageName: 'side-menu-dashboard',
        title: 'Справочники',
        subMenu: [
          {
            icon: '',
            pageName: 'educations',
            title: 'Образования'
          },
          {
            icon: '',
            pageName: 'meddocumentsosnovanies',
            title: 'Мед. документы'
          },
          {
            icon: '',
            pageName: 'naosnovaniis',
            title: 'На основании'
          },
          {
            icon: '',
            pageName: 'nationalities',
            title: 'Национальности'
          },
          {
            icon: '',
            pageName: 'prichinasmertiusatovlenavzroslyi',
            title: 'Кем установлена смерть (взрослые)'
          },
          {
            icon: '',
            pageName: 'prichinasmertiusatovlenarebenok',
            title: 'Кем установлена смерть (дети)'
          },
          {
            icon: '',
            pageName: 'rodyprinyali',
            title: 'Роды приняли'
          },
          {
            icon: '',
            pageName: 'smertnastupilas',
            title: 'Смерть наступила'
          },
          {
            icon: '',
            pageName: 'typesvidetelstvos',
            title: 'Виды свидетельства'
          },
          {
            icon: '',
            pageName: 'smertRebenkaNastupilas',
            title: 'Смерть ребенка наступила'
          },
          {
            icon: '',
            pageName: 'smertRebenkaProizoshla',
            title: 'Смерть ребенка произошла'
          },
          {
            icon: '',
            pageName: 'typetravmas',
            title: 'Типы травм'
          },
          {
            icon: '',
            pageName: 'mkbdiagnoses',
            title: 'МКБ-диагнозы'
          },
          {
            icon: '',
            pageName: 'anotherdocuments',
            title: 'Документы для основания'
          },
          {
            icon: '',
            pageName: 'womandeathpregnancy',
            title: 'Смерть женщины во время беременности'
          },
          {
            icon: '',
            pageName: 'doljnosts',
            title: 'Должности'
          }
        ]
      },
      {
        icon: 'LockIcon',
        pageName: 'side-menu-dashboard',
        title: 'Пользователи',
        subMenu: [
          {
            icon: '',
            pageName: 'userroles',
            title: 'Роли'
          },
          {
            icon: '',
            pageName: 'users',
            title: 'Пользователи'
          }
        ]
      },
      {
        icon: 'UserIcon',
        pageName: 'side-menu-dashboard',
        title: 'Работники ОЗ',
        subMenu: [
          {
            icon: '',
            pageName: 'medicofhospitals',
            title: 'Список работников'
          }
        ]
      },
      'devider',
      {
        icon: 'BoxIcon',
        pageName: 'side-menu-menu-layout',
        title: 'Menu Layout',
        subMenu: [
          {
            icon: '',
            pageName: 'side-menu-dashboard-overview-1',
            title: 'Side Menu',
            ignore: true
          },
          {
            icon: '',
            pageName: 'simple-menu-dashboard-overview-1',
            title: 'Simple Menu',
            ignore: true
          },
          {
            icon: '',
            pageName: 'top-menu-dashboard-overview-1',
            title: 'Top Menu',
            ignore: true
          }
        ]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
