<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="text-lg font-medium mt-10">Пре пост тест по Агрономии</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- СВЕДЕНИЯ ОБ УЧАСТНИКЕ -->
              <div class="intro-y box">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Сведения об участнике</h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- ФИО -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          ФИО
                        </label>
                        <input type="text"
                               :value="uchastnik.lastName + ' ' + uchastnik.firstName + ' ' + uchastnik.patronymic"
                               class="form-control italic" disabled/>
                      </div>
                      <!-- Дата заполнения -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Дата заполнения
                        </label>
                        <input type="text"
                               :value="this.format_date(currentDate)"
                               class="form-control italic" disabled/>
                      </div>
                      <!-- Место проведения -->
                      <div class="input-form col-span-12">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Место проведения
                        </label>
                        <input type="text"
                               :value="placeProvedeniya"
                               class="form-control italic" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- КОНТРОЛЬНЫЕ ВОПРОСЫ -->
              <div class="intro-y box mt-5">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Контрольные вопросы</h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- Что влияет на плодородие почвы? -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Что влияет на плодородие почвы?
                        </label>
                        <select disabled v-model="anketa3.question1" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="наличие в почве перегноя">наличие в почве перегноя</option>
                          <option value="наличие в почве воды">наличие в почве воды</option>
                          <option value="наличие в почве песка и глины">наличие в почве песка и глины</option>
                        </select>
                      </div>
                      <!-- К сочным кормам относятся: -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          К сочным кормам относятся:
                        </label>
                        <select disabled v-model="anketa3.question2" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="зеленые, корне- и клубнеплоды, бахчевые, силос">зеленые, корне- и клубнеплоды, бахчевые, силос</option>
                          <option value="барда, жом, кормовая патока, пивная дробина">барда, жом, кормовая патока, пивная дробина</option>
                          <option value="зерно, отруби, шрот, жмых, травяная мука">зерно, отруби, шрот, жмых, травяная мука</option>
                        </select>
                      </div>
                      <!-- К грубым кормам относятся: -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          К грубым кормам относятся:
                        </label>
                        <select disabled v-model="anketa3.question3" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="зеленые, корне- и клубнеплоды, бахчевые, силос">зеленые, корне- и клубнеплоды, бахчевые, силос</option>
                          <option value="зерно, отруби, шрот, жмых, травяная мука">зерно, отруби, шрот, жмых, травяная мука</option>
                          <option value="сено, солома, мякина">сено, солома, мякина</option>
                        </select>
                      </div>

                      <!-- Основные вредители люцерны в фазу бутонизации: -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Основные вредители люцерны в фазу бутонизации:
                        </label>
                        <select disabled v-model="anketa3.question4" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="долгоносики">долгоносики</option>
                          <option value="семяеды">семяеды</option>
                          <option value="тля, клопы, почкоеды">тля, клопы, почкоеды</option>
                        </select>
                      </div>
                      <!-- Уборка люцерны и эспарцета на сено:  -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          <span style="color: #1c3faa">Люцерна менен эспарцети кесек тоютка кайсы фазада оруп баштоо керек?</span>
                          / Фаза уборки люцерны и эспарцета на сено
                        </label>
                        <select disabled v-model="anketa3.question5" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="в фазу стеблевания или начала бутонизации">в фазу стеблевания или начала бутонизации</option>
                          <option value="в фазу цветения">в фазу цветения</option>
                          <option value="в фазу полной спелости семян">в фазу полной спелости семян</option>
                        </select>
                      </div>

                      <div class="col-span-12 mt-3">
                        <p class="text-base font-bold" style="justify-content: center; align-items: center; display: flex">«Агротехника выращивания кормовых злаков и корнеплодов»</p>
                      </div>
                      <!-- Норма высева ячменя кг/га   -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Норма высева ячменя кг/га
                        </label>
                        <select disabled v-model="anketa3.question6" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="100-120 кг/га">100-120 кг/га</option>
                          <option value="230-250 кг/га">230-250 кг/га</option>
                          <option value="165-220 кг/га">165-220 кг/га </option>
                        </select>
                      </div>
                      <!-- Какая культура более засухоустойчивая    -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Какая культура более засухоустойчивая
                        </label>
                        <select disabled v-model="anketa3.question7" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="ячмень">ячмень</option>
                          <option value="пшеница">пшеница</option>
                          <option value="овес">овес</option>
                        </select>
                      </div>
                      <!-- Укажите лучший срок посева кормовой свёклы    -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Укажите лучший срок посева кормовой свёклы
                        </label>
                        <select disabled v-model="anketa3.question8" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="температура почвы 6 - 7С°">температура почвы 6 - 7С°</option>
                          <option value="температура почвы 8 - 10С°">температура почвы 8 - 10С°</option>
                          <option value="температура почвы 10 - 12С°">температура почвы 10 - 12С°</option>
                        </select>
                      </div>
                      <!-- Укажите оптимальную глубину заделки семян свёклы    -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Укажите оптимальную глубину заделки семян свёклы
                        </label>
                        <select disabled v-model="anketa3.question9" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="7 - 8 см">7 - 8 см</option>
                          <option value="2,5 - 3 см">2,5 - 3 см</option>
                          <option value="5 - 6 см">5 - 6 см</option>
                        </select>
                      </div>
                      <!-- Какая болезнь поражает зерновые культуры    -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Какая болезнь поражает зерновые культуры
                        </label>
                        <select disabled v-model="anketa3.question10" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="фитофтороз">фитофтороз</option>
                          <option value="головня">головня</option>
                          <option value="черная ножка">черная ножка</option>
                        </select>
                      </div>
                      <!-- Гидропоника это?     -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Гидропоника это?
                        </label>
                        <select disabled v-model="anketa3.question11" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="хранение в подвале">хранение в подвале</option>
                          <option value="выращивание растений без грунта, на питательных растворах">выращивание растений без грунта, на питательных растворах</option>
                          <option value="увлажнённый корм">увлажнённый корм</option>
                        </select>
                      </div>
                      <div class="col-span-12 mt-3">
                        <p class="text-base font-bold" style="justify-content: center; align-items: center; display: flex">«Приготовление и хранение силоса.
                          Хранение и подготовка кормов к скармливанию»
                        </p>
                      </div>
                      <!-- Какие растения хорошо силосуются?     -->
                      <div class="input-form col-span-6">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Какие растения хорошо силосуются?
                        </label>
                        <select disabled v-model="anketa3.question12" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="люцерна эспарцет">люцерна эспарцет</option>
                          <option value="картофельная ботва">картофельная ботва</option>
                          <option value="кукуруза, злаковые травы, подсолнечник">кукуруза, злаковые травы, подсолнечник</option>
                        </select>
                      </div>
                      <!-- Какие методы обработки зернохранилищ против амбарных вредителей вы знаете?     -->
                      <div class="input-form col-span-6">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Какие методы обработки зернохранилищ против амбарных вредителей вы знаете?
                        </label>
                        <select disabled v-model="anketa3.question13" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="профилактический, механический, известью, фумигацией">профилактический, механический, известью, фумигацией</option>
                          <option value="биологический метод">биологический метод</option>
                          <option value="установить ультрафиолетовые лампы">установить ультрафиолетовые лампы</option>
                        </select>
                      </div>
                      <!-- В какой фазе развития кукурузы заготавливается качественный силос?     -->
                      <div class="input-form col-span-6">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          В какой фазе развития кукурузы заготавливается качественный силос?
                        </label>
                        <select disabled v-model="anketa3.question14" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="фаза образования початков">фаза образования початков</option>
                          <option value="в фазе молочно-восковой спелости початков">в фазе молочно-восковой спелости початков</option>
                          <option value="в фазе полного созревания зерна">в фазе полного созревания зерна</option>
                        </select>
                      </div>
                      <!-- С какой целью проводится подготовка кормов к скармливанию?     -->
                      <div class="input-form col-span-6">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          С какой целью проводится подготовка кормов к скармливанию?
                        </label>
                        <select disabled v-model="anketa3.question15" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="повышение влажности">повышение влажности</option>
                          <option value="увеличение объемов кормов">увеличение объемов кормов</option>
                          <option value="повышение перевариваемости">повышение перевариваемости</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {defineComponent} from 'vue'
import axios from 'axios'
import store from '@/store'
import Select2 from "vue3-select2-component";
import router from "@/router";
import moment from "moment";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},

  data() {
    return {
      anketaId: this.$route.params.id,
      anketa3: {
        id: '',
        uchastnik: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: null,
        question11: null,
        question12: null,
        question13: null,
        question14: null,
        question15: null
      },
      uchastnik: {
        lastName: '',
        firstName: '',
        patronymic: ''
      },
      currentDate: null,
      placeProvedeniya: ''
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.anketa3, this.$options.data().anketa3)
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'anketa3/' + this.anketaId, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              let anketaFromDb = response.data
              if (anketaFromDb.uchastnik != null) {
                this.uchastnik.lastName = anketaFromDb.uchastnik.lastName != null ? anketaFromDb.uchastnik.lastName : ''
                this.uchastnik.firstName = anketaFromDb.uchastnik.firstName != null ? anketaFromDb.uchastnik.firstName : ''
                this.uchastnik.patronymic = anketaFromDb.uchastnik.patronymic != null ? anketaFromDb.uchastnik.patronymic : ''
              }
              this.currentDate = anketaFromDb.creationDate
              this.anketa3.question1 = anketaFromDb.question1
              this.anketa3.question2 = anketaFromDb.question2
              this.anketa3.question3 = anketaFromDb.question3
              this.anketa3.question4 = anketaFromDb.question4
              this.anketa3.question5 = anketaFromDb.question5
              this.anketa3.question6 = anketaFromDb.question6
              this.anketa3.question7 = anketaFromDb.question7
              this.anketa3.question8 = anketaFromDb.question8
              this.anketa3.question9 = anketaFromDb.question9
              this.anketa3.question10 = anketaFromDb.question10
              this.anketa3.question11 = anketaFromDb.question11
              this.anketa3.question12 = anketaFromDb.question12
              this.anketa3.question13 = anketaFromDb.question13
              this.anketa3.question14 = anketaFromDb.question14
              this.anketa3.question15 = anketaFromDb.question15
              let placeProvedeniya = ''
              if (response.data.gruppa.oblast != null) {
                placeProvedeniya = placeProvedeniya + response.data.gruppa.oblast.name + ', '
              }
              if (response.data.gruppa.rayon != null) {
                placeProvedeniya = placeProvedeniya + response.data.gruppa.rayon.name + ', '
              }
              if (response.data.gruppa.aymak != null) {
                placeProvedeniya = placeProvedeniya + response.data.gruppa.aymak.name + ', '
              }
              if (response.data.gruppa.nasPunkt != null) {
                placeProvedeniya = placeProvedeniya + response.data.gruppa.nasPunkt.shortName
              }
              this.placeProvedeniya = placeProvedeniya
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    }
  }
})
</script>
