<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="intro-y text-lg font-medium mt-10">Населенные пункты</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <a      href="javascript:;"
                  data-toggle="modal"
                  data-target="#create-modal"
                  class="btn btn-primary shadow-md mr-2"
                  @click="showModalCreate">Добавить</a>
        </div>
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2" id='table_id'>
            <thead>
            <tr>
              <th>Название</th>
              <th>ATE код</th>
              <th>Область</th>
              <th>Район</th>
              <th>Айылный аймак</th>
              <th class="text-center whitespace-nowrap">Действия</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in naspunkts"
                :key="item.id">
              <td>
                <div class="text-s">
                  {{ item.shortName }}
                </div>
              </td>
              <td>
                <div class="text-s">
                  {{ item.ateCode }}
                </div>
              </td>
              <td>
                <div class="text-s" v-for="(oblast, name) in item.oblast" v-bind:key="oblast">
                  <div v-if="name === 'name'">
                    {{ item.oblast != null ? oblast : '(нет привязки)' }}
                  </div>
                </div>
              </td>
              <td>
                <div class="text-s" v-for="(rayon, name) in item.rayon" v-bind:key="rayon">
                  <div v-if="name === 'name'">
                    {{ rayon }}
                  </div>
                </div>
              </td>
              <td>
                <div class="text-s" v-for="(aymak, name) in item.aymak" v-bind:key="aymak">
                  <div v-if="name === 'shortName'">
                    {{ aymak }}
                  </div>
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;"
                     data-toggle="modal"
                     data-target="#create-modal"
                     @click="showModalEdit(item)">
                    <CheckSquareIcon class="w-4 h-4 mr-1"/>
                    Редактировать
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- BEGIN: Create Modal -->
      <div
        id="create-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop='static'
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <div class="text-3xl mt-5">{{ nameForModal }}</div>
              </div>
              <form v-on:submit.prevent="isEditingModal ? edit() : create()">
                <div class="p-5">
                  <label class="form-label left-0">Название</label>
                  <input v-model="naspunkt.name"
                         type="text"
                         required
                         class="intro-x login__input form-control py-3 px-4 border-gray-300 block mb-4"
                         placeholder="Название"
                  />
                  <label class="form-label left-0">ATE код</label>
                  <input v-model="naspunkt.ateCode"
                         type="number"
                         class="intro-x login__input form-control py-3 px-4 border-gray-300 block mb-4"
                         placeholder="Код"
                  />

                  <label class="form-label left-0">Область</label>
                  <Select2 required v-model="naspunkt.oblast" @select="onChangeOblast($event)" :options="oblasts" />

                  <label class="form-label left-0 mt-3">Район</label>
                  <Select2 required v-model="naspunkt.rayon" @select="onChangeRayon($event)" :options="rayons" />

                  <label class="form-label left-0 mt-3">Айылный аймак</label>
                  <Select2 v-model="naspunkt.aymak" :options="aymaks" />

                  <button type="submit" class="btn btn-primary py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top">
                    {{ nameOfButtonForModal }}
                  </button>
                  <button type="button" id="close" data-dismiss='modal' @click="resetData"
                          class='btn btn-light py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top'>
                    Отмена
                  </button>
                </div>
              </form>
              <div
                class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue'
import axios from 'axios'
import store from '@/store'

import $ from 'jquery'
import Select2 from 'vue3-select2-component'

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},
  setup() {
    const select = ref('#')
    return { select }
  },
  data() {
    return {
      nameForModal: String,
      nameOfButtonForModal: String,
      isEditingModal: false,
      totalItems: Number,
      naspunkts: [],
      naspunkt: {
        id: '',
        name: '',
        shortName: '',
        ateCode: null,
        oblast: null,
        rayon: null,
        aymak: null
      },
      oblasts: [],
      rayons: [],
      aymaks: []
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.naspunkt, this.$options.data().naspunkt)
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'coate/naspunkt/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.naspunkts = response.data
              $("#table_id").dataTable().fnDestroy();
              $(document).ready( function () {
                $('#table_id').DataTable({
                    "language": {
                      "processing": "Подождите...",
                      "search": "Поиск:",
                      "lengthMenu": "Показать _MENU_ записей",
                      "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
                      "infoEmpty": "Записи с 0 до 0 из 0 записей",
                      "infoFiltered": "(отфильтровано из _MAX_ записей)",
                      "infoPostFix": "",
                      "loadingRecords": "Загрузка записей...",
                      "zeroRecords": "Записи отсутствуют.",
                      "emptyTable": "В таблице отсутствуют данные",
                      "paginate":
                        {
                          "first": "Первая",
                          "previous": "Предыдущая",
                          "next": "Следующая",
                          "last": "Последняя"
                        },
                      "aria": {
                        "sortAscending": ": активировать для сортировки столбца по возрастанию",
                        "sortDescending": ": активировать для сортировки столбца по убыванию"
                      },
                      "select": {
                        "rows": {
                          "_": "Выбрано записей: %d",
                          "0": "Кликните по записи для выбора",
                          "1": "Выбрана одна запись"
                        }
                      },
                      "buttons":
                        {
                          "print": "Печать"
                        }
                    },
                    columnDefs : [
                      { 'visible': false, 'targets': [ ] }
                    ]
                    // "buttons": ["csv", "excel", "print"]
                  }
                );
              })
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

          await axios.get(API_URL + 'coate/oblast/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.oblasts = []
              response.data.forEach(item => {
                this.oblasts.push({id: item.id, text: item.name})
              })
            }
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    showModalDelete(naspunkt) {
      this.naspunktForDelete = naspunkt
    },
    confirmDelete() {
      axios
        .delete(API_URL + 'naspunkts/naspunkt/' + this.naspunktForDelete.id, {
          headers: {
            Authorization: 'Bearer ' + store.state.auth.user.jwtToken
          }
        }).then(response => {
          if (response.data != null) {
            this.deleteResponse(response)
            this.refreshData()
          }
        })
    },
    showModalCreate() {
      this.nameForModal = 'Добавление населенного пункта'
      this.nameOfButtonForModal = 'Добавить'
      this.isEditingModal = false
    },
    create() {
      axios({
        method: 'post',
        url: API_URL + 'coate/naspunkt/add',
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          name: this.naspunkt.name,
          ateCode: this.naspunkt.ateCode,
          shortName: this.naspunkt.name,
          oblast: this.naspunkt.oblast != null ? {id: this.naspunkt.oblast} : null,
          rayon: this.naspunkt.rayon != null ? {id: this.naspunkt.rayon} : null,
          aymak: this.naspunkt.aymak != null ? {id: this.naspunkt.aymak} : null
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    showModalEdit(naspunkt) {
      this.naspunkt.id = naspunkt.id
      this.naspunkt.name = naspunkt.name
      this.naspunkt.ateCode = naspunkt.ateCode
      this.naspunkt.shortName = naspunkt.name
      this.naspunkt.oblast = naspunkt.oblast != null ? naspunkt.oblast.id : null
      this.naspunkt.rayon = naspunkt.rayon != null ? naspunkt.rayon.id : null
      this.naspunkt.aymak = naspunkt.aymak != null ? naspunkt.aymak.id : null
      this.isEditingModal = true
      this.nameForModal = 'Редактирование населенного пункта'
      this.nameOfButtonForModal = 'Изменить'
    },
    edit() {
      axios({
        method: 'put',
        url: API_URL + 'coate/naspunkt/edit/' + this.naspunkt.id,
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          id: this.naspunkt.id,
          name: this.naspunkt.name,
          ateCode: this.naspunkt.ateCode,
          shortName: this.naspunkt.name,
          oblast: this.naspunkt.oblast != null ? {id: this.naspunkt.oblast} : null,
          rayon: this.naspunkt.rayon != null ? {id: this.naspunkt.rayon} : null,
          aymak: this.naspunkt.aymak != null ? {id: this.naspunkt.aymak} : null
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    onChangeOblast(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'coate/rayons/byOblastId/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.rayons = []
            response.data.forEach(item => {
              this.rayons.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    onChangeRayon(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'coate/aymaks/byRayonId/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.aymaks = []
            response.data.forEach(item => {
              this.aymaks.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    }
  }
})
</script>
