<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="intro-y text-lg font-medium mt-10">Настройка доступа к системе</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="col-span-6">
          <Select2 v-model="role"
                   @select="checkRole($event)"
                   :options="userRoles"
                   :settings="{containerCssClass: 'm-1.5'}"/>
        </div>
        <div class="col-span-6">
          <button v-show="role!=null" data-dismiss="modal" @click="edit"
                  class="btn btn-primary align-top">
            Обновить доступы
          </button>
        </div>
      <div class="col-span-12 lg:col-span-12">
        <div class="intro-y box">
          <div class="overflow-x-auto sm:overflow-x-visible">
            <div v-for="permissionCategory in permissions" v-bind:key="permissionCategory" class="cursor-pointer transition duration-300 ease-in-out bg-white dark:bg-dark-3 hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md">
              <div
                class="inbox__item inline-block sm:block text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1"
              >
              </div>
              <div class="px-5 py-3" style="background-color: rgb(204, 229, 255);">
                <div class="grid grid-cols-12 gap-1">
                  <div class="col-span-3">
                    <span class="inbox__item--highlight font-bold">{{ permissionCategory.nameRu }}</span>
                  </div>
<!--                  <div v-for="permission in permissionCategory.permissions" class="col-auto" v-bind:key="permission">-->
<!--                    <div class="grid grid-cols-12 gap-1">-->
<!--                      <input-->
<!--                        v-model="checkedPermissions"-->
<!--                        :disabled="role===null"-->
<!--                        :value="permission.id"-->
<!--                        class="form-check-input col-span-12"-->
<!--                        type="checkbox"-->
<!--                      />-->
<!--                      <label class="col-span-12">{{ permission.nameRu }}</label>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="grid grid-flow-col auto-cols-max gap-1 text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1">
                <div v-for="permission in permissionCategory.permissions" v-bind:key="permission">
                    <div class="px-5 py-3">
                      <div class="grid grid-cols-2 justify-items-center">
                        <div class="col-span-12">
                        <input
                          v-model="checkedPermissions"
                          :disabled="role===null"
                          :value="permission.id"
                          class="form-check-input "
                          type="checkbox"
                        />
                        </div>
                        <div class="col-span-12">
                        <label>{{ permission.nameRu }}</label>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import axios from 'axios'
import store from '@/store'
import Select2 from "vue3-select2-component";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {
    Select2
  },
  data() {
    return {
      role: null,
      userRoles: [],
      permissions: [],
      rolePermissions: [],
      actionId: '',
      checkedPermissions: []
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    refreshData() {
      try {
        (async () => {
          //Тянем все роли и их доступы
          await axios.get(API_URL + 'userroles/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.userRoles =[]
            response.data.forEach(item =>{
              this.userRoles.push({id: item.id, text: item.nameRu + ' (' + item.name + ')'})
            })
          })
          await axios.get(API_URL + 'permissions/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.permissions = response.data
          })
          if (this.role != null){
            await axios.get(API_URL + 'userroles/userrole/' + this.role, {
              headers: {
                Authorization: 'Bearer ' + store.state.auth.user.jwtToken
              }
            }).then(response => {
              this.rolePermissions = response.data.permissions
              this.rolePermissions.forEach(item => {
                this.checkedPermissions.push(item.id)
              })
            })
          }

        })()
      } catch (e) {
        console.log(e)
      }
    },
    checkRole() {
      this.checkedPermissions = []
      if (this.role != null){
        this.refreshData()
      }
    },
    edit() {
      if (this.role != null) {
        try {
          (async () => {
            axios({
              method: 'put',
              url: API_URL + 'userroles/updatePermissions/' + this.role,
              headers: {
                Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
                'Content-Type': 'application/json'
              },
              data:  this.checkedPermissions
            }).then(response => {
                this.refreshData()
            })
          })()
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
})
</script>
