<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="text-lg font-medium mt-10">Подробнее об оценке тренинга со стороны участников</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="intro-y col-span-12 lg:col-span-12">
            <!-- СВЕДЕНИЯ ОБ УЧАСТНИКЕ -->
            <div class="intro-y box">
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">Сведения об участнике</h2>
              </div>
              <div class="p-5">
                <div class="preview">
                  <div class='lg:grid grid-cols-12 gap-2'>
                    <!-- Общая информация-->
                    <div class="col-span-8">
                      <div class="truncate sm:whitespace-normal flex items-center">
                        <UserCheckIcon class="w-4 h-4 mr-2"/>
                        ФИО тренера(-ов): &nbsp;<span class="italic">{{ this.gruppa.prepods }}</span>
                      </div>
                    </div>
                    <div class="col-span-4">
                      <CalendarIcon class="w-4 h-4 mr-2"/>
                      Дата оценки: <span class="italic">{{ this.format_date(this.feedBackAnketa.creationDate) }}</span>
                    </div>
                    <div class="col-span-12 mb-4 mt-2">
                      <NavigationIcon class="w-4 h-4 mr-2"/>
                      Место проведения: <span class="italic">{{ this.gruppa.placeProvedeniya }}</span>
                    </div>
                    <div class="col-span-12 mb-4">
                      <UserCheckIcon class="w-4 h-4 mr-2"/>
                      ФИО участника: <span class="italic">{{ this.feedBackAnketa.fio }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- КОНТРОЛЬНЫЕ ВОПРОСЫ -->
            <div class="intro-y box mt-5">
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">Контрольные вопросы</h2>
              </div>
              <div class="p-5">
                <div class="preview">
                  <div class='lg:grid grid-cols-12 gap-2'>
                    <div class="col-span-12">
                      <p class="font-base font-bold">1. Участие на тренингах</p>
                    </div>
                    <!-- Вы раньше участвовали на тренингах, консультациях по аналогичной теме?-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Вы раньше участвовали на тренингах, консультациях по аналогичной теме?
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question1" class="form-check-input"
                                   type="radio"
                                   name="question1" value="Да"/>
                            <label class="form-check-label">Да</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question1" class="form-check-input"
                                   type="radio"
                                   name="question1" value="Нет"/>
                            <label class="form-check-label">Нет</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-span-12 mt-4">
                      <p class="font-base font-bold">2. Оценка предоставляемого материала</p>
                    </div>
                    <!-- Предложенный материал обладает практической ценностью-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Предложенный материал обладает практической ценностью
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question2" class="form-check-input"
                                   type="radio"
                                   name="question2" value="Плохо"/>
                            <label class="form-check-label">Плохо</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question2" class="form-check-input"
                                   type="radio"
                                   name="question2" value="Среднее"/>
                            <label class="form-check-label">Среднее</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question2" class="form-check-input"
                                   type="radio"
                                   name="question2" value="Хорошо"/>
                            <label class="form-check-label">Хорошо</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Предложенный материал обладает достаточной новизной-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Предложенный материал обладает достаточной новизной
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question3" class="form-check-input"
                                   type="radio"
                                   name="question3" value="Плохо"/>
                            <label class="form-check-label">Плохо</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question3" class="form-check-input"
                                   type="radio"
                                   name="question3" value="Среднее"/>
                            <label class="form-check-label">Среднее</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question3" class="form-check-input"
                                   type="radio"
                                   name="question3" value="Хорошо"/>
                            <label class="form-check-label">Хорошо</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Содержание тренинга соответствует поставленным задачам-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Содержание тренинга соответствует поставленным задачам
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question4" class="form-check-input"
                                   type="radio"
                                   name="question4" value="Плохо"/>
                            <label class="form-check-label">Плохо</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question4" class="form-check-input"
                                   type="radio"
                                   name="question4" value="Среднее"/>
                            <label class="form-check-label">Среднее</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question4" class="form-check-input"
                                   type="radio"
                                   name="question4" value="Хорошо"/>
                            <label class="form-check-label">Хорошо</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-span-12 mt-4">
                      <p class="font-base font-bold">3. Оценка тренера</p>
                    </div>
                    <!-- Доступность изложения материала-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Доступность изложения материала
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question5" class="form-check-input"
                                   type="radio"
                                   name="question5" value="Плохо"/>
                            <label class="form-check-label">Плохо</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question5" class="form-check-input"
                                   type="radio"
                                   name="question5" value="Среднее"/>
                            <label class="form-check-label">Среднее</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question5" class="form-check-input"
                                   type="radio"
                                   name="question5" value="Хорошо"/>
                            <label class="form-check-label">Хорошо</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Работа тренера с группой-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Работа тренера с группой
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question6" class="form-check-input"
                                   type="radio"
                                   name="question6" value="Плохо"/>
                            <label class="form-check-label">Плохо</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question6" class="form-check-input"
                                   type="radio"
                                   name="question6" value="Среднее"/>
                            <label class="form-check-label">Среднее</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question6" class="form-check-input"
                                   type="radio"
                                   name="question6" value="Хорошо"/>
                            <label class="form-check-label">Хорошо</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Уровень владения информацией-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Уровень владения информацией
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question7" class="form-check-input"
                                   type="radio"
                                   name="question7" value="Плохо"/>
                            <label class="form-check-label">Плохо</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question7" class="form-check-input"
                                   type="radio"
                                   name="question7" value="Среднее"/>
                            <label class="form-check-label">Среднее</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question7" class="form-check-input"
                                   type="radio"
                                   name="question7" value="Хорошо"/>
                            <label class="form-check-label">Хорошо</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Тренер хорошо подготовлен к тренингу-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Тренер хорошо подготовлен к тренингу
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question8" class="form-check-input"
                                   type="radio"
                                   name="question8" value="Плохо"/>
                            <label class="form-check-label">Плохо</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question8" class="form-check-input"
                                   type="radio"
                                   name="question8" value="Среднее"/>
                            <label class="form-check-label">Среднее</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question8" class="form-check-input"
                                   type="radio"
                                   name="question8" value="Хорошо"/>
                            <label class="form-check-label">Хорошо</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Тренинг проходит динамично, живо, с энтузиазмом-->
                    <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                      <div class="mt-3">
                        <label>Тренинг проходит динамично, живо, с энтузиазмом
                        </label>
                        <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question9" class="form-check-input"
                                   type="radio"
                                   name="question9" value="Плохо"/>
                            <label class="form-check-label">Плохо</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question9" class="form-check-input"
                                   type="radio"
                                   name="question9" value="Среднее"/>
                            <label class="form-check-label">Среднее</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question9" class="form-check-input"
                                   type="radio"
                                   name="question9" value="Хорошо"/>
                            <label class="form-check-label">Хорошо</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- В каких тренингах Вы хотели бы участвовать дополнительно?-->
                    <div class="col-span-12">
                      <div class="mt-3">
                        <label>В каких тренингах Вы хотели бы участвовать дополнительно?</label>
                        <div class="flex flex-col sm:flex-row mt-2">
                          <div class="form-check mr-2">
                            <input disabled v-model="feedBackAnketa.question10" id="checkbox-switch-4"
                                   class="form-check-input"
                                   type="checkbox" value="по агрономии"/>
                            <label class="form-check-label" for="checkbox-switch-4">по агрономии</label>
                          </div>
                          <div class="form-check mr-2 mt-2 sm:mt-0">
                            <input disabled v-model="feedBackAnketa.question10" id="checkbox-switch-5"
                                   class="form-check-input"
                                   type="checkbox" value="по животноводству"/>
                            <label class="form-check-label" for="checkbox-switch-5">по животноводству</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {defineComponent} from 'vue'
import axios from 'axios'
import store from '@/store'
import Select2 from "vue3-select2-component";
import router from "@/router";
import $ from "jquery";
import moment from "moment";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},

  data() {
    return {
      feedbackAnketaId: this.$route.params.id,
      feedBackAnketa: {
        id: '',
        creationDate: '',
        fio: '',
        uchastnik: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: []
      },
      gruppa: {
        prepods: '',
        placeProvedeniya: ''
      }
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'feedbackAnketa/' + this.feedbackAnketaId, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              let placeProvedeniya = ''
              let prepods = ''
              let fioUchastnik = ''
              if (response.data.gruppa.oblast != null) {
                placeProvedeniya = placeProvedeniya + response.data.gruppa.oblast.name + ', '
              }
              if (response.data.gruppa.rayon != null) {
                placeProvedeniya = placeProvedeniya + response.data.gruppa.rayon.name + ', '
              }
              if (response.data.gruppa.aymak != null) {
                placeProvedeniya = placeProvedeniya + response.data.gruppa.aymak.name + ', '
              }
              if (response.data.gruppa.nasPunkt != null) {
                placeProvedeniya = placeProvedeniya + response.data.gruppa.nasPunkt.shortName
              }
              this.gruppa.placeProvedeniya = placeProvedeniya
              if (response.data.gruppa.prepods != null) {
                response.data.gruppa.prepods.forEach(item => {
                  prepods = prepods + item.lastName + ' ' + item.firstName + ', '
                })
              }
              if (response.data.uchastnik.lastName != null) {
                fioUchastnik = fioUchastnik + response.data.uchastnik.lastName + ' '
              }
              if (response.data.uchastnik.firstName != null) {
                fioUchastnik = fioUchastnik + response.data.uchastnik.firstName + ' '
              }
              if (response.data.uchastnik.patronymic != null) {
                fioUchastnik = fioUchastnik + response.data.uchastnik.patronymic
              }
              this.feedBackAnketa.fio = fioUchastnik

              this.gruppa.prepods = prepods
              this.feedBackAnketa.creationDate = response.data.creationDate
              this.feedBackAnketa.question1 = response.data.question1
              this.feedBackAnketa.question2 = response.data.question2
              this.feedBackAnketa.question3 = response.data.question3
              this.feedBackAnketa.question4 = response.data.question4
              this.feedBackAnketa.question5 = response.data.question5
              this.feedBackAnketa.question6 = response.data.question6
              this.feedBackAnketa.question7 = response.data.question7
              this.feedBackAnketa.question8 = response.data.question8
              this.feedBackAnketa.question9 = response.data.question9
              this.feedBackAnketa.question10 = response.data.question10
            }
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
  }
})
</script>
