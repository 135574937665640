<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="text-lg font-medium mt-10">Роли</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div
          class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
          <a      href="javascript:;"
                  data-toggle="modal"
                  data-target="#create-modal"
                  class="btn btn-primary shadow-md mr-2"
                  @click="showModalCreate">Добавить роль</a>
        </div>
        <!-- BEGIN: Data List -->
        <div class="col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2" id="table_id">
            <thead>
            <tr>
              <th class="whitespace-nowrap">Название</th>
              <th class="whitespace-nowrap">Название для системы</th>
              <th class="text-center whitespace-nowrap">Действия</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in userroles"
              :key="item.id">
              <td>
                <div class="text-s whitespace-nowrap">
                  {{ item.name }}
                </div>
              </td>
              <td>
                <div class="text-s whitespace-nowrap">
                  {{ item.nameRu }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;"
                     data-toggle="modal"
                     data-target="#create-modal"
                     @click="showModalEdit(item)">
                    <CheckSquareIcon class="w-4 h-4 mr-1"/>
                    Редактировать
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                    @click="showModalDelete(item)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1"/>
                    Удалить
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- END: Pagination -->
      </div>
      <!-- BEGIN: Create Oblast Modal -->
      <div
        id="create-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop='static'
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <div class="text-3xl mt-5">{{ nameForModal }}</div>
              </div>
              <form v-on:submit.prevent="isEditingModal ? edit() : create()">
                <div class="p-5">
                <label class="form-label left-0">Название для системы</label>
                <input v-model="userrole.name"
                       type="text"
                       required
                       class="intro-x login__input form-control py-3 px-4 border-gray-300 block mb-4"
                />
                <label class="form-label left-0">Название на русском</label>
                <input v-model="userrole.nameRu"
                       type="text"
                       required
                       class="intro-x login__input form-control py-3 px-4 border-gray-300 block mb-4"
                />
                <button type="submit" class="btn btn-primary py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top">
                  {{ nameOfButtonForModal }}
                </button>
                <button type="button" id="close" data-dismiss='modal' @click="resetData"
                        class='btn btn-light py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top'>
                  Отмена
                </button>
              </div>
              </form>
              <div
                class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Create Oblast Modal -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div
        id="delete-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3"/>
                <div class="text-3xl mt-5">Вы уверены?</div>
                <div class="text-gray-600 mt-2">
                  Вы уверены что хотите удалить эту роль? <br/>
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-outline-secondary w-24 mr-1"
                >
                  Отмена
                </button>
                <button @click="confirmDelete" data-dismiss="modal" type="button" class="btn btn-danger w-24">Удалить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import axios from 'axios'
import store from '@/store'
import $ from 'jquery'

const API_URL = store.state.API_URI

export default defineComponent({
  data() {
    return {
      nameForModal: '',
      nameOfButtonForModal: '',
      isEditingModal: false,
      totalItems: Number,
      pageSizes: [
        10, 25, 35, 50, 100
      ],
      userroles: {
        type: Array
      },
      userrole: {
        id: '',
        name: '',
        nameRu: ''
      },
      itemForDelete: {
        type: Object
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 1
      }
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.userrole, this.$options.data().userrole)
    },
    onPageClick(pageNumber) {
      this.pagination.currentPage = pageNumber
      this.refreshData()
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'userroles/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.userroles = response.data
              $("#table_id").dataTable().fnDestroy();
              $(document).ready( function () {
                $('#table_id').DataTable({
                  "scrollX": true,
                    "language": {
                      "processing": "Подождите...",
                      "search": "Поиск:",
                      "lengthMenu": "Показать _MENU_ записей",
                      "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
                      "infoEmpty": "Записи с 0 до 0 из 0 записей",
                      "infoFiltered": "(отфильтровано из _MAX_ записей)",
                      "infoPostFix": "",
                      "loadingRecords": "Загрузка записей...",
                      "zeroRecords": "Записи отсутствуют.",
                      "emptyTable": "В таблице отсутствуют данные",
                      "paginate":
                        {
                          "first": "Первая",
                          "previous": "Предыдущая",
                          "next": "Следующая",
                          "last": "Последняя"
                        },
                      "aria": {
                        "sortAscending": ": активировать для сортировки столбца по возрастанию",
                        "sortDescending": ": активировать для сортировки столбца по убыванию"
                      },
                      "select": {
                        "rows": {
                          "_": "Выбрано записей: %d",
                          "0": "Кликните по записи для выбора",
                          "1": "Выбрана одна запись"
                        }
                      },
                      "buttons":
                        {
                          "print": "Печать"
                        }
                    },
                    columnDefs : [
                      { 'visible': false, 'targets': [ ] }
                    ]
                    // "buttons": ["csv", "excel", "print"]
                  }
                );
              })
            }
          }).catch(error => {
            this.checkResponseError(error)
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    showModalDelete(item) {
      this.itemForDelete = item
    },
    confirmDelete() {
      axios
        .delete(API_URL + 'userroles/userrole/' + this.itemForDelete.id, {
          headers: {
            Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          }
        }).then(response => {
          if (response.data != null) {
            this.deleteResponse(response)
            this.refreshData()
          }
        })
    },
    showModalCreate() {
      this.nameForModal = 'Добавление роли'
      this.nameOfButtonForModal = 'Добавить'
      this.isEditingModal = false
    },
    create() {
      axios({
        method: 'post',
        url: API_URL + 'userroles/add/',
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          name: this.userrole.name,
          nameRu: this.userrole.nameRu
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    showModalEdit(item) {
      this.userrole.id = item.id
      this.userrole.name = item.name
      this.userrole.nameRu = item.nameRu
      this.isEditingModal = true
      this.nameForModal = 'Редактирование роли'
      this.nameOfButtonForModal = 'Изменить'
    },
    edit() {
      axios({
        method: 'put',
        url: API_URL + 'userroles/userrole/' + this.userrole.id,
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          id: this.userrole.id,
          name: this.userrole.name,
          nameRu: this.userrole.nameRu
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    }
  }
})
</script>
