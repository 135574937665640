<template>
  <div class="grid">
    <div class="intro-y box mt-5">
      <div
        class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">Профиль пользователя</h2>
      </div>
      <div class="p-5">
        <div class="grid grid-cols-12 gap-x-5">
          <div class="col-span-12 xl:col-span-6">
            <div>
              <label for="update-profile-form-6" class="form-label"
              >ФИО</label
              >
              <input
                id="update-profile-form-6"
                type="text"
                class="form-control"
                disabled
                :value="user.fio"
              />
            </div>
            <div class="mt-3">
              <label for="update-profile-form-7" class="form-label"
              >E-Mail</label
              >
              <input
                id="update-profile-form-7"
                type="text"
                class="form-control"
                disabled
                :value="user.email"
              />
            </div>
            <div class="mt-3">
              <label for="update-profile-form-8" class="form-label"
              >Логин (имя пользователя)</label
              >
              <input
                id="update-profile-form-8"
                type="text"
                class="form-control"
                disabled
                :value="user.username"
              />
            </div>
            <div class="mt-3">
              <label for="update-profile-form-9" class="form-label"
              >Номер телефона</label
              >
              <input
                id="update-profile-form-9"
                type="text"
                class="form-control"
                disabled
                :value="user.phone"
              />
            </div>
            <div class="mt-3">
              <label for="update-profile-form-10" class="form-label"
              >Уровень пользователя</label
              >
              <input
                id="update-profile-form-10"
                type="text"
                class="form-control"
                disabled
                :value="user.levelOfUser.name"
              />
            </div>
          </div>
          <div class="col-span-12 xl:col-span-6">
            <div class="w-52 mx-auto xl:mr-0 xl:ml-6">
              <div
                class="border-2 border-dashed shadow-sm border-gray-200 dark:border-dark-5 rounded-md p-5"
              >
                  <img
                    v-bind:src="'data:image/png;base64,' + image"
                    class="rounded-md"
                  />
                <div class="mx-auto cursor-pointer relative mt-5">
                </div>
              </div>
            </div>
            <div class="mt-3">
              <label for="update-profile-form-11" class="form-label"
              >Роль пользователя</label
              >
              <input
                id="update-profile-form-11"
                type="text"
                class="form-control"
                disabled
                :value="user.userRole.nameRu"
              />
            </div>
            <div class="mt-3">
              <label for="update-profile-form-12" class="form-label"
              >Привязан к</label
              >
              <input
                id="update-profile-form-12"
                type="text"
                class="form-control"
                disabled
                :value="privyazka"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import store from '@/store'
import axios from "axios";
import $ from "jquery";

const API_URL = store.state.API_URI

export default defineComponent({
  data() {
    return {
      user: store.state.auth.user,
      image: '',
      privyazka: ''
    }
  },
  mounted() {
    if (this.user.levelOfUser.code === 1) {
      this.privyazka = 'Республика'
    }
    if (this.user.levelOfUser.code === 2) {
      this.privyazka = this.user.oblast.name
    }
    if (this.user.levelOfUser.code === 3) {
      this.privyazka = this.user.rayon.name
    }
    if (this.user.levelOfUser.code === 4) {
      this.privyazka = this.user.hospital.shortName
    }
    if (this.user.levelOfUser.code === 5) {
      this.privyazka = '(неизвестно)'
    }
    try {
      axios.get(API_URL + 'api/v1/getPhotoByPin/' + store.state.auth.user.username, {
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken
        }
      }).then(async responseM => {
          if (responseM.data.image != null) {
            this.image = responseM.data.image
          }
      }).catch(error => {
        console.log(error)
      })
    } catch (e) {
      console.log(e)
    }
  },
})
</script>
