<template>
  <div v-if="isSubmit" style="
  position: fixed;
    left: 30%;
    right: 50%;
    z-index: 999999;
    width: 701px;
    height: 135px;
    top: 30%;">
    <LoadingIcon icon="spinning-circles"/>
  </div>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="text-lg font-medium mt-10" style="color: white">Внедрение технологий</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="intro-y col-span-12 lg:col-span-12" :style="isSubmit ? 'pointer-events: none; filter: blur(5px);' : ''">
            <form class="validate-form" v-on:submit.prevent="create()">
              <!-- СВЕДЕНИЯ ОБ УЧАСТНИКЕ -->
              <div class="intro-y box">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Сведения об участнике</h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- Выбор участника -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Выберите ваше ФИО из списка<span class="text-primary-3"
                        >&nbsp;*</span>
                        </label>
                        <Select2 required :options="uchastniki" v-model="anketa1.uchastnik" @select="loadUchastnikInfo($event)"/>
                      </div>
                      <!-- ФИО -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          ФИО
                        </label>
                        <input type="text"
                               :value="uchastnik.lastName + ' ' + uchastnik.firstName + ' ' + uchastnik.patronymic"
                               class="form-control italic" disabled/>
                      </div>
                      <!-- Дата рождения -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Год рождения
                        </label>
                        <input type="text" :value="uchastnik.birthDate" class="form-control italic" disabled/>
                      </div>

                      <!-- Адрес домохозяйства -->
                      <div class="input-form col-span-10">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Адрес домохозяйства
                        </label>
                        <input type="text" :value="uchastnik.address" class="form-control italic" disabled/>
                      </div>
                      <!-- Род занятий -->
                      <div class="input-form col-span-2">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Род занятий
                        </label>
                        <input type="text" :value="uchastnik.rodZanyatiy" class="form-control italic" disabled/>
                      </div>

                      <!-- Контактные данные -->
                      <div class="input-form col-span-3">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Контактные данные
                        </label>
                        <input type="text" :value="uchastnik.contacts" class="form-control italic" disabled/>
                      </div>
                      <!-- Размер сельхоз. угодий -->
                      <div class="input-form col-span-3">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Размер сельхоз. угодий
                        </label>
                        <input type="text" :value="uchastnik.razmerUgodiy" class="form-control italic" disabled/>
                      </div>
                      <!-- Возраст -->
                      <div class="input-form col-span-3">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Возраст
                        </label>
                        <select :value="uchastnik.vozrast" disabled class="form-select sm:mr-2 italic"
                                aria-label=".form-select-sm example">
                          <option value="С 18 до 29 лет">С 18 до 29 лет</option>
                          <option value="С 29 лет">С 29 лет</option>
                        </select>
                      </div>
                      <!-- Пол -->
                      <div class="input-form col-span-3">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Пол
                        </label>
                        <input type="text" :value="uchastnik.gender" class="form-control italic" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- КОНТРОЛЬНЫЕ ВОПРОСЫ -->
              <div class="intro-y box mt-5">
                <div
                  class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                >
                  <h2 class="font-medium text-base mr-auto">Контрольные вопросы</h2>
                </div>
                <div class="p-5">
                  <div class="preview">
                    <div class='lg:grid grid-cols-12 gap-2'>
                      <!-- Количество дойных коров в хозяйстве -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Количество дойных коров в хозяйстве
                        </label>
                        <select required v-model="anketa1.question1" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5 и более">5 и более</option>
                        </select>
                      </div>
                      <!-- Средний удой молока с 1 коровы за 1 сутки -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Средний удой молока с 1 коровы за 1 сутки
                        </label>
                        <select required v-model="anketa1.question2" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value="2-4">2-4</option>
                          <option value="4-6">4-6</option>
                          <option value="6-8">6-8</option>
                          <option value="8-10">8-10</option>
                          <option value="свыше 10л">свыше 10л</option>
                        </select>
                      </div>
                      <!-- Какое количество молока реализуется в молоко перерабатывающие предприятия (в %) -->
                      <div class="input-form col-span-4">
                        <label
                          class="form-label w-full flex flex-col sm:flex-row"
                        >
                          Какое количество молока реализуется в молоко перерабатывающие предприятия (в %)
                        </label>
                        <select required v-model="anketa1.question3" class="form-select sm:mr-2"
                                aria-label=".form-select-sm example">
                          <option value=">=30%">>=30%</option>
                          <option value="30-50%">30-50%</option>
                          <option value="50-75%">50-75%</option>
                          <option value="75-100%">75-100%</option>
                        </select>
                      </div>

                      <!-- Совместное использование листовых подкормок и инсектицидов-->
                      <!-- (нутривант, новосил, гумат, акварин, бесфоллер, агромастер, нанокремний)-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label>
                            <span style="color: #1c3faa">1. Өсүмдүктөрдү жалбырагы аркылуу азыктандыруучу жер семирткичтерди жана зыянкечтерге каршы уу-заттарды (инсектициддерди) чогуу колдоносузбу?</span>
                            / Совместное использование листовых подкормок и инсектицидов
                            (нутривант, новосил, гумат, акварин, бесфоллер, агромастер, нанокремний)
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question4" id="radio-switch-4" class="form-check-input"
                                     type="radio"
                                     name="horizontal_radio_button" value="Да"/>
                              <label class="form-check-label" for="radio-switch-4">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question4" id="radio-switch-5" class="form-check-input"
                                     type="radio"
                                     name="horizontal_radio_button" value="Нет"/>
                              <label class="form-check-label" for="radio-switch-5">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Скашивание многолетних трав на высоту 8-10 см-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">2. Көп жылдык чөптөрдүн сабагын 8-10 см кылып калтырып чабуу</span>
                            / Скашивание многолетних трав на высоту 8-10 см
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question5" id="question5first" class="form-check-input"
                                     type="radio"
                                     name="question5first" value="Да"/>
                              <label class="form-check-label" for="question5first">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question5" id="question5second" class="form-check-input"
                                     type="radio"
                                     name="question5first" value="Нет"/>
                              <label class="form-check-label" for="question5second">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Использование  гербицидов. на   посева ячменя против сорняков  (Пума супер, Овсюген супер, Прима, Гранстар, Бавел)-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">3. Арпа аянтындагы отоо чөптөргө каршы  гербициддерди колдонуу (Пума супер, Овсюген супер, Прима, Гранстар, Бавел)</span>
                            / Использование гербицидов. на посева ячменя против сорняков (Пума супер, Овсюген супер,
                            Прима, Гранстар, Бавел)
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question6" id="question6First" class="form-check-input"
                                     type="radio"
                                     name="question6First" value="Да"/>
                              <label class="form-check-label" for="question6First">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question6" id="question6Second" class="form-check-input"
                                     type="radio"
                                     name="question6First" value="Нет"/>
                              <label class="form-check-label" for="question6Second">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- При выращивании кукурузы на силос против потравы  грачей и ворон  использование метода натяжения ниток-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">4. Жүгөрүнү силоско  өстүрүүдө   каргаларга  каршы  жип тартуу ыкмасын колдонуу</span>
                            / Использование метода натяжения ниток против потравы грачей и ворон при выращивании кукурузы на силос
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question7" class="form-check-input" type="radio"
                                     name="question7" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question7" class="form-check-input" type="radio"
                                     name="question7" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- При выращивании кукурузы против озимой совки используем  следующие  инсектициды (Эсперо, Авант, Караген, Дидер, Тайфун)-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">5. Жүгөрүнү өстүрүүдө совкага каршы төмөнкү инсектициддерди колдонуу (Эспиро, Авант, Карагин, Лидер, Тайфун)</span>
                            / Использование нижеследующих инсектицидов против озимой совки при выращивании кукурузы (Эсперо, Авант, Караген, Дидер, Тайфун)
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question8" class="form-check-input" type="radio"
                                     name="question8" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question8" class="form-check-input" type="radio"
                                     name="question8" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Определения оптимальный сороков уборки зерновых культур (овёс, пшеница)  визуально и разлом зерна зубами-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">6. Дан эгиндерин (сулу, буудай, арпа) оптималдуу оруп-жыюу мөөнөттөрүн аныктоодо данын тиш менен сындырган ыкманы колдонуу</span>
                            / Визуальное определение оптимальных сроков уборки зерновых культур (овёс, пшеница, ячмень) методом разлома зерна зубами
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question9" class="form-check-input" type="radio"
                                     name="question9" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question9" class="form-check-input" type="radio"
                                     name="question9" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Обработка зернохранилища против амбарных вредителей (механический метод, известь, хлорка, фумигация)-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">7. Дан эгиндери сакталуучу жайларда кампа зыянкечтерине каршы күрөшүү ыкмаларын колдоносузбу? (механикалык ыкма, акиташ менен актоо, хлорка менен тазалоо, фумигациялоо ыкмасы, уу-заттарды чачуу)</span>
                            / Обработка зернохранилища против амбарных вредителей (механический метод, побелка известью, обработка хлоркой, фумигация)
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question10" class="form-check-input" type="radio"
                                     name="question10" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question10" class="form-check-input" type="radio"
                                     name="question10" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Запаривание и сдабривание соломы (соль, зерновая болтушка, отварной картофель, кормовая свекла)-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">8. Тоюттарды малга жедирүүгө даярдоо ыкмаларын колдоносузбу? (жемге туз кошуп, жемди умачтап, картөшкөнү бышырып берүү, тоют кызылчасын майдалап берүү, жемден ботко кайнатып  же угутту жемге кошуп берүү)</span>
                            / Запаривание и сдабривание соломы (добавление соли, приготовление зерновой болтушки, отварной картофель, измельчение кормовой свеклы)
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question11" class="form-check-input" type="radio"
                                     name="question11" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question11" class="form-check-input" type="radio"
                                     name="question11" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Гидропоника – выращивание зерновых культур в зимнее время на зелённый корм-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">9. Кышкысын жашыл тоют өстүрүүдө Гидропоника ыкмасын колдоносузбу?</span>
                            / Пользуетесь ли вы методом Гидропоники- для выращивания зерновых культур на зелёный корм в зимнее время?
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question12" class="form-check-input" type="radio"
                                     name="question12" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question12" class="form-check-input" type="radio"
                                     name="question12" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Своевременная вакцинация и дегельминтизация животных и собак-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">10. Алдын алуучу жана аргасыз вакцинацияларды жургузуу.иш-чараларындагы мал ээсинин милдеттери. Ит-Уй жаныбарларга жана ит-мышыктарга гельминттик мите курттарга каршы дегелминтизация иш-чараларын жургузуу.</span>
                            / Своевременная вакцинация и дегельминтизация животных и собак
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question13" class="form-check-input" type="radio"
                                     name="question13" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question13" class="form-check-input" type="radio"
                                     name="question13" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Дезинфекция и побелка коровника-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">11. Дезинфекциялоо жана дезинфекциялоочу химиялык  каражаттарды туура колдонуу ыкмасы</span>
                            / Дезинфекция и побелка коровника
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question14" class="form-check-input" type="radio"
                                     name="question14" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question14" class="form-check-input" type="radio"
                                     name="question14" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Обеззараживание навоза-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">12. Кыкты жана жампаларды биотермикалык ыкма менен зыянсыздандыруу</span>
                            / Обеззараживание навоза
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question15" class="form-check-input" type="radio"
                                     name="question15" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question15" class="form-check-input" type="radio"
                                     name="question15" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Использование в рационе дойных коров сочных кормов – сенаж, силос, кормовая свекла-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">13. Саан уйдун рационундагы тоют курамына чыктуу тоюттардан сенаж, силос жана тоют кызылчасын колдонуу</span>
                            / Использование сенажа, силоса и кормовой свеклы в рационе дойных коров
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question16" class="form-check-input" type="radio"
                                     name="question16" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question16" class="form-check-input" type="radio"
                                     name="question16" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Поение коров теплой водой в стойловый период-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">14. Саан уйларды кыш мезгилинде жылуу суу менен камсыз кылуу жана оптимизациялаштыруу</span>
                            / Оптимизация в поении коров теплой водой в стойловый период
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question17" class="form-check-input" type="radio"
                                     name="question17" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question17" class="form-check-input" type="radio"
                                     name="question17" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Экономически оптимальные и экологически обоснованные расчеты потребности пастбища на 1 голову дойной коровы-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">15. Жайыттын 1 баш уйга болгон муктаждыгын экономикалык жактан оптималдуу жана экологиялык жактан негизделген  эсептоолор обоснован</span>
                            / Экономически оптимальные и экологически обоснованные расчеты потребности пастбища на 1
                            голову дойной коровы
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question18" class="form-check-input" type="radio"
                                     name="question18" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question18" class="form-check-input" type="radio"
                                     name="question18" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Использование соли-лизунца на пастбище в кормушках или специальных емкостях-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">16. Жайыт мезгилинде малды туз менен камсыз кылуу</span>
                            / Использование соли-лизунца в кормушках или специальных емкостях в пастбищный период
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question19" class="form-check-input" type="radio"
                                     name="question19" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question19" class="form-check-input" type="radio"
                                     name="question19" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Использование в рационе коров высокопротеиновых кормов – шрота, жмыха или горохо-ячменной смеси.-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">17. Жогорку протеиндүү тоюттардын курамындагы шрот, жмых жана буурчак менен арпанын аралашмасын тоют рационунда колдонуу</span>
                            / Использование в рационе коров высокопротеиновых кормов – шрота, жмыха или горохово-ячменной смеси
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question20" class="form-check-input" type="radio"
                                     name="question20" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question20" class="form-check-input" type="radio"
                                     name="question20" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Использование белково-витаминных добавок и премикса в кормлении коров-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">18. Белоктук-витаминдик-минералдык кошулмаларды (БВМК) рациондун тоют курамында колдонуу ыкмасы</span>
                            / Использование белково-витаминных добавок и премикса в кормлении коров
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question21" class="form-check-input" type="radio"
                                     name="question21" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question21" class="form-check-input" type="radio"
                                     name="question21" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Составление примерного рациона для дойных коров и годовой расчет потребности кормов по видам-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">19. 1 Саан уйга тоюттандыруу рационун болжолдуу түзүү жана тоют сезонуна тоют муктаждыгын эсеп-кысабын аныктоо</span>
                            / Составление примерного рациона для дойных коров и годовой расчет потребности в кормах по видам
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question22" class="form-check-input" type="radio"
                                     name="question22" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question22" class="form-check-input" type="radio"
                                     name="question22" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Правильная консервация вымени коров перед запуском-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">20. Бооз уйду байытуу учурунда алдын-ала   желинди консервациялоо ыкмасы</span>
                            / Правильная консервация вымени коров перед запуском
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question23" class="form-check-input" type="radio"
                                     name="question23" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question23" class="form-check-input" type="radio"
                                     name="question23" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Правильная техника доения (мойка вымени теплой водой и обтирание, массаж вымени до и после доения, дойка кулаком, контрольное сдаивание)-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">21. Уйларды сааган мезгилде туура техниканы колдонуу (желинди жылуу суу менен жуу жана сүртүү, саай турган учурда жана саап буткөндөн кийин укалоо, муштум менен саай турган ыкма, текшеруу үчүн саап көрүү)</span>
                            / Правильная техника доения (мойка вымени теплой водой и обтирание, массаж вымени до и
                            после доения, дойка кулаком, контрольное сдаивание)
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question24" class="form-check-input" type="radio"
                                     name="question24" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question24" class="form-check-input" type="radio"
                                     name="question24" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Применение правильных средств защиты и гигиены вымени-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">22. Желин саламаттыгы жана желин гигиенасы</span>
                            / Применение правильных средств защиты и гигиены вымени
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question25" class="form-check-input" type="radio"
                                     name="question25" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question25" class="form-check-input" type="radio"
                                     name="question25" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Правильная организация стойло-места для коров-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">23. Уйканага коюлган туура техникалык талаптарга жараша уюштурулган жай </span>
                            / Правильная организация стойло-места для коров
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question26" class="form-check-input" type="radio"
                                     name="question26" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question26" class="form-check-input" type="radio"
                                     name="question26" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Соблюдение зоогигиенических норм содержания КРС (вентиляционные и оконные отверстия и вытяжные трубы для проветривания)-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">24. Ири муйуздуу малды багууда уй сарайга коюлган зоогигиеналык  нормалар( жарык берууго - терезелердин терезелердин параметрлери  жана сеилдеткич веитилятор- шахтылар).</span>
                            / Соблюдение зоогигиенических норм содержания КРС (вентиляционные и оконные отверстия и
                            вытяжные трубы для проветривания)
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question27" class="form-check-input" type="radio"
                                     name="question27" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question27" class="form-check-input" type="radio"
                                     name="question27" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Организация правильной выпойки телят в первые 2 месяца-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">25. Сут маалындагы музоолорго сут менен тоюттандырууну туура уюштуруу.( 1-чи 2 айлыкка чейинки музоолор)</span>
                            / Организация правильной выпойки телят в первые 2 месяца
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question28" class="form-check-input" type="radio"
                                     name="question28" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question28" class="form-check-input" type="radio"
                                     name="question28" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Организация правильного кормления телят в первые 2 месяца – дача телятам стартерного комбикорма и сено хорошего качества-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">26. Сүттөн чыга элек музоолорго стартердик  тоют менен тоюттандырууну уюштуруу (1- 2 айлык  музоолор) </span>
                            / Организация правильного кормления телят в первые 2 месяца – добавление в рацион телят стартерного комбикорма и сена хорошего качества
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question29" class="form-check-input" type="radio"
                                     name="question29" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question29" class="form-check-input" type="radio"
                                     name="question29" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Применение искусственного осеменения-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">27. Жасалма жол менен уруктандыруу ыкмасын колдонуу </span>
                            / Применение искусственного осеменения
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question30" class="form-check-input" type="radio"
                                     name="question30" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question30" class="form-check-input" type="radio"
                                     name="question30" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Организация отдельного родильного отделения-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">28. Уй сарайда туут бөлмө уюштуруу жана бөлмөнүн параметрлери</span>
                            / Организация отдельного родильного помещения в коровнике, параметры помещения
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question31" class="form-check-input" type="radio"
                                     name="question31" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question31" class="form-check-input" type="radio"
                                     name="question31" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Определение породности и отбор особей по фенотипу влияющую на  молочную продуктивность, определение возраста и живой массы-->
                      <div class="col-span-12" style="justify-content: start; align-items: start; display: flex">
                        <div class="mt-3">
                          <label><span style="color: #1c3faa">29. Уйдун породасын аныктоо жана сут ондурумдуулугу жогору болгон уйларды сырткы белгилери, дене  тузулушу
  боюнча тандоо. Уйлардын тиру салмагын олчоо менен жана жашын тиштери боюнча аныктоо.</span>
                            / Определение породности и отбор особей по фенотипу влияющую на молочную продуктивность,
                            определение возраста и живой массы
                          </label>
                          <div class="flex flex-col sm:flex-row mt-2" style="justify-content: start">
                            <div class="form-check mr-2">
                              <input required v-model="anketa1.question32" class="form-check-input" type="radio"
                                     name="question32" value="Да"/>
                              <label class="form-check-label">Ооба / Да</label>
                            </div>
                            <div class="form-check mr-2 mt-2 sm:mt-0">
                              <input required v-model="anketa1.question32" class="form-check-input" type="radio"
                                     name="question32" value="Нет"/>
                              <label class="form-check-label">Жок / Нет</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary mt-5">
                Сохранить
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {defineComponent} from 'vue'
import axios from 'axios'
import store from '@/store'
import Select2 from "vue3-select2-component";
import router from "@/router";
import $ from "jquery";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},

  data() {
    return {
      gruppaId: this.$route.params.id,
      anketa1: {
        id: '',
        uchastnik: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: null,
        question11: null,
        question12: null,
        question13: null,
        question14: null,
        question15: null,
        question16: null,
        question17: null,
        question18: null,
        question19: null,
        question20: null,
        question21: null,
        question22: null,
        question23: null,
        question24: null,
        question25: null,
        question26: null,
        question27: null,
        question28: null,
        question29: null,
        question30: null,
        question31: null,
        question32: null
      },
      uchastnik: {
        lastName: '',
        firstName: '',
        patronymic: '',
        birthDate: '',
        gender: '',
        vozrast: null,
        address: '',
        rodZanyatiy: '',
        contacts: '',
        razmerUgodiy: ''
      },
      uchastniki: [],
      isSubmit: false
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.anketa1, this.$options.data().anketa1)
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'uchastnik/listForCreateAnketa/' + 1 + '/' + this.gruppaId).then(response => {
            if (response.data != null) {
              this.uchastniki = []
              response.data.forEach(item => {
                this.uchastniki.push({id: item.id, text: item.lastName + ' ' + item.firstName})
              })
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    create() {
      this.isSubmit = true
      axios({
        method: 'post',
        url: API_URL + 'anketa1/add',
        data: {
          uchastnik: this.anketa1.uchastnik != null ? {id: this.anketa1.uchastnik} : null,
          gruppa: {id: this.gruppaId},
          question1: this.anketa1.question1,
          question2: this.anketa1.question2,
          question3: this.anketa1.question3,
          question4: this.anketa1.question4,
          question5: this.anketa1.question5,
          question6: this.anketa1.question6,
          question7: this.anketa1.question7,
          question8: this.anketa1.question8,
          question9: this.anketa1.question9,
          question10: this.anketa1.question10,
          question11: this.anketa1.question11,
          question12: this.anketa1.question12,
          question13: this.anketa1.question13,
          question14: this.anketa1.question14,
          question15: this.anketa1.question15,
          question16: this.anketa1.question16,
          question17: this.anketa1.question17,
          question18: this.anketa1.question18,
          question19: this.anketa1.question19,
          question20: this.anketa1.question20,
          question21: this.anketa1.question21,
          question22: this.anketa1.question22,
          question23: this.anketa1.question23,
          question24: this.anketa1.question24,
          question25: this.anketa1.question25,
          question26: this.anketa1.question26,
          question27: this.anketa1.question27,
          question28: this.anketa1.question28,
          question29: this.anketa1.question29,
          question30: this.anketa1.question30,
          question31: this.anketa1.question31,
          question32: this.anketa1.question32
        }
      }).then(response => {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          focusConfirm: true,
          icon: 'success',
          title: 'Ваша запись успешно сохранена!',
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
        router.push('/login')
      }).catch(error => {
        this.isSubmit = false
        this.$swal.fire({
          toast: true,
          focusConfirm: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'error',
          title: 'Внутренняя ошибка сервера',
          text: 'Попробуйте позже',
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
      })
    },
    loadUchastnikInfo(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'uchastnik/' + event.id).then(response => {
            if (response.data != null) {
              var uchastnikFromDb = response.data
              this.uchastnik.lastName = uchastnikFromDb.lastName != null ? uchastnikFromDb.lastName : ''
              this.uchastnik.firstName = uchastnikFromDb.firstName != null ? uchastnikFromDb.firstName : ''
              this.uchastnik.patronymic = uchastnikFromDb.patronymic != null ? uchastnikFromDb.patronymic : ''
              this.uchastnik.birthDate = uchastnikFromDb.birthDate != null ? uchastnikFromDb.birthDate : null
              this.uchastnik.gender = uchastnikFromDb.gender.name
              if (uchastnikFromDb.birthDate != null) {
                var age = new Date().getFullYear() - new Date(uchastnikFromDb.birthDate).getFullYear()
                if (age >= 18 && age < 29) {
                  this.uchastnik.vozrast = 'С 18 до 29 лет'
                } else {
                  this.uchastnik.vozrast = 'С 29 лет'
                }
              }
              this.uchastnik.razmerUgodiy = uchastnikFromDb.razmerUgodiy != null ? uchastnikFromDb.razmerUgodiy : ''
              this.uchastnik.rodZanyatiy = uchastnikFromDb.rodZanyatiy != null ? uchastnikFromDb.rodZanyatiy : ''
              this.uchastnik.contacts = uchastnikFromDb.contacts != null ? uchastnikFromDb.contacts : ''
              let addressOfUchastnik = ''
              if (uchastnikFromDb.oblast != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.oblast.name + ', '
              }
              if (uchastnikFromDb.rayon != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.rayon.name + ', '
              }
              if (uchastnikFromDb.aymak != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.aymak.name + ', '
              }
              if (uchastnikFromDb.nasPunkt != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.nasPunkt.name + ', '
              }
              if (uchastnikFromDb.street != null) {
                addressOfUchastnik = addressOfUchastnik + uchastnikFromDb.street + ' ' + uchastnikFromDb.houseNumber
              }
              this.uchastnik.address = addressOfUchastnik
            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    }
  }
})
</script>
