<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="intro-y text-lg font-medium mt-10">Участники</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div v-if="isAdd" class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <a href="javascript:;"
             data-toggle="modal"
             data-target="#create-modal"
             class="btn btn-primary shadow-md mr-2"
             @click="showModalCreate">Добавить</a>
        </div>
        <div class="intro-y box col-span-12 mt-3">
          <div
            class="flex flex-col sm:flex-row items-center p-3 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Расширенный поиск</h2>
            <div class="form-check w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0">
              <label class="form-check-label ml-0 sm:ml-2" for="show-example-1"
              >
                <span v-if="!filterShow">Показать</span>
                <span v-if="filterShow">Скрыть</span>
              </label
              >
              <input
                id="show-example-1"
                v-model="filterShow"
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox"
              />
            </div>
          </div>
          <div v-show="filterShow" id="input" class="p-5">
            <div class="preview lg:grid grid-cols-12 gap-4">
              <div class="col-span-3">
                <label class="form-label">Фамилия</label>
                <input
                  v-model="searchParams.lastName"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-span-3">
                <label class="form-label">Имя</label>
                <input
                  v-model="searchParams.firstName"
                  type="text"
                  class="form-control"
                />
              </div>

              <div class="col-span-3">
                <label class="form-label">Область</label>
                <Select2
                  @select="onChangeOblast($event)"
                  v-model="searchParams.oblast"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="oblasts"/>
              </div>
              <div class="col-span-3">
                <label class="form-label">Район</label>
                <Select2
                  @select="onChangeRayon($event)"
                  v-model="searchParams.rayon"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="rayons"/>
              </div>
              <div class="col-span-3">
                <label class="form-label">Аймак</label>
                <Select2
                  @select="onChangeAymak($event)"
                  v-model="searchParams.aymak"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="aymaks"/>
              </div>
              <div class="col-span-3">
                <label class="form-label">Нас.пункт</label>
                <Select2
                  v-model="searchParams.nasPunkt"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="nasPunkts"/>
              </div>
              <div class="col-span-2">
                <label class="form-label">Пол</label>
                <Select2
                  v-model="searchParams.gender"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="genders"/>
              </div>
              <div class="col-span-2">
                <label class="form-label">Группа</label>
                <Select2
                  v-model="searchParams.gruppa"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="gruppas"/>
              </div>
              <div class="col-span-2">
                <label class="form-label">Род занятий</label>
                <input
                  v-model="searchParams.rodZanyatiy"
                  type="text"
                  class="form-control"
                />
              </div>

              <div class="col-span-12 justify-self-end">
                <button
                  @click="Object.assign(this.$data.searchParams, this.$options.data().searchParams); getByPagination(1, searchParams.keyword)"
                  class="btn bg-theme-2 text-white">
                  <RotateCwIcon/> &nbsp;Сбросить фильтры
                </button>
                <button @click="getByPagination(1, searchParams.keyword)" class="btn bg-primary-1 text-white ml-3">
                  <FilterIcon/>
                  Поиск
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="intro-y box col-span-12 mt-1">
          <div
            class="flex flex-col sm:flex-row items-center p-3 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Участники</h2>
            <h2 class="font-medium text-base" style="margin-right: 50%">Всего участников: {{
                paginationParams.totalItems
              }}</h2>
            <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <!--              <form @submit.prevent="getByPagination(1, '')">-->
              <!--                <div class="w-56 relative text-gray-700 dark:text-gray-300">-->
              <!--                  <input-->
              <!--                    type="text"-->
              <!--                    class="form-control w-56 pr-10 placeholder-theme-13"-->
              <!--                    placeholder="Поиск"-->
              <!--                    v-model="searchParams.keyword"-->
              <!--                  />-->
              <!--                  <button type="submit">-->
              <!--                    <SearchIcon-->
              <!--                      :style="searchParams.keyword.length > 0 ? 'color: blue' : ''"-->
              <!--                      class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"-->
              <!--                    />-->
              <!--                  </button>-->
              <!--                  <XCircleIcon v-if="searchParams.keyword.length > 0"-->
              <!--                               @click="searchParams.keyword = ''; getByPagination(1,'')"-->
              <!--                               class="w-4 h-4 absolute my-auto inset-y-0 mr-7 right-0 "-->
              <!--                  />-->

              <!--                </div>-->
              <!--              </form>-->
            </div>
          </div>
          <div id="striped-rows-table" class="p-3">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                  <tr class="bg-gray-200 text-gray-700">
                    <th class="border text-center dark:border-dark-5 width-60">ФИО</th>
                    <th class="border text-center dark:border-dark-5 width-60">Год рождения</th>
                    <th class="border text-center dark:border-dark-5 width-60">Пол</th>
                    <th class="border text-center dark:border-dark-5 width-60">Область</th>
                    <th class="border text-center dark:border-dark-5 width-60">Район</th>
                    <th class="border text-center dark:border-dark-5 width-60">Айылный аймак</th>
                    <th class="border text-center dark:border-dark-5 width-60">Группа</th>
                    <th class="border text-center dark:border-dark-5 width-60">Род занятий</th>
                    <th class="border text-center dark:border-dark-5 width-60" v-if="isEdit">Действия</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="hover:bg-gray-200" v-for="item in uchastniki"
                      :key="item.id">
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.lastName != null ? item.lastName : '-' }}
                      </div>
                      <div class="text-s">
                        {{ item.firstName != null ? item.firstName : '-' }}
                      </div>
                      <div class="text-s">
                        {{ item.patronymic != null ? item.patronymic : '-' }}
                      </div>
                    </td>
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.birthDate != null ? item.birthDate : '(отсутствует)' }}
                      </div>
                    </td>
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.gender.name }}
                      </div>
                    </td>
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.oblast != null ? item.oblast.name : '(отсутствует)' }}
                      </div>
                    </td>
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.rayon != null ? item.rayon.name : '(отсутствует)' }}
                      </div>
                    </td>
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.aymak != null ? item.aymak.name : '(отсутствует)' }}
                      </div>
                    </td>
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.gruppa != null ? item.gruppa.name : '(отсутствует)' }}
                      </div>
                    </td>
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.rodZanyatiy != null ? item.rodZanyatiy : '(отсутствует)' }}
                      </div>
                    </td>
                    <td v-if="isEdit" class="border text-center table-report__action w-56">
                      <div class="flex justify-center items-center">
                        <a class="flex items-center mr-3" href="javascript:;"
                           data-toggle="modal"
                           data-target="#create-modal"
                           @click="showModalEdit(item)">
                          <CheckSquareIcon class="w-4 h-4 mr-1"/>
                          Редактировать
                        </a>
                      </div>
                      <div class="flex justify-center items-center mt-3">
                        <a class="flex items-center mr-3" href="javascript:;"
                           style="color: red"
                           data-toggle="modal"
                           data-target="#delete-confirmation-modal"
                           @click="showModalDelete(item)">
                          <Trash2Icon class="w-4 h-4 mr-1"/>
                          Удалить
                        </a>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-5"
            >
              <ul class="pagination bg-gray-600 rounded-full">
                <li v-if="5 < paginationParams.currentPage">
                  <a class="pagination__link " @click="getByPagination(1, this.paginationParams.keyword)">
                    <ChevronsLeftIcon class="w-4 h-4 text-white"/>
                  </a>
                </li>
                <li v-if="paginationParams.currentPage > 1">
                  <a class="pagination__link"
                     @click="getByPagination(paginationParams.currentPage - 1, this.paginationParams.keyword)">
                    <ChevronLeftIcon class="w-4 h-4 text-white"/>
                  </a>
                </li>
                <template v-for="page in paginationParams.pagenumbersEnd" v-bind:key='page'>
                  <li v-if="paginationParams.pagenumbersStart <= page || page >= paginationParams.pagenumbersEnd">
                    <a class="pagination__link"
                       v-bind:class="{ 'pagination__link pagination__link--active' : page === paginationParams.currentPage}"
                       @click="getByPagination(page, this.paginationParams.keyword)">
                      <p class="text-white" v-bind:class="{ 'text-black' : page === paginationParams.currentPage}">
                        {{ page }}
                      </p>
                    </a>
                  </li>
                </template>
                <li v-if="paginationParams.currentPage !== paginationParams.totalPages">
                  <a class="pagination__link "
                     @click="getByPagination(paginationParams.currentPage + 1, this.paginationParams.keyword)">
                    <ChevronRightIcon class="w-4 h-4 text-white"/>
                  </a>
                </li>
                <li v-if="paginationParams.currentPage < paginationParams.totalPages - 5">
                  <a class="pagination__link"
                     @click="getByPagination(paginationParams.totalPages, this.paginationParams.keyword)">
                    <ChevronsRightIcon class="w-4 h-4 text-white"/>
                  </a>
                </li>
              </ul>
              <input type="text" class="form-control w-36 mr-1" v-model="paginationParams.goToPage"
                     placeholder="Номер страницы">
              <button class="btn btn-primary mr-10" type="submit"
                      @click="getByPagination(paginationParams.goToPage, paginationParams.keyword)"
                      :disabled="!(paginationParams.goToPage < paginationParams.totalPages && paginationParams.goToPage < paginationParams.totalPages > 0)">
                Перейти
              </button>
              <select v-model.number="paginationParams.pageSize" @change="getByPagination(1, '')"
                      class="w-20 form-select mt-3 ml-4 sm:mt-0">
                <option>10</option>
                <option>25</option>
                <option>35</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- BEGIN: Create Modal -->
      <div
        id="create-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop='static'
      >
        <div class="modal-dialog" style='width: 65% !important;'>
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <div class="text-3xl mt-5">{{ nameForModal }}</div>
              </div>
              <form v-on:submit.prevent="isEditingModal ? edit() : create()">
                <div class="p-5">

                  <div class="grid grid-cols-12 gap-1">
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        ПИН
                      </label>
                      <input
                        v-model="uchastnik.pin"
                        type="number"
                        class="form-control"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Фамилия
                        <span class="text-primary-3">*</span>
                      </label>
                      <input
                        v-model="uchastnik.lastName"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Имя
                        <span class="text-primary-3">*</span>
                      </label>
                      <input
                        v-model="uchastnik.firstName"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Отчество
                      </label>
                      <input
                        v-model="uchastnik.patronymic"
                        type="text"
                        class="form-control"
                      />
                    </div>

                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Год рождения
                      </label>
                      <input
                        v-model="uchastnik.birthDate"
                        type="number"
                        min="1900"
                        max="2099"
                        step="1"
                        class="form-control"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Пол
                      </label>
                      <Select2
                        v-model="uchastnik.gender"
                        :options="genders"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Область
                      </label>
                      <Select2
                        @select="onChangeOblast($event)"
                        v-model="uchastnik.oblast"
                        :options="oblasts"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Район
                      </label>
                      <Select2
                        @select="onChangeRayon($event)"
                        v-model="uchastnik.rayon"
                        :options="rayons"
                      />
                    </div>

                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Айылный аймак
                      </label>
                      <Select2
                        @select="onChangeAymak($event)"
                        v-model="uchastnik.aymak"
                        :options="aymaks"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Населенный пункт
                      </label>
                      <Select2
                        v-model="uchastnik.nasPunkt"
                        :options="nasPunkts"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Улица
                      </label>
                      <input
                        v-model="uchastnik.street"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Номер дома
                      </label>
                      <input
                        v-model="uchastnik.houseNumber"
                        type="text"
                        class="form-control"
                      />
                    </div>

                    <div class="col-span-4">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Род занятий
                      </label>
                      <input
                        v-model="uchastnik.rodZanyatiy"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-span-4">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Контактные данные
                      </label>
                      <input
                        v-model="uchastnik.contacts"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-span-4">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Размер сельскохозяйственных угодий
                      </label>
                      <input
                        v-model="uchastnik.razmerUgodiy"
                        type="text"
                        class="form-control"
                      />
                    </div>

                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Количество коров
                      </label>
                      <input
                        v-model="uchastnik.countOfCows"
                        type="number"
                        class="form-control"
                      />
                    </div>
                    <div class="col-span-3">
                      <label
                        class="form-label w-full flex flex-col sm:flex-row">
                        Средний удой с 1 коровы за 1 сутки
                      </label>
                      <input
                        v-model="uchastnik.sredniyUdoy"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top">
                    {{ nameOfButtonForModal }}
                  </button>
                  <button type="button" id="close" data-dismiss='modal' @click="resetData"
                          class='btn btn-light py-3 px-4 mt-5 w-full xl:w-32 xl:mr-3 align-top'>
                    Отмена
                  </button>
                </div>
              </form>
              <div
                class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div
        id="delete-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3"/>
                <div class="text-3xl mt-5">Вы уверены?</div>
                <div v-if="isInGruppa" class="text-black mt-2 font-medium">
                  Данный участник состоит в группе {{ gruppaName }} <br/>
                </div>
                <div class="text-gray-600 mt-2">
                  Вы уверены что хотите удалить этого участника? <br/>
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-outline-secondary w-24 mr-1"
                >
                  Отмена
                </button>
                <button @click="confirmDelete" data-dismiss="modal" type="button" class="btn btn-danger w-24">Удалить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {defineComponent, ref} from 'vue'
import axios from 'axios'
import store from '@/store'
import moment from "moment";

import $ from 'jquery'
import Select2 from 'vue3-select2-component'

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},
  setup() {
    const select = ref('#')
    return {select}
  },
  data() {
    return {
      nameForModal: String,
      nameOfButtonForModal: String,
      isEditingModal: false,
      totalItems: Number,
      uchastniki: [],
      uchastnik: {
        id: '',
        pin: null,
        firstName: null,
        lastName: null,
        patronymic: null,
        birthDate: null,
        gender: null,
        oblast: null,
        rayon: null,
        aymak: null,
        nasPunkt: null,
        street: null,
        houseNumber: null,
        rodZanyatiy: null,
        contacts: null,
        razmerUgodiy: null,
        countOfCows: null,
        sredniyUdoy: null,
        gruppa: null
      },
      oblasts: [],
      rayons: [],
      aymaks: [],
      nasPunkts: [],
      genders: [],
      isAdd: false,
      isEdit: false,
      filterShow: false,
      paginationParams: {
        goToPage: '',
        keyword: '',
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        pagenumbersStart: 0,
        pagenumbersEnd: 0,
        pageSize: 10
      },
      searchParams: {
        gruppa: null,
        oblast: null,
        rayon: null,
        aymak: null,
        nasPunkt: null,
        firstName: '',
        lastName: '',
        pin: '',
        gender: null,
        rodZanyatiy: '',
        keyword: '',
      },
      gruppas: [],
      user: store.state.auth.user,
      itemForDelete: null,
      gruppaName: null,
      isInGruppa: false
    }
  },
  mounted() {
    this.user.userRole.permissions.forEach(item => {
      if (item.name.includes('SUPER_ADMIN') || item.name.includes('UCHASTNIKI_CREATE')) {
        this.isEdit = true
      }
      if (item.name.includes('SUPER_ADMIN') || item.name.includes('UCHASTNIKI_EDIT')) {
        this.isAdd = true
      }
    })
    this.refreshData()
  },
  methods: {
    resetData() {
      Object.assign(this.$data.uchastnik, this.$options.data().uchastnik)
    },
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'uchastnik/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.uchastniki = []
              this.uchastniki = response.data.list
              this.paginationParams.currentPage = response.data.currentPage
              this.paginationParams.totalItems = response.data.totalItems
              this.paginationParams.totalPages = response.data.totalPages

              this.paginationParams.pagenumbersStart = this.paginationParams.currentPage - 1 > 1 ? this.paginationParams.currentPage - 1 : 1
              this.paginationParams.pagenumbersEnd = this.paginationParams.currentPage + 5 < this.paginationParams.totalPages ? this.paginationParams.currentPage + 5 : this.paginationParams.totalPages

            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

          await axios.get(API_URL + 'coate/oblast/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.oblasts = []
              response.data.forEach(item => {
                this.oblasts.push({id: item.id, text: item.name})
              })
            }
          })

          await axios.get(API_URL + 'sexs/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.genders = []
              response.data.forEach(item => {
                this.genders.push({id: item.id, text: item.name})
              })
            }
          })

        })()
      } catch (e) {
        console.log(e)
      }
      this.getGruppas()
    },
    getByPagination(pageNumber, keyword) {
      this.EIs = []
      try {
        (async () => {
          await axios.post(API_URL + 'uchastnik/page', {
              gruppa: this.searchParams.gruppa,
              lastName: this.searchParams.lastName,
              firstName: this.searchParams.firstName,
              oblast: this.searchParams.oblast,
              rayon: this.searchParams.rayon,
              nasPunkt: this.searchParams.nasPunkt,
              gender: this.searchParams.gender,
              rodZanyatiy: this.searchParams.rodZanyatiy,
              keyword: this.searchParams.keyword
            },
            {
              headers: {
                Authorization: 'Bearer ' + store.state.auth.user.jwtToken
              },
              params: {
                pageNo: pageNumber,
                pageSize: this.paginationParams.pageSize
              }
            }
          ).then(response => {
            this.uchastniki = []
            this.uchastniki = response.data.list
            this.paginationParams.currentPage = response.data.currentPage
            this.paginationParams.totalItems = response.data.totalItems
            this.paginationParams.totalPages = response.data.totalPages
            this.paginationParams.pagenumbersStart = this.paginationParams.currentPage - 1 > 1 ? this.paginationParams.currentPage - 1 : 1
            this.paginationParams.pagenumbersEnd = this.paginationParams.currentPage + 5 < this.paginationParams.totalPages ? this.paginationParams.currentPage + 5 : this.paginationParams.totalPages
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    showModalCreate() {
      this.nameForModal = 'Добавление участника'
      this.nameOfButtonForModal = 'Добавить'
      this.isEditingModal = false
    },
    create() {
      axios({
        method: 'post',
        url: API_URL + 'uchastnik/create',
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          pin: this.uchastnik.pin,
          firstName: this.uchastnik.firstName,
          lastName: this.uchastnik.lastName,
          patronymic: this.uchastnik.patronymic,
          birthDate: this.uchastnik.birthDate,
          gender: this.uchastnik.gender != null ? {id: this.uchastnik.gender} : null,
          oblast: this.uchastnik.oblast != null ? {id: this.uchastnik.oblast} : null,
          rayon: this.uchastnik.rayon != null ? {id: this.uchastnik.rayon} : null,
          aymak: this.uchastnik.aymak != null ? {id: this.uchastnik.aymak} : null,
          nasPunkt: this.uchastnik.nasPunkt != null ? {id: this.uchastnik.nasPunkt} : null,
          street: this.uchastnik.street,
          houseNumber: this.uchastnik.houseNumber,
          rodZanyatiy: this.uchastnik.rodZanyatiy,
          contacts: this.uchastnik.contacts,
          razmerUgodiy: this.uchastnik.razmerUgodiy,
          countOfCows: this.uchastnik.countOfCows,
          sredniyUdoy: this.uchastnik.sredniyUdoy,
          gruppa: this.uchastnik.gruppa
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    showModalEdit(uchastnik) {
      if (uchastnik.rayon != null) {
        this.getRayonById(uchastnik.rayon.id)
      }
      if (uchastnik.aymak != null) {
        this.getAymakById(uchastnik.aymak.id)
      }
      if (uchastnik.nasPunkt != null) {
        this.getNasPunktById(uchastnik.nasPunkt.id)
      }
      this.uchastnik.id = uchastnik.id
      this.uchastnik.pin = uchastnik.pin
      this.uchastnik.firstName = uchastnik.firstName
      this.uchastnik.lastName = uchastnik.lastName
      this.uchastnik.patronymic = uchastnik.patronymic
      this.uchastnik.birthDate = uchastnik.birthDate
      this.uchastnik.gender = uchastnik.gender != null ? uchastnik.gender.id : null
      this.uchastnik.oblast = uchastnik.oblast != null ? uchastnik.oblast.id : null
      this.uchastnik.rayon = uchastnik.rayon != null ? uchastnik.rayon.id : null
      this.uchastnik.aymak = uchastnik.aymak != null ? uchastnik.aymak.id : null
      this.uchastnik.nasPunkt = uchastnik.nasPunkt != null ? uchastnik.nasPunkt.id : null
      this.uchastnik.street = uchastnik.street
      this.uchastnik.houseNumber = uchastnik.houseNumber
      this.uchastnik.rodZanyatiy = uchastnik.rodZanyatiy
      this.uchastnik.razmerUgodiy = uchastnik.razmerUgodiy
      this.uchastnik.countOfCows = uchastnik.countOfCows
      this.uchastnik.sredniyUdoy = uchastnik.sredniyUdoy
      this.uchastnik.contacts = uchastnik.contacts
      this.uchastnik.gruppa = uchastnik.gruppa
      this.isEditingModal = true
      this.nameForModal = 'Редактирование участника'
      this.nameOfButtonForModal = 'Изменить'
    },
    edit() {
      axios({
        method: 'post',
        url: API_URL + 'uchastnik/update',
        headers: {
          Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          'Content-Type': 'application/json'
        },
        data: {
          id: this.uchastnik.id,
          pin: this.uchastnik.pin,
          firstName: this.uchastnik.firstName,
          lastName: this.uchastnik.lastName,
          patronymic: this.uchastnik.patronymic,
          birthDate: this.uchastnik.birthDate,
          gender: this.uchastnik.gender != null ? {id: this.uchastnik.gender} : null,
          oblast: this.uchastnik.oblast != null ? {id: this.uchastnik.oblast} : null,
          rayon: this.uchastnik.rayon != null ? {id: this.uchastnik.rayon} : null,
          aymak: this.uchastnik.aymak != null ? {id: this.uchastnik.aymak} : null,
          nasPunkt: this.uchastnik.nasPunkt != null ? {id: this.uchastnik.nasPunkt} : null,
          street: this.uchastnik.street,
          houseNumber: this.uchastnik.houseNumber,
          rodZanyatiy: this.uchastnik.rodZanyatiy,
          contacts: this.uchastnik.contacts,
          razmerUgodiy: this.uchastnik.razmerUgodiy,
          countOfCows: this.uchastnik.countOfCows,
          sredniyUdoy: this.uchastnik.sredniyUdoy,
          gruppa: this.uchastnik.gruppa
        }
      }).then(response => {
        if (response != null) {
          if (this.succesResponse(response)) {
            this.resetData()
            $('#close').click()
          }
          this.refreshData()
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    showModalDelete(item) {
      this.itemForDelete = item
      this.checkUchastnikWhenDelete(this.itemForDelete.id)
    },
    confirmDelete() {
      axios
        .post(API_URL + 'uchastnik/deleteById/' + this.itemForDelete.id, null, {
          headers: {
            Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          }
        }).then(response => {
        if (response.data != null) {
          this.deleteResponse(response)
          this.refreshData()
        }
      })
    },
    checkUchastnikWhenDelete(id) {
      axios
        .get(API_URL + 'uchastnik/checkUchastnikInGruppas/' + id, {
          headers: {
            Authorization: 'Bearer ' + store.state.auth.user.jwtToken,
          }
        }).then(response => {
        this.gruppaName = response.data.gruppaName
        this.isInGruppa = response.data.inGruppa
      })
    },
    onChangeOblast(event) {
      this.nasPunkts = []
      try {
        (async () => {
          await axios.get(API_URL + 'coate/naspunkts/byAteCode/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            response.data.forEach(item => {
              this.nasPunkts.push({id: item.id, text: item.shortName})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
      try {
        (async () => {
          await axios.get(API_URL + 'coate/rayons/byOblastId/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.rayons = []
            response.data.forEach(item => {
              this.rayons.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    onChangeRayon(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'coate/naspunkts/byAteCode/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            response.data.forEach(item => {
              this.nasPunkts.push({id: item.id, text: item.shortName})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
      try {
        (async () => {
          await axios.get(API_URL + 'coate/aymaks/byRayonId/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.aymaks = []
            response.data.forEach(item => {
              this.aymaks.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    onChangeAymak(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'coate/naspunkts/byAteCode/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            response.data.forEach(item => {
              this.nasPunkts.push({id: item.id, text: item.shortName})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    getRayonById(id) {
      this.rayons = []
      try {
        (async () => {
          await axios.get(API_URL + 'coate/rayon/' + id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.rayons.push({id: response.data.id, text: response.data.name})
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    getAymakById(id) {
      this.aymaks = []
      try {
        (async () => {
          await axios.get(API_URL + 'coate/aymak/' + id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            response.data.forEach(item => {
              this.aymaks.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    getNasPunktById(id) {
      this.nasPunkts = []
      try {
        (async () => {
          await axios.get(API_URL + 'coate/naspunkt/' + id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            response.data.forEach(item => {
              this.nasPunkts.push({id: item.id, text: item.shortName})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    getGruppas() {
      try {
        (async () => {
          await axios.get(API_URL + 'gruppa/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.gruppas = []
            response.data.forEach(item => {
              this.gruppas.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    }
  }
})
</script>
