<template>
  <div>
    <h2 class="intro-y text-2xl font-medium mt-5 text-center mr-auto">
      Анкеты
    </h2>
    <!-- END: Pricing Tab -->
    <!-- BEGIN: Pricing Content -->
    <div class="grid grid-cols-12 gap-3">
      <!-- Старые анкеты-->
      <div class="col-span-12">
        <div class="flex mt-10">
          <div class="tab-content" style="width: 25%">
            <div
              class="tab-pane flex flex-col lg:flex-row active"
              role="tabpanel"
              aria-labelledby="layout-1-monthly-fees-tab"
            >
              <div class="intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0">
                <BriefcaseIcon
                  class="block w-12 h-12 text-theme-25 dark:text-theme-22 mx-auto"
                />
                <div class="text-xl font-medium text-center mt-10">Внедрение технологий</div>
                <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
                  Анкета по внедрению технологий
                </div>
                <button
                  type="button"
                  class="btn btn-sm btn-primary mb-2 py-3 px-4 block mx-auto mt-8"
                  @click="copyLink(1)"
                >
                  Скопировать ссылку
                </button>
                <div class="mt-6" style="justify-content: center; align-items: center; display: flex">
                  <code>{{ host + anketa1Link + '/' + gruppaId }}</code>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content" style="width: 25%">
            <div
              class="tab-pane flex flex-col lg:flex-row active"
              role="tabpanel"
              aria-labelledby="layout-1-monthly-fees-tab"
            >
              <div class="intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0">
                <BriefcaseIcon
                  class="block w-12 h-12 text-theme-25 dark:text-theme-22 mx-auto"
                />
                <div class="text-xl font-medium text-center mt-10">Пре - пост Тест «ПО Животноводству и ветеринарии»
                </div>
                <button
                  type="button"
                  class="btn btn-sm btn-primary mb-2 py-3 px-4 block mx-auto mt-8"
                  @click="copyLink(2)"
                >
                  Скопировать ссылку
                </button>
                <div class="mt-6" style="justify-content: center; align-items: center; display: flex">
                  <code>{{ host + anketa2Link + '/' + gruppaId }}</code>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content" style="width: 25%">
            <div
              class="tab-pane flex flex-col lg:flex-row active"
              role="tabpanel"
              aria-labelledby="layout-1-monthly-fees-tab"
            >
              <div class="intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0">
                <BriefcaseIcon
                  class="block w-12 h-12 text-theme-25 dark:text-theme-22 mx-auto"
                />
                <div class="text-xl font-medium text-center mt-10">Оценка тренинга</div>
                <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
                  (заполняется 1 раз)
                </div>
                <button
                  type="button"
                  class="btn btn-sm btn-primary mb-2 py-3 px-4 block mx-auto mt-8"
                  @click="copyLink(3)"
                >
                  Скопировать ссылку
                </button>
                <div class="mt-6" style="justify-content: center; align-items: center; display: flex">
                  <code>{{ host + feedBackAnketaLink + '/' + gruppaId }}</code>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content" style="width: 25%">
            <div
              class="tab-pane flex flex-col lg:flex-row active"
              role="tabpanel"
              aria-labelledby="layout-1-monthly-fees-tab"
            >
              <div class="intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0">
                <BriefcaseIcon
                  class="block w-12 h-12 text-theme-25 dark:text-theme-22 mx-auto"
                />
                <div class="text-xl font-medium text-center mt-10">Пре пост тест по Агрономии</div>
                <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
                  Анкета пре пост тест по Агрономии
                </div>
                <button
                  type="button"
                  class="btn btn-sm btn-primary mb-2 py-3 px-4 block mx-auto mt-8"
                  @click="copyLink(4)"
                >
                  Скопировать ссылку
                </button>
                <div class="mt-6" style="justify-content: center; align-items: center; display: flex">
                  <code>{{ host + anketa3Link + '/' + gruppaId }}</code>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-5">
          <div class="tab-content" style="width: 25%">
            <div
              class="tab-pane flex flex-col lg:flex-row active"
              role="tabpanel"
              aria-labelledby="layout-1-monthly-fees-tab"
            >
              <div class="intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0">
                <BriefcaseIcon
                  class="block w-12 h-12 text-theme-25 dark:text-theme-22 mx-auto"
                />
                <div class="text-xl font-medium text-center mt-10">Анкета по удою</div>
                <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
                  Анкета по ежемесячному удою
                </div>
                <button
                  type="button"
                  class="btn btn-sm btn-primary mb-2 py-3 px-4 block mx-auto mt-8"
                  @click="copyLink(5)"
                >
                  Скопировать ссылку
                </button>
                <div class="mt-6" style="justify-content: center; align-items: center; display: flex">
                  <code>{{ host + anketaUdoyLink + '/' + gruppaId }}</code>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Новые анкеты-->
      <div class="col-span-12">
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Новые анкеты</h2>
          </div>
          <div id="basic-accordion" class="p-5">
            <div class="preview">
              <div id="faq-accordion-1" class="accordion">
                <!-- Агрономия-->
                <div class="accordion-item">
                  <div id="faq-accordion-content-1" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-1"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-1"
                    >
                      Анкеты по агрономии
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-1"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-1"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div
                      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed bg-gray-100"
                    >
                      <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                        <table class="table table-report sm:mt-2">
                          <thead>
                          <tr>
                            <th class="whitespace-nowrap">#</th>
                            <th class="whitespace-nowrap">Название анкеты</th>
                            <th class="whitespace-nowrap">Ссылка</th>
                          </tr>
                          </thead>
                          <tbody>
                          <!-- Первая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                1
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 1: «Плодородие почвы, кормопроизводство и агротехника выращивания многолетних трав»
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + agronomiyaQuiz1Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForAgronomiya(1)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Вторая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                2
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 2: «Агротехника выращивания кормовых злаков и корнеплодов»
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + agronomiyaQuiz2Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForAgronomiya(2)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Третья анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                3
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 3: «Приготовление и хранение силоса. Хранение и подготовка кормов к скармливанию»
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + agronomiyaQuiz3Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForAgronomiya(3)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Животноводство-->
                <div class="accordion-item">
                  <div id="faq-accordion-content-2" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-2"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-2"
                    >
                      Анкеты по животноводству
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-2"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-2"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div
                      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed bg-gray-100"
                    >
                      <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                        <table class="table table-report sm:mt-2">
                          <thead>
                          <tr>
                            <th class="whitespace-nowrap">#</th>
                            <th class="whitespace-nowrap">Название анкеты</th>
                            <th class="whitespace-nowrap">Ссылка</th>
                          </tr>
                          </thead>
                          <tbody>
                          <!-- Первая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                1
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 1: БОЛЕЗНИ КОРОВ И ИХ ПРОФИЛАКТИКА
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + jivotnovodstvoQuiz2Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForJivotnovodstvo(2)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Вторая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                2
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 2: ОСНОВЫ И ЭКОНОМИКА ПРАВИЛЬНОГО КОРМЛЕНИЯ
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + jivotnovodstvoQuiz3Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForJivotnovodstvo(3)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Третья анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                3
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 3: СОСТАВЛЕНИЕ И ИСПОЛЬЗОВАНИЕ РАЦИОНОВ ДЛЯ СБАЛАНСИРОВАННОГО КОРМЛЕНИЯ ДОЙНЫХ КОРОВ
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + jivotnovodstvoQuiz4Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForJivotnovodstvo(4)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Четвертая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                4
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 4: ОСНОВЫ ОБЕСПЕЧЕНИЯ КАЧЕСТВА МОЛОКА
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + jivotnovodstvoQuiz5Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForJivotnovodstvo(5)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Пятая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                5
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 5: ТЕХНОЛОГИИ УЛУЧШЕНИЯ УСЛОВИЙ СОДЕРЖАНИЯ ДОЙНЫХ КОРОВ (КОРОВНИКИ) И ВЫРАЩИВАНИЯ
                                ТЕЛЯТ
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + jivotnovodstvoQuiz6Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForJivotnovodstvo(6)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Вторая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                6
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 6: “ВОСПРОИЗВОДСТВО КРУПНОГО РОГАТОГО СКОТА”
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + jivotnovodstvoQuiz1Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForJivotnovodstvo(1)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Внедрение технологий (Агрономия)-->
                <div class="accordion-item">
                  <div id="faq-accordion-content-2" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-2"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-2"
                    >
                      Анкеты по внедрению технологий (агрономия)
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-2"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-2"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div
                      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed bg-gray-100"
                    >
                      <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                        <table class="table table-report sm:mt-2">
                          <thead>
                          <tr>
                            <th class="whitespace-nowrap">#</th>
                            <th class="whitespace-nowrap">Название анкеты</th>
                            <th class="whitespace-nowrap">Ссылка</th>
                          </tr>
                          </thead>
                          <tbody>
                          <!-- Первая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                1
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 1: «ПЛОДОРОДИЕ ПОЧВЫ, КОРМОПРОИЗВОДСТВО И АГРОТЕХНИКА ВЫРАЩИВАНИЯ МНОГОЛЕТНИХ ТРАВ»
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedAgrQuiz1Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedAgronomiya(1)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Вторая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                2
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 2: «АГРОТЕХНИКА ВЫРАЩИВАНИЯ КОРМОВЫХ ЗЛАКОВ И КОРНЕПЛОДОВ»
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedAgrQuiz2Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedAgronomiya(2)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Третья анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                3
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 3: «ПРИГОТОВЛЕНИЕ И ХРАНЕНИЕ СИЛОСА. ХРАНЕНИЕ И ПОДГОТОВКА КОРМОВ К СКАРМЛИВАНИЮ»
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedAgrQuiz3Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedAgronomiya(3)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Внедрение технологий (Животноводство)-->
                <div class="accordion-item">
                  <div id="faq-accordion-content-2" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-2"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-2"
                    >
                      Анкеты по внедрению технологий (животноводство)
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-2"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-2"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div
                      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed bg-gray-100"
                    >
                      <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                        <table class="table table-report sm:mt-2">
                          <thead>
                          <tr>
                            <th class="whitespace-nowrap">#</th>
                            <th class="whitespace-nowrap">Название анкеты</th>
                            <th class="whitespace-nowrap">Ссылка</th>
                          </tr>
                          </thead>
                          <tbody>
                          <!-- Первая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                1
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 1: БОЛЕЗНИ ДОЙНЫХ КОРОВ И ИХ ПРОФИЛАКТИКА
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedJivQuiz1Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedJivotnovodstvo(1)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Вторая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                2
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 2: ОСНОВЫ И ЭКОНОМИКА ПРАВИЛЬНОГО КОРМЛЕНИЯ
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedJivQuiz2Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedJivotnovodstvo(2)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Третья анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                3
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 3: СОСТАВЛЕНИЕ И ИСПОЛЬЗОВАНИЕ РАЦИОНОВ ДЛЯ СБАЛАНСИРОВАННОГО КОРМЛЕНИЯ ДОЙНЫХ КОРОВ
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedJivQuiz3Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedJivotnovodstvo(3)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Четвертая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                4
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 4: ОСНОВЫ ОБЕСПЕЧЕНИЯ КАЧЕСТВА СЫРОГО МОЛОКА
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedJivQuiz4Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedJivotnovodstvo(4)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Пятая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                5
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 5: ТЕХНОЛОГИИ УЛУЧШЕНИЯ УСЛОВИЙ СОДЕРЖАНИЯ ДОЙНЫХ КОРОВ (КОРОВНИКИ) И ВЫРАЩИВАНИЯ
                                ТЕЛЯТ
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedJivQuiz5Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedJivotnovodstvo(5)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          <!-- Шестая анкета-->
                          <tr class="intro-x">
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                6
                              </div>
                            </td>
                            <td>
                              <div
                                class="mt-0.5"
                              >
                                УМ 6: “ВОСПРОИЗВОДСТВО КРУПНОГО РОГАТОГО СКОТА”
                              </div>
                              <div
                                class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                              >
                                {{ host + vnedJivQuiz6Add + '/' + gruppaId }}
                              </div>
                            </td>
                            <td class="table-report__action w-56">
                              <div class="flex justify-center items-center">
                                <button class="flex items-center mr-3 btn btn-primary btn-sm" type="button"
                                        @click="copyLinkForVnedJivotnovodstvo(6)">
                                  <CheckSquareIcon class="w-4 h-4 mr-1"/>
                                  Скопировать ссылку
                                </button>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {defineComponent} from 'vue'
import store from '@/store'
import Select2 from 'vue3-select2-component'

const API_URL = store.state.API_URI

export default defineComponent({
  components: {
    Select2
  },
  data() {
    return {
      gruppaId: this.$route.params.id,
      anketa1Link: '/anketa1Add',
      anketa2Link: '/anketa2Add',
      feedBackAnketaLink: '/feedBackAdd',
      anketa3Link: '/anketa3Add',
      anketaUdoyLink: '/anketaUdoyAdd',
      agronomiyaQuiz1Add: '/agronomiyaQuiz1Add',
      agronomiyaQuiz2Add: '/agronomiyaQuiz2Add',
      agronomiyaQuiz3Add: '/agronomiyaQuiz3Add',
      jivotnovodstvoQuiz2Add: '/jivotnovodstvoQuiz2Add',
      jivotnovodstvoQuiz1Add: '/jivotnovodstvoQuiz1Add',
      jivotnovodstvoQuiz3Add: '/jivotnovodstvoQuiz3Add',
      jivotnovodstvoQuiz4Add: '/jivotnovodstvoQuiz4Add',
      jivotnovodstvoQuiz5Add: '/jivotnovodstvoQuiz5Add',
      jivotnovodstvoQuiz6Add: '/jivotnovodstvoQuiz6Add',

      vnedAgrQuiz1Add: '/vnedAgrQuiz1Add',
      vnedAgrQuiz2Add: '/vnedAgrQuiz2Add',
      vnedAgrQuiz3Add: '/vnedAgrQuiz3Add',

      vnedJivQuiz1Add: '/vnedJivQuiz1Add',
      vnedJivQuiz2Add: '/vnedJivQuiz2Add',
      vnedJivQuiz3Add: '/vnedJivQuiz3Add',
      vnedJivQuiz4Add: '/vnedJivQuiz4Add',
      vnedJivQuiz5Add: '/vnedJivQuiz5Add',
      vnedJivQuiz6Add: '/vnedJivQuiz6Add',

      host: location.protocol + '//' + location.host
    }
  },
  mounted() {
  },
  methods: {
    copyLink(numberOfAnketa) {
      if (numberOfAnketa === 1) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.anketa1Link + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 2) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.anketa2Link + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 3) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.feedBackAnketaLink + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 4) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.anketa3Link + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 5) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.anketaUdoyLink + '/' + this.gruppaId)
      }
    },
    copyLinkForAgronomiya(numberOfAnketa) {
      if (numberOfAnketa === 1) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.agronomiyaQuiz1Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 2) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.agronomiyaQuiz2Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 3) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.agronomiyaQuiz3Add + '/' + this.gruppaId)
      }
    },
    copyLinkForJivotnovodstvo(numberOfAnketa) {
      if (numberOfAnketa === 1) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.jivotnovodstvoQuiz1Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 2) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.jivotnovodstvoQuiz2Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 3) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.jivotnovodstvoQuiz3Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 4) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.jivotnovodstvoQuiz4Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 5) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.jivotnovodstvoQuiz5Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 6) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.jivotnovodstvoQuiz6Add + '/' + this.gruppaId)
      }
    },
    copyLinkForVnedAgronomiya(numberOfAnketa) {
      if (numberOfAnketa === 1) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedAgrQuiz1Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 2) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedAgrQuiz2Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 3) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedAgrQuiz3Add + '/' + this.gruppaId)
      }
    },
    copyLinkForVnedJivotnovodstvo(numberOfAnketa) {
      if (numberOfAnketa === 1) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedJivQuiz1Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 2) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedJivQuiz2Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 3) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedJivQuiz3Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 4) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedJivQuiz4Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 5) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedJivQuiz5Add + '/' + this.gruppaId)
      }
      if (numberOfAnketa === 6) {
        let host = location.protocol + '//' + location.host
        this.copy(host + this.vnedJivQuiz6Add + '/' + this.gruppaId)
      }
    },
    successCopied: function (link) {
      let text = 'Ссылка успешно скопирована!'
      this.$swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        focusConfirm: true,
        icon: 'success',
        title: text,
        text: link,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })
      return true
    },
    copy(link) {
      const textArea = document.createElement("textarea");
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
      this.successCopied(link)
    }
  }
})
</script>
