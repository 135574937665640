<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <h2 class="intro-y text-lg font-medium mt-10">Пре пост тест по Агрономии</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <!-- ФИЛЬТР-->
        <div class="intro-y box col-span-12 mt-3">
          <div
            class="flex flex-col sm:flex-row items-center p-3 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Расширенный поиск</h2>
            <div class="form-check w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0">
              <label class="form-check-label ml-0 sm:ml-2" for="show-example-1"
              >
                <span v-if="!filterShow">Показать</span>
                <span v-if="filterShow">Скрыть</span>
              </label
              >
              <input
                id="show-example-1"
                v-model="filterShow"
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox"
              />
            </div>
          </div>
          <div v-show="filterShow" id="input" class="p-5">
            <div class="preview lg:grid grid-cols-12 gap-4">
              <div class="col-span-2">
                <label class="form-label">Фамилия</label>
                <input
                  v-model="searchParams.lastName"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-span-2">
                <label class="form-label">Имя</label>
                <input
                  v-model="searchParams.firstName"
                  type="text"
                  class="form-control"
                />
              </div>

              <div class="col-span-2">
                <label class="form-label">Область</label>
                <Select2
                  @select="onChangeOblast($event)"
                  v-model="searchParams.oblast"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="oblasts"/>
              </div>
              <div class="col-span-2">
                <label class="form-label">Район</label>
                <Select2
                  @select="onChangeRayon($event)"
                  v-model="searchParams.rayon"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="rayons"/>
              </div>
              <div class="col-span-3">
                <label class="form-label">Аймак</label>
                <Select2
                  @select="onChangeAymak($event)"
                  v-model="searchParams.aymak"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="aymaks"/>
              </div>
              <div class="col-span-3">
                <label class="form-label">Нас.пункт</label>
                <Select2
                  v-model="searchParams.nasPunkt"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="nasPunkts"/>
              </div>
              <div class="col-span-2">
                <label class="form-label">Пол</label>
                <Select2
                  v-model="searchParams.gender"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="genders"/>
              </div>
              <div class="col-span-2">
                <label class="form-label">Группа</label>
                <Select2
                  v-model="searchParams.gruppa"
                  :settings="{containerCssClass: 'm-1'}"
                  :options="gruppas"/>
              </div>
              <div class="col-span-2">
                <label class="form-label">Род занятий</label>
                <input
                  v-model="searchParams.rodZanyatiy"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-span-2">
                <label class="form-label">Какая по счету анкета?</label>
                <input
                  v-model="searchParams.numberOfAnketa"
                  type="number"
                  min="1"
                  max="2"
                  class="form-control"
                />
              </div>

              <div class="col-span-12 justify-self-end">
                <button
                  @click="Object.assign(this.$data.searchParams, this.$options.data().searchParams); getByPagination(1, searchParams.keyword)"
                  class="btn bg-theme-2 text-white">
                  <RotateCwIcon/> &nbsp;Сбросить фильтры
                </button>
                <button @click="getByPagination(1)" class="btn bg-primary-1 text-white ml-3">
                  <FilterIcon/>
                  Поиск
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="intro-y box col-span-12 mt-1">
          <div
            class="flex flex-col sm:flex-row items-center p-3 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Анкеты</h2>
            <h2 class="font-medium text-base" style="margin-right: 50%">Всего анкет: {{
                paginationParams.totalItems
              }}</h2>
          </div>
          <div id="striped-rows-table" class="p-3">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                  <tr class="bg-gray-200 text-gray-700">
                    <th>ФИО участника</th>
                    <th>Год рождения</th>
                    <th>Пол</th>
                    <th>Группа</th>
                    <th>Дата прохождения анкеты</th>
                    <th>Какая по счету анкета?</th>
                    <th class="text-center whitespace-nowrap">Действия</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="hover:bg-gray-200" v-for="item in paginationParams.result"
                      :key="item.id">
                    <td class="border text-center">
                      <div class="text-s">
                        {{ item.uchastnik.lastName != null ? item.uchastnik.lastName : '-' }}
                      </div>
                      <div class="text-s">
                        {{ item.uchastnik.firstName != null ? item.uchastnik.firstName : '-' }}
                      </div>
                      <div class="text-s">
                        {{ item.uchastnik.patronymic != null ? item.uchastnik.patronymic : '-' }}
                      </div>
                    </td>
                    <td class="border">
                      <div class="text-s">
                        {{ item.uchastnik.birthDate }}
                      </div>
                    </td>
                    <td class="border">
                      <div class="text-s">
                        {{ item.uchastnik.gender.name }}
                      </div>
                    </td>
                    <td class="border">
                      <div class="text-s">
                        {{ item.gruppa.name }}
                      </div>
                    </td>
                    <td class="border">
                      <div class="text-s">
                        {{ this.format_date(item.creationDate) }}
                      </div>
                    </td>
                    <td class="border">
                      <div class="text-s">
                        {{ item.numberOfAnketa }}
                      </div>
                    </td>
                    <td class="border table-report__action w-56">
                      <div class="flex justify-center items-center">
                        <router-link class="flex items-center mr-3"
                                     :to="{path: '/anketa3Info/'+item.id}" append>
                          <CheckSquareIcon class="w-4 h-4 mr-1"/>
                          Подробнее
                        </router-link>
                      </div>
                      <div class="flex justify-center items-center">
                        <a data-toggle="modal"
                           class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                           data-target="#delete-modal"
                           @click="showModalDelete(item)" style="color: red">
                          Удалить анкету</a>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-5"
            >
              <ul class="pagination bg-gray-600 rounded-full">
                <li v-if="5 < paginationParams.currentPage">
                  <a class="pagination__link " @click="getByPagination(1, this.paginationParams.keyword)">
                    <ChevronsLeftIcon class="w-4 h-4 text-white"/>
                  </a>
                </li>
                <li v-if="paginationParams.currentPage > 1">
                  <a class="pagination__link"
                     @click="getByPagination(paginationParams.currentPage - 1, this.paginationParams.keyword)">
                    <ChevronLeftIcon class="w-4 h-4 text-white"/>
                  </a>
                </li>
                <template v-for="page in paginationParams.pagenumbersEnd" v-bind:key='page'>
                  <li v-if="paginationParams.pagenumbersStart <= page || page >= paginationParams.pagenumbersEnd">
                    <a class="pagination__link"
                       v-bind:class="{ 'pagination__link pagination__link--active' : page === paginationParams.currentPage}"
                       @click="getByPagination(page, this.paginationParams.keyword)">
                      <p class="text-white" v-bind:class="{ 'text-black' : page === paginationParams.currentPage}">
                        {{ page }}
                      </p>
                    </a>
                  </li>
                </template>
                <li v-if="paginationParams.currentPage !== paginationParams.totalPages">
                  <a class="pagination__link "
                     @click="getByPagination(paginationParams.currentPage + 1, this.paginationParams.keyword)">
                    <ChevronRightIcon class="w-4 h-4 text-white"/>
                  </a>
                </li>
                <li v-if="paginationParams.currentPage < paginationParams.totalPages - 5">
                  <a class="pagination__link"
                     @click="getByPagination(paginationParams.totalPages, this.paginationParams.keyword)">
                    <ChevronsRightIcon class="w-4 h-4 text-white"/>
                  </a>
                </li>
              </ul>
              <input type="text" class="form-control w-36 mr-1" v-model="paginationParams.goToPage"
                     placeholder="Номер страницы">
              <button class="btn btn-primary mr-10" type="submit"
                      @click="getByPagination(paginationParams.goToPage, paginationParams.keyword)"
                      :disabled="!(paginationParams.goToPage < paginationParams.totalPages && paginationParams.goToPage < paginationParams.totalPages > 0)">
                Перейти
              </button>
              <select v-model.number="paginationParams.pageSize" @change="getByPagination(1)"
                      class="w-20 form-select mt-3 ml-4 sm:mt-0">
                <option>10</option>
                <option>25</option>
                <option>35</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div
        id='delete-modal'
        class='modal'
        tabindex='-1'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-body p-0'>
              <div class='p-5 text-center'>
                <XCircleIcon class='w-16 h-16 text-theme-6 mx-auto mt-3'/>
                <div class='text-3xl mt-5'>Вы уверены?</div>
                <div class='text-gray-600 mt-2'>
                  Вы уверены что хотите удалить эту анкету? <br/>
                </div>
              </div>
              <div class='px-5 pb-8 text-center'>
                <button
                  type='button'
                  data-dismiss='modal'
                  class='btn btn-outline-secondary w-24 mr-1'
                >
                  Отмена
                </button>
                <button @click='confirmDelete' data-dismiss='modal' type='button' class='btn btn-danger w-24'>Удалить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue'
import axios from 'axios'
import store from '@/store'

import $ from 'jquery'
import Select2 from 'vue3-select2-component'
import moment from "moment";

const API_URL = store.state.API_URI

export default defineComponent({
  components: {Select2},
  setup() {
    const select = ref('#')
    return { select }
  },
  data() {
    return {
      nameForModal: String,
      nameOfButtonForModal: String,
      isEditingModal: false,
      totalItems: Number,
      anketas: [],
      paginationParams: {
        keyword: '',
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        pagenumbersStart: 0,
        pagenumbersEnd: 0,
        pageSize: 10,
        result: []
      },
      searchParams: {
        gruppa: null,
        oblast: null,
        rayon: null,
        aymak: null,
        nasPunkt: null,
        firstName: '',
        lastName: '',
        pin: '',
        gender: null,
        rodZanyatiy: '',
        numberOfAnketa: null,
      },
      isSuperAdmin: false,
      user: store.state.auth.user,
      itemForDelete: null,

      filterShow: false,

      oblasts: [],
      rayons: [],
      aymaks: [],
      nasPunkts: [],
      genders: [],
      gruppas: [],
    }
  },
  mounted() {
    this.user.userRole.permissions.forEach(item => {
      if (item.name.includes('SUPER_ADMIN')) {
        this.isSuperAdmin = true
      }
    })
    this.refreshData()
  },
  methods: {
    refreshData() {
      try {
        (async () => {
          await axios.get(API_URL + 'anketa3/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.paginationParams.result = []
              this.paginationParams.result = response.data.list
              this.paginationParams.currentPage = response.data.currentPage
              this.paginationParams.totalItems = response.data.totalItems
              this.paginationParams.totalPages = response.data.totalPages

              this.paginationParams.pagenumbersStart = this.paginationParams.currentPage - 1 > 1 ? this.paginationParams.currentPage - 1 : 1
              this.paginationParams.pagenumbersEnd = this.paginationParams.currentPage + 5 < this.paginationParams.totalPages ? this.paginationParams.currentPage + 5 : this.paginationParams.totalPages

            }
            return response.data
          }).catch(error => {
            this.checkResponseError(error)
          })

          await axios.get(API_URL + 'coate/oblast/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.oblasts = []
              response.data.forEach(item => {
                this.oblasts.push({id: item.id, text: item.name})
              })
            }
          })

          await axios.get(API_URL + 'sexs/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.genders = []
              response.data.forEach(item => {
                this.genders.push({id: item.id, text: item.name})
              })
            }
          })

        })()
      } catch (e) {
        console.log(e)
      }
      this.getGruppas()
    },
    getByPagination(page) {
      this.paginationParams.result = []
      try {
        (async () => {
          await axios.post(API_URL + 'anketa3/page', {
            gruppa: this.searchParams.gruppa,
            lastName: this.searchParams.lastName,
            firstName: this.searchParams.firstName,
            oblast: this.searchParams.oblast,
            rayon: this.searchParams.rayon,
            nasPunkt: this.searchParams.nasPunkt,
            gender: this.searchParams.gender,
            rodZanyatiy: this.searchParams.rodZanyatiy,
            numberOfAnketa: this.searchParams.numberOfAnketa
          }, {
            params: {
              pageNo: page,
              pageSize: this.paginationParams.pageSize
            },
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            if (response.data != null) {
              this.paginationParams.result = []
              this.paginationParams.result = response.data.list
              this.paginationParams.currentPage = response.data.currentPage
              this.paginationParams.totalItems = response.data.totalItems
              this.paginationParams.totalPages = response.data.totalPages
              this.paginationParams.pagenumbersStart = this.paginationParams.currentPage - 1 > 1 ? this.paginationParams.currentPage - 1 : 1
              this.paginationParams.pagenumbersEnd = this.paginationParams.currentPage + 5 < this.paginationParams.totalPages ? this.paginationParams.currentPage + 5 : this.paginationParams.totalPages
            }
          }).catch(error => {
            this.checkResponseError(error)
          })

        })()
      } catch (e) {
        console.log(e)
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    showModalDelete(item) {
      this.itemForDelete = item
    },
    confirmDelete() {
      axios.post(API_URL + 'anketa3/deleteById', null,
        {
          headers: {
            Authorization: 'Bearer ' + store.state.auth.user.jwtToken
          },
          params: {
            id: this.itemForDelete.id
          }
        }).then(response => {
        if (response != null) {
          if (response.status === 200) {
            this.succesResponse(response)
            this.getByPagination(1)
          }
        }
      }).catch(error => {
        this.checkResponseError(error)
      })
    },
    getGruppas() {
      try {
        (async () => {
          await axios.get(API_URL + 'gruppa/list', {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.gruppas = []
            response.data.forEach(item => {
              this.gruppas.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },

    onChangeOblast(event) {
      this.nasPunkts = []
      try {
        (async () => {
          await axios.get(API_URL + 'coate/naspunkts/byAteCode/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            response.data.forEach(item => {
              this.nasPunkts.push({id: item.id, text: item.shortName})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
      try {
        (async () => {
          await axios.get(API_URL + 'coate/rayons/byOblastId/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.rayons = []
            response.data.forEach(item => {
              this.rayons.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    onChangeRayon(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'coate/naspunkts/byAteCode/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            response.data.forEach(item => {
              this.nasPunkts.push({id: item.id, text: item.shortName})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
      try {
        (async () => {
          await axios.get(API_URL + 'coate/aymaks/byRayonId/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            this.aymaks = []
            response.data.forEach(item => {
              this.aymaks.push({id: item.id, text: item.name})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
    onChangeAymak(event) {
      try {
        (async () => {
          await axios.get(API_URL + 'coate/naspunkts/byAteCode/' + event.id, {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.user.jwtToken
            }
          }).then(response => {
            response.data.forEach(item => {
              this.nasPunkts.push({id: item.id, text: item.shortName})
            })
          })
        })()
      } catch (e) {
        console.log(e)
      }
    },
  }
})
</script>
